export enum ErrorAction {
    API_ERROR = 'API_ERROR',
}

export interface ErrorData {
    error: Error;
    message: string;
    type: ErrorAction;
}

export function handleAPIError(error: Error, message: string): ErrorData {
    console.error(error);
    return {
        type: ErrorAction.API_ERROR,
        error,
        message,
    };
}
