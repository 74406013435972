import { merge } from 'lodash';

import { replaceItemWithId } from '../util';

import { SubmissionsAction, SubmissionsData } from './submissions.action';
import { Submission } from '../types';

export interface SubmissionsState {
    publicSubmissions?: Submission[];
    privateSubmissions?: Submission[];
    focusedSubmission?: Submission;
    isPublicListRequesting: boolean;
    isPrivateListRequesting: boolean;
    isDetailsRequesting: boolean;
    submissionDetails?: Submission;
    readme?: string;
    isFileUploadinging: boolean;
    uploadPredictionsDatasetId?: string;
}

const initialState: SubmissionsState = {
    publicSubmissions: undefined,
    privateSubmissions: undefined,
    focusedSubmission: undefined,
    isPublicListRequesting: true,
    isPrivateListRequesting: true,
    isDetailsRequesting: true,
    submissionDetails: undefined,
    readme: undefined,
    isFileUploadinging: false,
    uploadPredictionsDatasetId: undefined,
};

export default function submission(
    state: SubmissionsState = initialState,
    action: SubmissionsData
) {
    switch (action.type) {
        case SubmissionsAction.REQUEST_PUBLIC_LIST: {
            return {
                ...state,
                isPublicListRequesting: true,
            };
        }

        case SubmissionsAction.REQUEST_PRIVATE_LIST: {
            return {
                ...state,
                isPrivateListRequesting: true,
            };
        }

        case SubmissionsAction.CLEAR_PUBLIC_LIST: {
            return {
                ...state,
                publicSubmissions: undefined,
            };
        }

        case SubmissionsAction.CLEAR_PRIVATE_LIST: {
            return {
                ...state,
                privateSubmissions: undefined,
            };
        }

        case SubmissionsAction.GET_PUBLIC_LIST: {
            return {
                ...state,
                publicSubmissions: action.submissions,
                isPublicListRequesting: false,
            };
        }

        case SubmissionsAction.GET_PRIVATE_LIST: {
            return {
                ...state,
                privateSubmissions: action.submissions,
                isPrivateListRequesting: false,
            };
        }

        case SubmissionsAction.CLEAR_DETAIL: {
            return {
                ...state,
                submissionDetails: undefined,
                isDetailsRequesting: false,
                readme: undefined,
            };
        }

        case SubmissionsAction.REQUEST_DETAIL: {
            return {
                ...state,
                isDetailsRequesting: true,
            };
        }

        case SubmissionsAction.SELECT_DETAIL: {
            return {
                ...state,
                isDetailsRequesting: false,
                submissionDetails: action.submissionDetails,
                readme: action.readme,
            };
        }

        case SubmissionsAction.README_UPDATE: {
            if (action.submissionId !== state.submissionDetails?.id) {
                return state;
            }
            return {
                ...state,
                readme: action.readme,
            };
        }

        case SubmissionsAction.UPLOADING_PREDICTION_FILE: {
            return merge({}, state, {
                isFileUploadinging: true,
            });
        }

        case SubmissionsAction.UPLOAD_PREDICTION_FILE: {
            return merge({}, state, {
                uploadPredictionsDatasetId: action.uploadPredictionsDatasetId,
                isFileUploadinging: false,
            });
        }

        case SubmissionsAction.UPDATE: {
            const updatedSubmissionDetails =
                state.submissionDetails && action.submissionId === state.submissionDetails.id
                    ? action.submissionPatchData
                    : state.submissionDetails;
            let { privateSubmissions: updatedPrivateSubmissions } = state;
            if (updatedPrivateSubmissions && action.submissionId && action.submissionPatchData) {
                updatedPrivateSubmissions = replaceItemWithId(
                    updatedPrivateSubmissions,
                    action.submissionId,
                    action.submissionPatchData
                );
            }
            let { publicSubmissions: updatedPublicSubmissions } = state;
            if (updatedPublicSubmissions && action.submissionId && action.submissionPatchData) {
                updatedPublicSubmissions = replaceItemWithId(
                    updatedPublicSubmissions,
                    action.submissionId,
                    action.submissionPatchData
                );
            }
            return merge({}, state, {
                submissionDetails: updatedSubmissionDetails,
                privateSubmissions: updatedPrivateSubmissions,
                publicSubmissions: updatedPublicSubmissions,
            });
        }

        case SubmissionsAction.FOCUS_DETAIL: {
            return {
                ...state,
                focusedSubmission: action.focusedSubmission,
            };
        }

        default: {
            return state;
        }
    }
}
