import { Leaderboard } from '../leaderboards';
import { SubmissionPatchSpec, SubmissionPost, SubmissionWithInvalidBlindScore } from '../types';
import {
    camelizeKeys,
    decamelizeKeys,
    deserializeMoments,
    transformResponse,
    transformRequest,
} from '../util';

import axios from './axios';

export function getSubmissions(leaderboardId: string) {
    const promise = axios.get(`/api/leaderboards/${leaderboardId}/submissions/published`, {
        transformResponse: transformResponse(camelizeKeys, deserializeMoments),
    });
    // promise.then((res) => console.log('getSubmissions', res));
    return promise;
}

export function getUserSubmissions(leaderboardId: string) {
    const promise = axios.get(`/api/leaderboards/${leaderboardId}/submissions/user`, {
        transformResponse: transformResponse(camelizeKeys, deserializeMoments),
    });
    // promise.then((res) => console.log('getSubmissions', res));
    return promise;
}

export function getSubmissionDetails(leaderboardId: string, submissionId: string) {
    const promise = axios.get(`/api/v2/submissions/lb/${leaderboardId}/sub/${submissionId}`, {
        transformResponse: transformResponse(camelizeKeys, deserializeMoments),
    });
    return promise;
}

export function patchSubmission(
    leaderboardId: string,
    submissionId: string,
    patchData: SubmissionPatchSpec,
    decamelize: boolean = true
) {
    return axios.patch(
        `/api/v2/submissions/lb/${leaderboardId}/sub/${submissionId}`,
        patchData,
        decamelize ? { transformRequest: transformRequest(decamelizeKeys) } : undefined
    );
}

export function createSubmission(leaderboard: Leaderboard, sub: SubmissionPost): Promise<any> {
    return axios.post(`/api/leaderboards/${leaderboard.id}/submissions`, sub, {
        // This tells axios to convert camelCase to snake_case, which the API expects.
        // TODO: Remove this, and just use camelCase throughout.
        transformRequest: transformRequest(decamelizeKeys),
    });
}

export function uploadPredictionsFile(leaderboardId: string, data: FormData): Promise<any> {
    return axios.post(`/api/leaderboards/${leaderboardId}/predictions`, data);
}

export function publishSubmission(submissionId: string): Promise<any> {
    return axios.put(`/api/submissions/${submissionId}/publish`);
}

export function findSubmissionsWithInvalidBlindScores(): Promise<
    SubmissionWithInvalidBlindScore[]
> {
    return axios
        .get<SubmissionWithInvalidBlindScore[]>(`/api/submissions/invalid`)
        .then((r) => r.data);
}
