import React from 'react';
import styled from 'styled-components';

import { ellipsesify, getFullTitleIfTruncated } from '../../util';

interface Props {
    /** Full text to truncate. */
    text: string;
    /** Max characters to show before truncating. */
    maxCharacters: number;
    className?: string;
}

const TruncatedStringWithTitle: React.FunctionComponent<Props> = (props) => {
    return (
        <div
            className={props.className}
            title={getFullTitleIfTruncated(props.text, props.maxCharacters)}>
            {ellipsesify(props.text, props.maxCharacters)}
        </div>
    );
};

export default styled(TruncatedStringWithTitle)``;
