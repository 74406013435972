import { Leaderboard } from '../leaderboards';
import { Metric, MetricScore, PublishStatus, Submission } from '../types';

import { Dictionary } from './base';

export const shouldShowScores = (record: Submission, leaderboard: Leaderboard): boolean => {
    return leaderboard.showUnpublishedScores || record.publishStatus === PublishStatus.Published;
};

export const getScoreCompareValue = (
    record: Submission,
    score: MetricScore | undefined,
    leaderboard: Leaderboard
) => {
    if (
        score !== undefined &&
        score.blindScore !== undefined &&
        shouldShowScores(record, leaderboard)
    ) {
        return score.blindScore;
    }
    return 0;
};

export const getMerticScoreByKey = (
    key: string,
    metricScores: Dictionary<MetricScore>
): MetricScore | undefined => {
    const foundKey = Object.keys(metricScores).find(
        (mk: string) => metricScores[mk].metricKey === key
    );
    if (foundKey) {
        return metricScores[foundKey];
    }
    return undefined;
};

export const getMerticByKey = (key: string, metrics: Metric[]): Metric | undefined => {
    return metrics.find((m) => m.key === key);
};
