import * as React from 'react';

import styled from 'styled-components';

export const AdminUsage: React.FunctionComponent = () => (
    <Wrapper>
        <p>
            You have been granted permission to perform administrative actions on AI2's
            Leaderboards.
        </p>
        <p>
            This lets you create new leaderboards, edit existing ones, see all submissions (even
            unpublished ones), see details about the submissions (submitter contact information),
            unpublish anyone's submissions, and add/remove other admins. Some of these can be done
            on the website, others have to be done via an API call. Please{' '}
            <a href="https://github.com/allenai/leaderboard/blob/main/docs/api.md">read the docs</a>
            .
        </p>
        <p>
            <b>This permission comes with responsibility.</b> AI2's Leaderboards are used by the
            general public, and administrators have access to private and confidential information
            that submitters have trusted us with.{' '}
            <b>You must exercise caution and restraint when taking actions</b>, because they have
            effects on others work, on our presentation of their work, and on AI2's reputation.
            Please consider these points:
        </p>
        <p>
            <ol>
                <li>
                    Administrative operations will reveal private details of submitters, like their
                    names, email addresses, affiliations, which they do not wish to publish. Don't
                    distribute these facts to others.
                </li>

                <li>
                    As an administrator, you may contact a submitter about their submission, as it
                    relates to the Leaderboard. You may not contact the submitter for any other
                    reason.
                </li>

                <li>
                    Administrative operations will reveal unpublished submissions, including the
                    evaluation scores. If you plan to submit competitive entries to a leaderboard,
                    these entries may influence your decision to compete fairly.
                </li>
            </ol>
        </p>

        <p>
            If you have questions about taking an administrative action, please email{' '}
            <a href="mailto:reviz@allenai.org">reviz@allenai.org</a>.
        </p>
    </Wrapper>
);

const Wrapper = styled.div`
    padding: ${({ theme }) => theme.spacing.xs2};

    border-color: ${({ theme }) => theme.color.R8};
    border-width: 2px;
    border-style: solid;

    color: ${({ theme }) => theme.color.R10};
    background-color: ${({ theme }) => theme.color.O4};
`;
