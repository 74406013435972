import { AxiosError, AxiosRequestTransformer, AxiosResponseTransformer } from 'axios';
import humps from 'humps';
import { forOwn, isArray } from 'lodash';
import moment from 'moment';

import axios from '../api/axios';

import { alert } from '../components/general/Shared';
import { GoLangZeroDate } from '../constants';

/*
 * Searches for timestamps in the received objects, and converts them into Moment.js
 * objects for use elsewhere in the app. Also handles Go's zero values for dates.
 */
export function deserializeMoments(data: any) {
    const parseDatesToMoments = (thing: any) => {
        forOwn(thing, (value, key, object) => {
            if (typeof value === 'string') {
                // If value is a string made of just digits, then assume it's just
                // a string with digits and don't try to convert it to a time. This
                // means an ISO 8601 date like "2013" (the year 2013) or "20180102"
                // (January 2nd, 2018) will not be converted to a date.
                //
                // See this bug: https://github.com/allenai/leaderboard/issues/1694
                if (/^\d+$/.test(value)) {
                    object[key] = value;
                    return;
                }

                // Consider Go's zero value for a date equivalent to that date being null in JS.
                if (value === GoLangZeroDate) {
                    object[key] = null;
                    return;
                }

                const asMoment = moment(value, moment.ISO_8601);
                if (asMoment.isValid()) {
                    object[key] = asMoment;
                }
            } else if (typeof value === 'object') {
                object[key] = parseDatesToMoments(value);
            }
        });
        return thing;
    };

    if (isArray(data)) {
        return data.map(parseDatesToMoments);
    } else {
        return parseDatesToMoments(data);
    }
}

/**
 * The backend sends JSON with SNAKE_CASE keys, so send the objects through humps
 * to make them a friendlier camelCase.
 *
 * TODO: This is bizarre and wasteful of CPU cycles. We deserialize and then re-serialize
 * every API response, all in the name of case conversion.
 */
export function camelizeKeys(data: any) {
    const camelize = (thing: object[]) => {
        return humps.camelizeKeys(thing);
    };
    if (isArray(data)) {
        return data.map(camelize);
    } else {
        return camelize(data);
    }
}

export function decamelizeKeys(data: any) {
    const decamelize = (thing: string) => {
        return JSON.stringify(humps.decamelizeKeys(JSON.parse(thing)));
    };
    if (isArray(data)) {
        return data.map(decamelize);
    } else {
        return decamelize(data);
    }
}

export function transformResponse(
    ...t: AxiosResponseTransformer[]
): (AxiosRequestTransformer | AxiosResponseTransformer)[] {
    let defaults: AxiosResponseTransformer[] = [];
    if (axios.defaults.transformResponse) {
        if (Array.isArray(axios.defaults.transformResponse)) {
            defaults = defaults.concat(axios.defaults.transformResponse);
        } else {
            defaults.push(axios.defaults.transformResponse);
        }
    }
    return defaults.concat(t);
}

export function transformRequest(...t: AxiosRequestTransformer[]): AxiosRequestTransformer[] {
    let defaults: AxiosRequestTransformer[] = [];
    if (axios.defaults.transformRequest) {
        if (Array.isArray(axios.defaults.transformRequest)) {
            defaults = defaults.concat(axios.defaults.transformRequest);
        } else {
            defaults.push(axios.defaults.transformRequest);
        }
    }
    return defaults.concat(t);
}

// display response message if we have one, else just display error cold
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function handleApiError(
    error: any,
    title: string,
    messageStart: string,
    messageEndIfNotResponse: string
) {
    if (error.response && error.response.data) {
        console.error(error.response.data);
        alert(title, `${messageStart}: ${error.response.data.message}`);
    } else {
        console.error(messageEndIfNotResponse, error);
        alert(title, `${messageStart}: ${messageEndIfNotResponse}`);
    }
}

/**
 * An enumeration of error codes returned by the API.
 * @see ../../api/error.go
 */
export enum ErrorCode {
    // An error the frontend doesn't know anything about
    Unknown = -1,
}

/**
 * Generic model capturing the fields we care about that are returned by the
 * API when conveying a known error.
 */
class ApiError {
    constructor(
        /* The error code, which tells us what type of error occured. */
        readonly code: ErrorCode,
        /* The original error, just in case we need it. */
        readonly fields: {},
        /* Extra metadata attached to the error. */
        readonly cause: AxiosError
    ) {}
}

/**
 * An error returned from the API that the UI doesn't understand.
 */
class UnknownApiError extends ApiError {
    constructor(cause: AxiosError) {
        super(ErrorCode.Unknown, {}, cause);
    }
}

/**
 * Error indicating that a user has attempted to publish too often.
 */
export class PublishQuotaExceededApiError extends ApiError {
    constructor(code: ErrorCode, readonly fields: { secondsLeft: number }, cause: AxiosError) {
        super(code, fields, cause);
    }
}

/**
 * Returns the specific ApiError instance is one is associated with the
 * provided error, otherwise an UnknownError is returned.
 */
export function unpackApiError(
    err: AxiosError<{ error_code: number; seconds_left: number }>
): ApiError {
    if (err.response && err.response.data && typeof err.response.data.error_code === 'number') {
        return new PublishQuotaExceededApiError(
            err.response.data.error_code,
            { secondsLeft: err.response.data.seconds_left },
            err
        );
    } else {
        return new UnknownApiError(err);
    }
}
