import { textStyles } from '@allenai/varnish/components';
import { DownloadOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import * as React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { DefaultReadmeTemplate } from '../../constants';
import { Leaderboard } from '../../leaderboards';
import {
    Config,
    PublishStatus,
    Submission,
    SubmissionPatchSpec,
    SubmissionStatus,
} from '../../types';
import { EmptyRouteAwareComponentProps } from '../../util';
import { EditableMarkdown } from '../general/EditableMarkdown';
import { EditableText, EditContent } from '../general/EditableText';
import { MarkdownReader } from '../general/MarkdownReader';
import MetricScores from '../general/MetricScores';
import { None } from '../general/None';
import PageHeader from '../general/PageHeader';
import RelativeTime from '../general/RelativeTime';
import { AnchorButton, NavLink, SectionHeader } from '../general/Shared';
import { WithUpdating } from '../general/WithUpdating';
import TwoColumnLayout from '../layout/TwoColumnLayout';
import { H1, H2 } from '../typography';

import SubmissionStatusWithIcon from './SubmissionStatusWithIcon';

interface Props {
    submissionDetails: Submission;
    config: Config;
    canEdit: boolean;
    className?: string;
    selectedLeaderboard: Leaderboard;

    onUpdate(patchData: SubmissionPatchSpec, decamelize?: boolean): void;
}

const SubmissionDetails: React.FunctionComponent<EmptyRouteAwareComponentProps<Props>> = (
    props
) => {
    if (!props.submissionDetails) {
        return (
            <div className={props.className}>
                <PageHeader.Grid>
                    <PageHeader.Content>
                        <NavLink to={`/${props.selectedLeaderboard.id}`}>
                            <img
                                src={props.selectedLeaderboard.metadata.logo}
                                width="90"
                                height="90"
                                alt={`${props.selectedLeaderboard.metadata.longName} Logo`}
                            />
                        </NavLink>
                        <H1>Submission Not Found</H1>
                    </PageHeader.Content>
                </PageHeader.Grid>
            </div>
        );
    }

    const hasReadme =
        props.submissionDetails.readme && props.submissionDetails.readme.trim() !== '';
    const readmeTemplate =
        props.selectedLeaderboard.metadata.readmeTemplate || DefaultReadmeTemplate;

    const showScores =
        props.submissionDetails.publishStatus === PublishStatus.Published ||
        (props.submissionDetails.blindStatus === SubmissionStatus.Succeeded &&
            props.selectedLeaderboard.showUnpublishedScores &&
            props.canEdit);
    return (
        <div className={props.className}>
            <PageHeader.Grid>
                <PageHeader.Content alignItems="center">
                    <NavLink to={`/${props.selectedLeaderboard.id}`}>
                        <img
                            src={props.selectedLeaderboard.metadata.logo}
                            width="90"
                            height="90"
                            alt={`${props.selectedLeaderboard.metadata.longName} Logo`}
                        />
                    </NavLink>
                    <H1>Submission Details</H1>
                </PageHeader.Content>
            </PageHeader.Grid>
            <H2>
                {props.canEdit ? (
                    <EditableText
                        text={props.submissionDetails.name}
                        onSubmitText={(name) => props.onUpdate({ name })}
                    />
                ) : (
                    props.submissionDetails.name
                )}
            </H2>

            <TwoColumnLayout>
                <LeftCol>
                    <SectionHeader>Details</SectionHeader>
                    <IndentedSection>
                        <Timestamp>
                            <textStyles.Small>
                                <b>Created:</b>
                            </textStyles.Small>{' '}
                            <RelativeTime time={props.submissionDetails.created} /> by{' '}
                            {props.submissionDetails.user.name ||
                                props.submissionDetails.user.name ||
                                props.submissionDetails.user.id}
                        </Timestamp>
                        <Timestamp>
                            <textStyles.Small>
                                <b>Published:</b>
                            </textStyles.Small>{' '}
                            <RelativeTime time={props.submissionDetails.published} /> by{' '}
                            {props.submissionDetails.user.name ||
                                props.submissionDetails.user.name ||
                                props.submissionDetails.user.id}
                        </Timestamp>
                        {props.canEdit ? (
                            <Field>
                                <textStyles.Small>
                                    <b>Contact Email:</b>
                                </textStyles.Small>
                                <br />
                                <Comment>
                                    Your email will never be shared with anyone outside of AI2.{' '}
                                    We'll use it to contact you about your submission or events{' '}
                                    relevant to {props.selectedLeaderboard.metadata.longName}.
                                </Comment>
                                <EditableText
                                    inputType="email"
                                    text={props.submissionDetails.email}
                                    onSubmitText={(email) => props.onUpdate({ email })}
                                    required
                                />
                            </Field>
                        ) : null}
                        <Description>
                            {props.canEdit ? (
                                <div>
                                    <textStyles.Small>
                                        <b>Description:</b>
                                    </textStyles.Small>
                                    <EditableText
                                        text={props.submissionDetails.description}
                                        draftText={
                                            props.submissionDetails.description &&
                                            props.submissionDetails.description !== ''
                                                ? undefined
                                                : 'Description'
                                        }
                                        onSubmitText={(description) =>
                                            props.onUpdate({ description })
                                        }
                                    />
                                </div>
                            ) : (
                                <div>
                                    <textStyles.Small>
                                        <b>Description:</b>
                                    </textStyles.Small>
                                    {props.submissionDetails.description}
                                </div>
                            )}
                        </Description>

                        <Contributors>
                            {props.canEdit ? (
                                <div>
                                    <textStyles.Small>
                                        <b>Contributors:</b>
                                    </textStyles.Small>
                                    <EditableText
                                        text={props.submissionDetails.contributors}
                                        draftText={
                                            props.submissionDetails.contributors &&
                                            props.submissionDetails.contributors !== ''
                                                ? undefined
                                                : 'Contributors'
                                        }
                                        onSubmitText={(contributors) =>
                                            props.onUpdate({ contributors })
                                        }
                                    />
                                </div>
                            ) : (
                                <div>
                                    <textStyles.Small>
                                        <b>Contributors:</b>
                                    </textStyles.Small>{' '}
                                    {props.submissionDetails.contributors &&
                                    props.submissionDetails.contributors.trim() !== '' ? (
                                        props.submissionDetails.contributors
                                    ) : (
                                        <None />
                                    )}
                                </div>
                            )}
                        </Contributors>
                    </IndentedSection>

                    {props.canEdit ? (
                        <>
                            <SectionHeader>Readme</SectionHeader>
                            <IndentedSection>
                                <EditableMarkdown
                                    text={props.submissionDetails.readme}
                                    draftText={hasReadme ? undefined : readmeTemplate}
                                    onSubmitText={(readme) => props.onUpdate({ readme })}
                                />
                            </IndentedSection>
                        </>
                    ) : hasReadme ? (
                        <>
                            <SectionHeader>Readme</SectionHeader>
                            <IndentedSection>
                                <MarkdownReader source={props.submissionDetails.readme} />
                            </IndentedSection>
                        </>
                    ) : null}
                </LeftCol>
                <RightCol>
                    {showScores && (
                        <LinkWithButtonStyle
                            size="small"
                            href={`/api/submissions/lb/${props.selectedLeaderboard.id}/sub/${props.submissionDetails.id}/scores`}>
                            <DownloadOutlined />
                            Download Scores
                        </LinkWithButtonStyle>
                    )}
                    <ScoreArea>
                        {showScores ? (
                            <Score
                                scores={props.submissionDetails.metricScores}
                                selectedLeaderboard={props.selectedLeaderboard}
                            />
                        ) : null}
                    </ScoreArea>
                    <Status>
                        {props.submissionDetails.blindStatus ? (
                            <StatusArea status={props.submissionDetails.blindStatus} />
                        ) : null}
                    </Status>
                    <div>
                        <SectionHeader>Want to create your own submission?</SectionHeader>
                        <div>
                            <AnchorButton
                                href={`/${props.selectedLeaderboard.id}/submissions/get-started`}>
                                Get Started Now
                            </AnchorButton>
                        </div>
                    </div>
                </RightCol>
            </TwoColumnLayout>
        </div>
    );
};

const LinkWithButtonStyle = styled(Button)`
    width: 100%;
    && {
        color: ${({ theme }) => theme.color.N9};
    }
`;

const ScoreArea = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: ${(props) => props.theme.spacing.lg};
    margin-bottom: ${(props) => props.theme.spacing.lg};
    max-height: 30vh;
    overflow-y: auto;
`;

const Score = styled(MetricScores)`
    margin: 0 ${(props) => props.theme.spacing.md} ${(props) => props.theme.spacing.xs} 0;
`;

const StatusArea = styled(SubmissionStatusWithIcon)`
    margin: ${(props) => props.theme.spacing.sm} 0 0;
`;

export const IndentedSection = styled.div`
    margin-left: ${(props) => props.theme.spacing.xl};
`;

const Timestamp = styled.div`
    margin-bottom: ${(props) => props.theme.spacing.md};
    span {
        margin-right: ${(props) => props.theme.spacing.sm};
        align-self: center;
    }
    &:last-child {
        margin-bottom: 0;
    }
`;

const Status = styled.div`
    margin-bottom: ${(props) => props.theme.spacing.lg};

    &:last-child {
        margin-bottom: 0;
    }
`;

const Contributors = styled.div`
    margin-bottom: ${(props) => props.theme.spacing.md};

    span {
        margin-right: ${(props) => props.theme.spacing.sm};
        align-self: center;
    }

    ${EditContent} {
        flex-grow: 1;

        input {
            width: 100%;
            box-sizing: border-box;
            display: block;
        }
    }
`;

const Field = styled.div`
    margin-bottom: ${(props) => props.theme.spacing.md};
`;

const Description = styled(Field)`
    span {
        margin-right: ${(props) => props.theme.spacing.sm};
        align-self: center;
    }
    overflow: hidden;
    text-overflow: ellipsis;
`;

const LeftCol = styled.div`
    word-break: break-word;
`;

const RightCol = styled.div`
    ${AnchorButton} {
        border-color: ${(props) => props.theme.color.B2.toString()};
        font-size: 1.125em; /* 18px */
        &:hover {
            border: 2px solid ${(props) => props.theme.color.A8.toString()};
        }
    }
`;

const Comment = styled(textStyles.Small)`
    display: block;
    margin: ${(props) => props.theme.spacing.xxs} 0;
`;

export default withRouter(WithUpdating(SubmissionDetails));
