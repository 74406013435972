// TODO: we should move over to varnish breakpoints

export enum Breakpoint {
    XSMALL,
    SMALL,
    MEDIUM,
    LARGE,
    XLARGE,
    XXLARGE,
}

export function above(breakpoint: Breakpoint) {
    switch (breakpoint) {
        case Breakpoint.XSMALL:
            return '(min-width: 577px)';
        case Breakpoint.SMALL:
            return '(min-width: 769px)';
        case Breakpoint.MEDIUM:
            return '(min-width: 993px)';
        case Breakpoint.LARGE:
            return '(min-width: 1201px)';
        case Breakpoint.XLARGE:
            return '(min-width: 1601px)';
        default:
            throw new Error(`Invalid breakpoint, nothing above: ${breakpoint}`);
    }
}

export function below(breakpoint: Breakpoint) {
    switch (breakpoint) {
        case Breakpoint.XSMALL:
            return '(min-width: 480px)';
        case Breakpoint.SMALL:
            return '(max-width: 576px)';
        case Breakpoint.MEDIUM:
            return '(max-width: 768px)';
        case Breakpoint.LARGE:
            return '(max-width: 992px)';
        case Breakpoint.XLARGE:
            return '(max-width: 1200px)';
        case Breakpoint.XXLARGE:
            return '(max-width: 1600px)';
        default:
            throw new Error(`Invalid breakpoint, nothing below: ${breakpoint}`);
    }
}
