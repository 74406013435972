import React from 'react';
import styled from 'styled-components';

import { Leaderboard } from '../../leaderboards';
import { Config } from '../../types';
import { MetaTags } from '../general/MetaTags';
import { LinkButton, SectionHeader, StyledMarkdown } from '../general/Shared';
import MaxWidth from '../layout/MaxWidth';

interface Props {
    className?: string;
    config: Config;
    selectedLeaderboard: Leaderboard;
}

class About extends React.PureComponent<Props> {
    render() {
        return (
            <div className={this.props.className}>
                <MetaTags
                    title={`About — ${this.props.selectedLeaderboard.metadata.longName}`}
                    description={`Details about the ${this.props.selectedLeaderboard.metadata.longName} Leaderboard`}
                />
                <Content>
                    <MaxWidth>
                        <SectionHeader>
                            {this.props.selectedLeaderboard.metadata.team.name}
                        </SectionHeader>
                        <StyledMarkdown
                            source={this.props.selectedLeaderboard.metadata.team.description}
                        />
                        <SectionHeader>Leaderboard</SectionHeader>
                        <section>
                            <StyledMarkdown
                                source={this.props.selectedLeaderboard.metadata.purpose}
                            />
                            The leaderboard is powered by Beaker, AI2's powerful tool for rapid
                            reproducible research.
                        </section>
                        <SectionHeader>
                            Example {this.props.selectedLeaderboard.metadata.shortName} Question
                        </SectionHeader>
                        <section>
                            <StyledMarkdown
                                source={this.props.selectedLeaderboard.metadata.example}
                            />
                        </section>
                        <SectionHeader>Contact</SectionHeader>
                        <section>
                            If you need any help, please email us at{' '}
                            <LinkButton href="mailto:leaderboard@allenai.org">
                                leaderboard@allenai.org
                            </LinkButton>{' '}
                            and say you're asking about
                            leaderboard <b>
                            {this.props.selectedLeaderboard.metadata.shortName}
                            </b>. Please include a submission URL if you are asking about a specific submission.
                        </section>
                    </MaxWidth>
                </Content>
            </div>
        );
    }
}

const Content = styled.div`
    color: ${(props) => props.theme.color.N9.toString()};
`;

export default styled(About)`
    ${SectionHeader} {
        align-self: auto;
        margin-top: 25px;
    }
`;
