import { textStyles } from '@allenai/varnish/components';
import { confirmAlert } from 'react-confirm-alert';
import React from 'react';
import Markdown from 'react-markdown';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { above, Breakpoint } from '../../Breakpoint';
import { H3 } from '../typography';

export const SectionHeader = styled(H3)`
    margin: 30px 0 15px;

    &:first-child {
        margin-top: 0;
    }
`;

export const SubSectionHeader = styled(textStyles.Big)`
    margin: 30px 0 15px;
    font-weight: bold;

    &:first-child {
        margin-top: 0;
    }
`;

// TODO: We should use the base varnish buttons

const baseButtonStyles = css`
    ${({ theme }) => css`
        &&& {
            font-size: 0.9375em; /* 15px */
            color: ${theme.color.B6.toString()};
            font-weight: bold;
            background: transparent;
            padding: 10px;
            border: 2px solid ${theme.color.B2.toString()};
            border-radius: 5px;
            cursor: pointer;
            svg {
                vertical-align: bottom;
                margin-right: 10px;
                height: 19px;
            }
            :hover {
                border-color: ${theme.color.A8.toString()};
            }
            :disabled {
                color: ${theme.color.N6.toString()};
                border-color: ${theme.color.A1.toString()};
                text-decoration: none;
                cursor: not-allowed;
            }
        }
    `}
`;

export const Button = styled.button.attrs(() => ({
    type: 'button',
}))<React.ComponentPropsWithoutRef<'button'>>`
    ${baseButtonStyles}
`;

export const AnchorButton = styled.a`
    ${baseButtonStyles}

    display: inline-block;
    text-decoration: none;
    box-sizing: border-box;
    text-align: center;
    user-select: none;
    width: 100%;
`;

export const baseLinkStyles = css`
    ${({ theme }) => css`
        color: ${theme.color.B6.toString()};
        text-decoration: underline;
        cursor: pointer;

        :hover {
            color: ${theme.color.A8.toString()};
        }
    `}
`;

export const LinkButton = styled.a`
    display: inline-block;
    ${baseLinkStyles}
`;

export const navButtonStyles = css`
    ${baseButtonStyles}

    ${({ theme }) => css`
        align-items: center;
        border: 2px solid ${theme.color.B2.toString()};
        display: flex;
        justify-content: center;
        font-size: 1.125em;
        margin-bottom: 30px;
        text-decoration: none;
        box-sizing: border-box;
        user-select: none;
        width: 100%;

        &:hover {
            border: 2px solid ${theme.color.A8.toString()};
        }
    `}
`;

export const NavButton: any = styled(ReactRouterNavLink)`
    ${navButtonStyles}
`;

export const Rank = styled.section<{ isBest?: boolean }>`
    ${({ theme, isBest }) => `
        color: ${theme.color.N1.toString()};
        font-size: 1.25em;
        font-weight: bold;
        background-color: ${isBest ? theme.color.O8.toString() : theme.color.B6.toString()};
        height: 2em;
        width: 2em;
        border-radius: 2em;
        margin: auto;
        text-align: center;
        line-height: 2em;
    `}
`;

const noop = () => {};
export function confirm(
    title: string,
    message: string,
    yesFunc?: () => void,
    childrenElement?: JSX.Element,
    noFunc?: () => void
) {
    confirmAlert({
        title: title,
        message: message,
        buttons: [
            { label: 'Yes', onClick: yesFunc || noop },
            { label: 'No', onClick: noFunc || noop },
        ],
        childrenElement: () => childrenElement,
    });
}

export function alert(
    title: string,
    message?: string,
    okFunc?: () => void,
    childrenElement?: JSX.Element
) {
    confirmAlert({
        title: title,
        message: message,
        buttons: [{ label: 'OK', onClick: okFunc || noop }],
        childrenElement: () => childrenElement,
    });
}

export const SpacedBr = styled.br`
    line-height: 2;
`;

const codeStyles = css`
    ${({ theme }) => css`
        border-radius: 5px;
        margin: 0;
        padding: 2px;
        background-color: ${theme.color.N3.toString()};
        overflow: auto;
        font-size: 0.8em;
    `}
`;

const codeInPreStyles = css`
    ${({ theme }) => css`
        padding: ${theme.spacing.sm};
        display: block;
        white-space: pre;
    `}
`;

export const LoadingMsg = styled.div`
    display: flex;
    line-height: 1;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex: 100% 1 1;

    svg {
        margin-right: 10px;
    }
`;

export const LoadingContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        background: #fff;
        max-width: ${theme.breakpoints.xl};
        box-sizing: border-box;
        margin: 0 auto;

        @media ${above(Breakpoint.SMALL)} {
            border-top: 1px solid ${theme.color.A1.toString()};
        }

        @media ${above(Breakpoint.MEDIUM)} {
            border-top: none;
        }
    `}
`;

const listStyles = css`
    margin: 1rem 0;
    padding: 0 2rem;
    &:last-child {
        margin-bottom: 0;
    }

    li {
        margin: 0.1rem 0;
        padding: 0;
    }
`;

/**
 * This is used to style HTML rendered via markdown that's entered by
 * LB administrators into the LB config file.
 */
export const StyledMarkdown = styled(Markdown)`
    ol,
    ul {
        ${listStyles}
    }
    a {
        ${baseLinkStyles}
    }
    code {
        ${codeStyles}
    }
    pre > code {
        ${codeInPreStyles}
    }

    ${({ theme }) => css`
        table {
            border-spacing: 0;
        }
        th {
            padding: 0.5rem;
            border-bottom: 1px solid ${theme.color.A1.toString()};
            text-align: left;
        }
        td {
            padding: 0.5rem;
            vertical-align: top;
            max-width: 60%; /* TODO This is a hack to make the DROP table look ok. We
                           should figure out a better long term solution in the
                           future */
        }
        p {
            margin: 15px 0;
        }
    `}
`;

export const OlWithSpace = styled.ol`
    ${listStyles}
`;

const headerLinkStyles = css`
    ${baseLinkStyles};

    ${({ theme }) => css`
        color: ${theme.color.B6.toString()};
        font-family: ${theme.typography.font.family.headline};
        font-weight: 600;
        text-decoration: none;
    `}
`;

export const ExternalHeaderLink = styled.a`
    ${headerLinkStyles};
`;

export const NavLink = styled(ReactRouterNavLink)`
    ${baseLinkStyles};
`;

export const ErrorMessage = styled.div`
    color: ${({ theme }) => theme.color.R8.toString()};
`;
