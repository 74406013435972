import { Typography, Button } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { below, Breakpoint } from '../../Breakpoint';
import { Leaderboard } from '../../leaderboards';
import { formatDate, formatScore, pluralize } from '../../util';
import MaxWidth from '../layout/MaxWidth';
import Page from '../layout/Page';
import { H1 } from '../typography';

interface Props {
    leaderboard: Leaderboard;
    className?: string;
}

const DatasetCardFeatured: React.FunctionComponent<Props> = ({ leaderboard, ...props }) => {
    return (
        <div className={props.className}>
            <FeaturedBackground>
                <FeaturedPage>
                    <MaxWidth>
                        <Featured>
                            Featured Leaderboard{' '}
                        </Featured>
                        <Name>
                            <NameLink to={`/${leaderboard.id}`}>
                                {leaderboard.metadata.longName}
                            </NameLink>
                        </Name>
                        <Description ellipsis={{ rows: 9, expandable: true, symbol: 'more' }}>
                            {leaderboard.metadata.description}
                        </Description>
                        {leaderboard.submissionCount &&
                        leaderboard.topScore &&
                        leaderboard.lastPublished ? (
                            <Metadata>
                                <SizingButton href={`/${leaderboard.id}`}>
                                    View submissions
                                </SizingButton>
                                <SubCount>
                                    {pluralize('Submission', leaderboard.submissionCount || 0)}
                                </SubCount>
                                <Bullet>&bull;</Bullet>
                                <TopScore>
                                    Top score:{' '}
                                    <strong>
                                        {formatScore(
                                            leaderboard.topScore,
                                            leaderboard.metadata.metricPrecision
                                        )}
                                    </strong>
                                </TopScore>
                                <Bullet>&bull;</Bullet>
                                <Updated>Updated: {formatDate(leaderboard.lastPublished)}</Updated>
                            </Metadata>
                        ) : null}
                    </MaxWidth>
                </FeaturedPage>
            </FeaturedBackground>
        </div>
    );
};

const FeaturedPage = styled(Page)`
    padding-top: 60px;
    padding-bottom: 60px;
`;

const Featured = styled.div`
    font-family: ${(props) => props.theme.typography.font.family.headline};
    text-transform: uppercase;
    color: #3caee7;
    font-size: 0.8125em; /* 13px */
    margin-bottom: 15px;
`;

const Name = styled(H1)`
    font-size: 2.1875em; /* 35px */
    margin-bottom: 15px;
`;

const NameLink = styled(NavLink)`
    && {
        &,
        &:active,
        &:hover,
        &:visited {
            color: ${(props) => props.theme.color.N1.toString()};
        }

        &,
        &:active,
        &:visited {
            text-decoration: none;
        }
    }
`;

const Description = styled(Typography.Paragraph)`
    color: ${(props) => props.theme.color.N1.toString()};
    max-width: 600px;

    a {
        color: ${(props) => props.theme.color.N3.toString()};
    }
`;

const Metadata = styled.div`
    color: ${(props) => props.theme.color.N1.toString()};
    display: flex;
    gap: ${(props) => props.theme.spacing.sm};
    align-items: center;
    flex-direction: row;
    margin-top: 30px;

    @media ${below(Breakpoint.MEDIUM)} {
        flex-direction: column;
    }
`;

const Bullet = styled.span`
    @media ${below(Breakpoint.MEDIUM)} {
        display: none;
    }
`;

// the sizes below convert us from an antd button size=small to size=large
const SizingButton = styled(Button)`
    && {
        height: 32px;
        line-height: 30px;
        font-size: 16px;

        @media ${below(Breakpoint.MEDIUM)} {
            height: 48px;
            line-height: 46px;
            font-size: 18px;
            width: 100%;
        }
    }
`;

const SubCount = styled.div``;

const TopScore = styled.div``;

const Updated = styled.div`
    color: ${(props) => props.theme.color.N3.toString()};
`;

const FeaturedBackground = styled.div`
    background: transparent url('/assets/images/featured.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;

    @media ${below(Breakpoint.MEDIUM)} {
        background: none;
    }
`;

export default styled(DatasetCardFeatured)`
    background: linear-gradient(#0c578d, #004271);
`;
