import React from 'react';
import { HelpCircle } from 'react-feather';
import { withRouter } from 'react-router';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { below, Breakpoint } from '../../Breakpoint';
import { Leaderboard } from '../../leaderboards';
import { Config } from '../../types';
import { EmptyObject, EmptyRouteAwareComponentProps } from '../../util';
import PageHeader from '../general/PageHeader';
import { NavLink, SectionHeader, StyledMarkdown } from '../general/Shared';
import TwoColumnLayout from '../layout/TwoColumnLayout';
import { H1 } from '../typography';
import DefaultRules from './DefaultRules';
import SubmissionDescription from './SubmissionDescription';
import { CircleSpinnerSVG } from '../general/CircleSpinnerSVG';

interface Props {
    className?: string;
    config: Config;
    selectedLeaderboard: Leaderboard;
    defaultEmail?: string;
}

interface State {
    name: string;
    email: string;
    file?: any;
    description: string;
    contributors: string;
    isSubmitting: boolean;
}

export class CreateSubmission extends React.PureComponent<
    EmptyRouteAwareComponentProps<Props>,
    State
> {
    constructor(props: EmptyRouteAwareComponentProps<Props>) {
        super(props);
        this.state = {
            name: '',
            email: props.defaultEmail || '',
            file: null,
            description: '',
            contributors: '',
            isSubmitting: false,
        };
    }

    private setName = (value: string) => {
        this.setState({ name: value });
    };

    private setEmail = (value: string) => {
        this.setState({ email: value });
    };

    private setFile = (value: any) => {
        this.setState({ file: value });
    };

    private setDescription = (value: string) => {
        this.setState({ description: value });
    };

    private setContributors = (value: string) => {
        this.setState({ contributors: value });
    };

    private setIsSubmitting = (value: boolean) => {
        this.setState({ isSubmitting: value });
    };

    private helpIconSize = 14;

    private handleSubmit = () => {
        // Calls to this function are guarded by Antd's form validation (which fields are
        // required, how long they are, etc.)
        this.setIsSubmitting(true);
        const form = document.getElementById('submission_form_element');
        (form as HTMLFormElement).submit();
    };

    render() {
        return (
            <div className={this.props.className}>
                <PageHeader.Grid>
                    <PageHeader.Content alignItems="center">
                        <NavLink to={`/${this.props.selectedLeaderboard.id}`}>
                            <img
                                src={this.props.selectedLeaderboard.metadata.logo}
                                width="90"
                                height="90"
                                alt={`${this.props.selectedLeaderboard.metadata.longName} Logo`}
                            />
                        </NavLink>
                        <H1>Create Submission</H1>
                    </PageHeader.Content>
                </PageHeader.Grid>
                <TwoColumnLayout>
                    <div>
                        <SectionHeader>Rules:</SectionHeader>
                        <section>
                            {this.props.selectedLeaderboard.metadata.customRules ? (
                                <StyledMarkdown
                                    source={this.props.selectedLeaderboard.metadata.customRules}
                                />
                            ) : (
                                <DefaultRules
                                    name={this.props.selectedLeaderboard.metadata.shortName}
                                />
                            )}
                        </section>
                        <Form
                            id="submission_form_element"
                            method="POST"
                            action={`/api/v3/leaderboards/${this.props.selectedLeaderboard.id}/submissions`}
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}>
                            <Ul>
                                <Li>
                                    <FormLabel>
                                        Name *{' '}
                                        <ToolTip
                                            size={this.helpIconSize}
                                            data-tip="How the leaderboard will<br/>refer to your experiment."
                                        />
                                    </FormLabel>
                                    {/* avg 6.16px per char at 15pt */}
                                    <NameInput
                                        name="name"
                                        placeholder={'Name of our new submission'}
                                        maxLength={54}
                                        minLength={3}
                                        required
                                        value={this.state.name}
                                        onChange={(evt) => this.setName(evt.target.value)}
                                    />
                                </Li>
                                <Li>
                                    <FormLabel>Contact Email *</FormLabel>
                                    <InputComment>
                                        Your email will never be shared with anyone outside of AI2.
                                        We'll use it to contact you about your submission or events
                                        relevant to{' '}
                                        {this.props.selectedLeaderboard.metadata.longName}.
                                    </InputComment>
                                    <Input
                                        name="email"
                                        type="email"
                                        placeholder="you@youremailprovider.com"
                                        required
                                        value={this.state.email}
                                        onChange={(evt) => this.setEmail(evt.target.value)}
                                    />
                                </Li>
                                <Li>
                                    <FormLabel>
                                        Description / Paper Title *{' '}
                                        <ToolTip
                                            size={this.helpIconSize}
                                            data-tip="How is your model novel?<br/>Has your model been published?"
                                        />
                                    </FormLabel>
                                    <InputComment>
                                        Please provide a description of your model and if available
                                        include a link to a code repository, an academic paper or a
                                        blog post.
                                    </InputComment>
                                    {/* avg 6.16px per char at 15pt */}
                                    <TextArea
                                        name="description"
                                        placeholder={
                                            'Description of your new submission including a link to more information'
                                        }
                                        maxLength={2000}
                                        minLength={3}
                                        rows={4}
                                        required
                                        value={this.state.description}
                                        onChange={(evt) => this.setDescription(evt.target.value)}
                                    />
                                </Li>
                                <Li>
                                    <FormLabel>
                                        Predictions *{' '}
                                        <ToolTip
                                            size={this.helpIconSize}
                                            data-tip="Which file contains your predictions?"
                                        />
                                    </FormLabel>
                                    <InputComment>
                                        Your upload must be smaller than 500 MB.
                                    </InputComment>
                                    <Input
                                        name="prediction_file"
                                        type="file"
                                        required
                                        value={this.state.file}
                                        onChange={(evt) => this.setFile(evt.target.value)}
                                    />
                                </Li>
                                <Li>
                                    <FormLabel>
                                        Contributors *{' '}
                                        <ToolTip
                                            size={this.helpIconSize}
                                            data-tip="Who contributed to your experiment?"
                                        />
                                    </FormLabel>
                                    <InputComment>
                                        We require non-anonymous submissions when posting to the
                                        leaderboard.
                                    </InputComment>
                                    <Input
                                        name="contributors"
                                        placeholder={
                                            'e.g. Jane Doe from the University of Washington in collaboration with John Doe from AI2'
                                        }
                                        required
                                        value={this.state.contributors}
                                        onChange={(evt) => this.setContributors(evt.target.value)}
                                    />
                                </Li>
                                <Li>
                                    <ButtonArea>
                                        <InputButton
                                            type="submit"
                                            value={
                                                this.state.isSubmitting ? 'Submitting' : 'Submit'
                                            }
                                            disabled={this.state.isSubmitting}
                                        />
                                        {this.state.isSubmitting && <CircleSpinnerSVG size="32" />}
                                    </ButtonArea>

                                    <Reminder>
                                        Once you submit, your prediction will be evaluated. Your
                                        submission will remain private until you publish it.
                                    </Reminder>
                                </Li>
                            </Ul>
                        </Form>
                    </div>
                    <div>
                        <SectionHeader>What is a Submission?</SectionHeader>
                        <section>
                            <p>
                                A submission includes information about your technique and a file
                                with predictions.
                            </p>
                            <p>
                                Once you submit your productions our system will evaluate it. Your
                                submission will remain private and anonymous until you explicitly
                                publish your results.
                            </p>
                        </section>
                        <SectionHeader>Predictions Format</SectionHeader>
                        <StyledMarkdown
                            source={this.props.selectedLeaderboard.metadata.predictionsFormat}
                        />
                        <SectionHeader>What happens next?</SectionHeader>
                        <section>
                            <SubmissionDescription
                                selectedLeaderboard={this.props.selectedLeaderboard}
                            />
                        </section>
                    </div>
                </TwoColumnLayout>
                <ReactTooltip multiline={true} />
            </div>
        );
    }
}

const FormLabel = styled.label`
    margin: 0 0 7px 0;
    font-weight: bold;
    color: ${(props) => props.theme.color.N9.toString()};
`;

const ToolTip = styled(HelpCircle)`
    margin-left: 8px;
`;

const Ul = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
`;

const Li = styled.li`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 25px 0 0 0;

    &:first-child {
        margin-top: 0;
    }
`;

const Input = styled.input.attrs<EmptyObject>(() => ({
    'data-lpignore': 'true',
}))`
    font-size: 0.9375em; /* 15px */
    font-family: ${(props) => props.theme.typography.font.family.sansSerif};
    color: ${(props) => props.theme.color.N9.toString()};
    outline: 0;
    border: 1px solid ${(props) => props.theme.color.A1.toString()};
    border-radius: 6px;
    padding: 5px;
    display: block;
    width: 100%;
    box-sizing: border-box;
`;

const TextArea = styled.textarea`
    display: block;
    font-size: 0.9375em; /* 15px */
    color: ${(props) => props.theme.color.N9.toString()};
    font-family: ${(props) => props.theme.typography.font.family.sansSerif};
    outline: 0;
    padding: 10px;
    resize: none;
    overflow: auto;
    border: 1px solid ${(props) => props.theme.color.A1.toString()};
`;

const NameInput = styled(Input)`
    display: block;
`;

const ButtonArea = styled.span`
    display: flex;

    @media ${below(Breakpoint.SMALL)} {
        flex-direction: column;
    }
`;

export const InputButton = styled.input`
    text-align: center;
    cursor: pointer;
    margin-right: 15px;
    width: 140px;
    text-decoration: none;
    font-size: 0.9375em; /* 15px */
    color: ${(props) => props.theme.color.B6.toString()};
    font-weight: bold;
    background: transparent;
    padding: 5px 10px;
    border: 2px solid ${(props) => props.theme.color.B6.toString()};
    border-radius: 5px;

    :hover {
        border-color: ${(props) => props.theme.color.A8.toString()};
    }

    :disabled {
        color: ${(props) => props.theme.color.B9.toString()};
        border-color: ${(props) => props.theme.color.B9.toString()};
        text-decoration: none;
        cursor: not-allowed;
        opacity: 0.6;
    }

    &:last-child {
        margin-right: 0;
    }

    @media ${below(Breakpoint.SMALL)} {
        margin: 15px 0 0;
        display: block;
        width: 100%;

        &:first-child {
            margin-top: 0;
        }
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    font-size: 0.9375em; /* 15px */
    margin: 30px 0 0;
    background: ${(props) => props.theme.color.N1.toString()};
`;

const InputComment = styled.div`
    margin-bottom: 1em;
`;

const Reminder = styled.div`
    margin-top: 15px;
`;

export default withRouter(CreateSubmission);
