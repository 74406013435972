import moment from 'moment';

import * as Api from '../api';
import { Leaderboard, LeaderboardList } from '../leaderboards';
import { Config } from '../types';
import { sortByString } from '../util';

export enum ConfigAction {
    REQUEST_CONFIG = 'CONFIG_REQUESTED',
    UPDATED = 'CONFIG_UPDATED',
    SELECT_LEADERBOARD = 'CONFIG_SELECT_LEADERBOARD',
}

export interface ConfigData {
    type: ConfigAction;
    config?: Config;
    leaderboardId?: string;
}

export function getConfig() {
    return (dispatch: (cd: ConfigData) => void) => {
        dispatch({
            type: ConfigAction.REQUEST_CONFIG,
        });

        Api.getLeaderboards().then((leaderboardResponse) => {
            const leaderboards: Leaderboard[] = leaderboardResponse.data.leaderboards;
            const leaderboardsById: LeaderboardList = {};
            let active: Leaderboard[] = [];

            for (const lb of leaderboards) {
                if (lb.id in leaderboardsById) {
                    throw new Error(
                        `Invalid leaderboard configuration, the id ${lb.id} appears twice.`
                    );
                }
                leaderboardsById[lb.id] = lb;

                // An active leaderboards is not hidden and has had recently published submissions
                if (!lb.metadata.hiddenFromMenu && moment().diff(lb.lastPublished, 'days') <= 60) {
                    active.push(lb);
                }
            }

            // A featured leaderboard is an active one, selected in a way to appear random
            // but remain stable for a day. But first, we sort our leaderboard by id!
            active = sortByString(active, (lb: Leaderboard) => lb.id);
            const featured = active[new Date().getDate() % active.length];

            const config: Config = {
                leaderboards: leaderboards,
                leaderboardsById: leaderboardsById,
                featuredLeaderboard: featured,
            };

            dispatch({
                type: ConfigAction.UPDATED,
                config,
            });
        });
    };
}

export function selectLeaderboard(leaderboardId: string) {
    return (dispatch: (cd: ConfigData) => void) => {
        dispatch({
            type: ConfigAction.SELECT_LEADERBOARD,
            leaderboardId,
        });
    };
}
