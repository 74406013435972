import { Leaderboard } from '../leaderboards';
import { Config } from '../types';

import { ConfigAction, ConfigData } from './config.action';

export interface ConfigState {
    config?: Config;
    selectedLeaderboard?: Leaderboard;
    isConfigRequesting: boolean;
}

const initialState: ConfigState = {
    config: undefined,
    selectedLeaderboard: undefined,
    isConfigRequesting: true,
};

export default function config(state: ConfigState = initialState, action: ConfigData) {
    switch (action.type) {
        case ConfigAction.REQUEST_CONFIG:
            return {
                ...state,
                isConfigRequesting: true,
            };
        case ConfigAction.UPDATED: {
            return {
                ...state,
                config: action.config,
                isConfigRequesting: false,
            };
        }
        case ConfigAction.SELECT_LEADERBOARD: {
            let selectedLeaderboard;
            if (action.leaderboardId && state.config) {
                selectedLeaderboard = state.config.leaderboardsById[action.leaderboardId];
                if (!selectedLeaderboard) {
                    throw new Error(`Invalid leaderboard id "${action.leaderboardId}"`);
                }
            }
            return {
                ...state,
                selectedLeaderboard,
            };
        }
        default: {
            return state;
        }
    }
}
