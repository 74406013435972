export function cookieExists(cookieName: string) {
    const re = new RegExp(cookieName + '=([^;]+)');
    const value = re.exec(document.cookie);
    return value != null;
}

export function logoutAndReload() {
    document.cookie = 'User-Token=; Path=/; Expires=Thu, 01-Jan-1970 00:00:01 GMT;';
    location.reload();
}
