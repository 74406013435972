import moment from 'moment';

import { User } from '../types';
import { camelizeKeys, deserializeMoments, transformResponse } from '../util';

import axios from './axios';

export interface LeaderboardEvent {
    id: string;

    date: moment.Moment;
    user: User;
    leaderboardId: string;

    extSubmissionId: string;
    publishStatus: string;

    description: string;
    before?: string;
    after?: string;
}

export function getEvents(limit: number): Promise<LeaderboardEvent[]> {
    let url = '/api/events';
    if (limit > 0) {
        url = url + `?limit=${limit}`;
    }
    return axios
        .get(url, {
            transformResponse: transformResponse(camelizeKeys, deserializeMoments),
        })
        .then((resp) => {
            const eventsKey = 'events';
            return resp.data[eventsKey];
        });
}
