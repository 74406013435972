import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Leaderboard } from '../../leaderboards';
import { selectLeaderboard, ReduxState } from '../../redux';
import { Config } from '../../types';
import { RouteAwareComponentProps } from '../../util';
import { LoadingContainer, LoadingMsg } from '../general/Shared';
import { SmallSpinner } from '../general/SmallSpinner';

interface MatchParams {
    dataset: string;
}

export interface Props {
    config: Config;
    selectedLeaderboard: Leaderboard;
    isConfigRequesting: boolean;

    selectLeaderboard: (leaderboardId: string) => void;
}

const WithSelectedLeaderboardRouteOuter = <P extends object>(Component: React.ComponentType<P>) =>
    class WithSelectedLeaderboardRouteInner extends React.PureComponent<
        P & RouteAwareComponentProps<Props, MatchParams>
    > {
        state = {
            isLoading: !(
                this.props.config &&
                !this.props.isConfigRequesting &&
                this.props.selectedLeaderboard &&
                this.props.match.params.dataset === this.props.selectedLeaderboard.id
            ),
        };

        componentDidMount() {
            this.selectLeaderboard(this.props.match.params.dataset);
        }

        componentDidUpdate(prevProps: P & RouteAwareComponentProps<Props, MatchParams>) {
            const dataset = this.props.match.params.dataset;
            // only refresh if config became not null OR isRequesting changed value OR we have a new dataset in params
            if (
                (this.props.config && !prevProps.config) ||
                this.props.isConfigRequesting !== prevProps.isConfigRequesting ||
                !this.props.selectedLeaderboard ||
                (this.props.selectedLeaderboard && dataset !== this.props.selectedLeaderboard.id)
            ) {
                this.selectLeaderboard(dataset);
            }
        }

        selectLeaderboard(leaderboardId: string) {
            // if we are loading config, just keep showing the loading indicator
            if (this.props.config && !this.props.isConfigRequesting) {
                // if we are loaded, then see if we are requesting a valid leaderboard
                if (!this.props.config.leaderboardsById[leaderboardId]) {
                    this.props.history.push('/not-found');
                } else {
                    // if it is valid, set it to selected if not already
                    if (
                        !this.props.selectedLeaderboard ||
                        leaderboardId !== this.props.selectedLeaderboard.id
                    ) {
                        this.props.selectLeaderboard(leaderboardId);
                    }
                    this.setState({ isLoading: false });
                }
            }
        }

        render() {
            return (
                <div>
                    {!this.state.isLoading ? (
                        <Component {...this.props} />
                    ) : (
                        <LoadingContainer>
                            <LoadingMsg>
                                <SmallSpinner />
                                <div>Loading, please wait...</div>
                            </LoadingMsg>
                        </LoadingContainer>
                    )}
                </div>
            );
        }
    };

const mapStateToProps = (state: ReduxState) => ({
    config: state.config.config,
    selectedLeaderboard: state.config.selectedLeaderboard,
    isConfigRequesting: state.config.isConfigRequesting,
});

const mapDispatchToProps = (dispatch: (a: ReturnType<typeof selectLeaderboard>) => void) => {
    return {
        selectLeaderboard: (leaderboardId?: string) =>
            dispatch(selectLeaderboard(leaderboardId || '')),
    };
};

export const WithSelectedLeaderboardRoute = compose(
    connect(mapStateToProps, mapDispatchToProps),
    WithSelectedLeaderboardRouteOuter
);
