import { RouteComponentProps } from 'react-router';

import qs from 'querystring';

import { EmptyObject } from './base';

export type RouteAwareComponentProps<T, S> = T & RouteComponentProps<S>;

export type EmptyRouteAwareComponentProps<T> = RouteAwareComponentProps<T, EmptyObject>;

/**
 * If the provided variable is defined in the current Location's query string
 * (provided by the param locationSearch, originating from window.location.search),
 * and is a valid integer, return it. Otherwise return the provided default.
 *
 * @param {string}      locationSearch
 * @param {string}      variableName
 * @param {number}      defaultValue
 *
 * @returns {number}
 */
export function getIntFromQueryStringOrDefault(
    locationSearch: string,
    variableName: string,
    defaultValue: number
): number {
    const parsedQueryString = qs.parse(locationSearch.substr(1));
    if (typeof parsedQueryString[variableName] === 'string') {
        // The cast here is necessary to get TypeScript to accept that we'll never
        // have a string[] here due to the guard above.
        const value = parsedQueryString[variableName] as string;
        const maybeInt = parseInt(value, 10);
        return isNaN(maybeInt) ? defaultValue : maybeInt;
    } else {
        return defaultValue;
    }
}

export function haveQueryParam(locationSearch: string, variableName: string): boolean {
    const parsedQueryString = qs.parse(locationSearch.substr(1));
    return variableName in parsedQueryString;
}

export function getStringFromQueryStringOrNull(
    locationSearch: string,
    variableName: string
): string | null {
    const parsedQueryString = qs.parse(locationSearch.substr(1));
    if (typeof parsedQueryString[variableName] === 'string') {
        // The cast here is necessary to get TypeScript to accept that we'll never
        // have a string[] here due to the guard above.
        return parsedQueryString[variableName] as string;
    } else {
        return null;
    }
}
