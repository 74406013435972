import { AdminSubmission, Submission } from '../types';
import { camelizeKeys, deserializeMoments, transformResponse } from '../util';

import axios from './axios';

export function adminGetAllSubmissions(leaderboardID?: string): Promise<AdminSubmission[]> {
    let url = `/api/v1/admin/all-submissions`;
    if (leaderboardID) {
        const params = new URLSearchParams({ leaderboard_id: leaderboardID });
        url += '?' + params.toString();
    }

    return axios
        .get<AdminSubmission[]>(url, {
            transformResponse: transformResponse(deserializeMoments),
        })
        .then((r) => r.data);
}

export function adminGetSubmissionDetails(submissionId: string): Promise<Submission> {
    return axios
        .get<Submission>(`/api/v1/admin/submission-details/${submissionId}`, {
            transformResponse: transformResponse(camelizeKeys, deserializeMoments),
        })
        .then((r) => r.data);
}
