import moment from 'moment';

export function formatDate(m: moment.Moment) {
    return m ? m.format('L') : '';
}

export function formatTime(m: moment.Moment) {
    return m ? m.format('LTS') : '';
}

export function formatDateTime(m: moment.Moment) {
    return m ? `${formatDate(m)} ${formatTime(m)}` : '';
}

export function formatAgo(m: moment.Moment) {
    return m ? m.fromNow() : '';
}

export function olderThanDays(m: moment.Moment, nDays: number): boolean {
    const nDaysAgo = moment().subtract(nDays, 'd');
    return nDaysAgo.isSameOrAfter(m);
}
