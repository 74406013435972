import { Layout, VarnishApp } from '@allenai/varnish/components';
import { ScrollToTopOnPageChange } from '@allenai/varnish-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import Notifications from 'react-notify-toast';
import { Provider } from 'react-redux';
import { Redirect, Route, BrowserRouter, Switch } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createGlobalStyle } from 'styled-components';
import '@allenai/varnish/dist/theme.css';

import AdminEventsContainer from './components/admin/events/AdminEventsContainer';
import AdminHomeContainer from './components/admin/home/AdminHomeContainer';
import AdminSubmissionDetailsContainer from './components/admin/submissiondetails/AdminSubmissionDetailsContainer';
import AdminAllSubmissionsContainer from './components/admin/submissions/AdminAllSubmissionsContainer';
import { WithConfig } from './components/general/WithConfig';
import WithStateFromServer from './components/general/WithStateFromServer';
import HomeContainer from './components/home/HomeContainer';
import NotFoundPage from './components/layout/NotFoundPage';
import PageFooter from './components/layout/PageFooter';
import TopBar from './components/layout/TopBar';
import { WithSelectedLeaderboardRoute } from './components/layout/WithSelectedLeaderboardRoute';
import CreateSubmissionContainer from './components/submission/CreateSubmissionContainer';
import SubmissionDetailsContainer from './components/submission/SubmissionDetailsContainer';
import SubmissionsContainer from './components/submission/SubmissionsContainer';
import { reducers } from './redux';
import { AdminNewLeaderboard } from './components/admin/leaderboard/AdminNewLeaderboard';
import { AdminEditLeaderboard } from './components/admin/leaderboard/AdminEditLeaderboard';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(thunk)));

const GlobalStyle = createGlobalStyle`
  /* These overrides ensure the alerts display appropriately regardless of the
     client's resolution. */
  .react-confirm-alert {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-confirm-alert-body {
    max-width: 400px;
    width: 100%;
    box-sizing: border-box;
    margin: 15px;
  }
  // TODO: react-confirm should be replaced with antd
`;

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <ScrollToTopOnPageChange />
            <Helmet
                defaultTitle="Leaderboards by Allen AI"
                titleTemplate="%s - Leaderboards by Allen AI"
            />
            <VarnishApp layout="left-aligned">
                <WithStateFromServer>
                    <Layout bgcolor="white">
                        <GlobalStyle />
                        <TopBar />
                        <Switch>
                            <Route exact path="/" component={HomeContainer} />
                            <Route exact path="/home" component={HomeContainer} />
                            <Route exact path="/admin/events" component={AdminEventsContainer} />
                            <Route
                                exact
                                path="/admin/submissions"
                                component={WithConfig(AdminAllSubmissionsContainer)}
                            />
                            <Route
                                exact
                                path="/admin/submission/:id"
                                component={AdminSubmissionDetailsContainer}
                            />
                            <Route
                                exact
                                path="/admin/leaderboard/new"
                                component={AdminNewLeaderboard}
                            />
                            <Route
                                exact
                                path="/admin/leaderboard/edit/:id"
                                component={AdminEditLeaderboard}
                            />
                            <Route exact path="/admin" component={WithConfig(AdminHomeContainer)} />
                            <Route exact path="/not-found" component={NotFoundPage} />
                            <Route
                                path="/:dataset/submissions/"
                                component={
                                    WithSelectedLeaderboardRoute(SubmissionsContainer) as any
                                }
                            />
                            <Route
                                path="/:dataset/submission/create"
                                component={
                                    WithSelectedLeaderboardRoute(CreateSubmissionContainer) as any
                                }
                            />
                            <Route
                                path="/:dataset/submission/:id"
                                component={
                                    WithSelectedLeaderboardRoute(SubmissionDetailsContainer) as any
                                }
                            />
                            <Redirect from="/:dataset" to="/:dataset/submissions/" />
                            <Route component={NotFoundPage} />
                        </Switch>
                        <Notifications />
                        <PageFooter />
                    </Layout>
                </WithStateFromServer>
            </VarnishApp>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
