import { Location } from 'history';
import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { EmptyRouteAwareComponentProps, getIntFromQueryStringOrDefault } from '../../util';

// The maximnum number of pagination links to show in a single pagination control
const MaxPaginationPages = 5;

// The "midpoint", (1/2 of the max # of  pagination links we want to show).
// Important because it effects how we calculate what page numbers to
// display.
// @see getPageNumbersToDisplay
const PaginationMidpoint = Math.floor(MaxPaginationPages / 2);

// The size, in pixels, of the left / right chevrons for paginating forwards
// and backwards
const ChevronSize = 18;

/**
 * Returns the page numbers to be displayed, returning up to 5 page numbers.
 *
 * @param {number} numPages
 * @param {number} currentPageNumber
 *
 * @returns {number[]}
 */
function getPageNumbersToDisplay(numPages: number, currentPageNumber: number): number[] {
    if (numPages > MaxPaginationPages && currentPageNumber > PaginationMidpoint) {
        const remainingPages = numPages - currentPageNumber;
        const diff =
            remainingPages > PaginationMidpoint
                ? currentPageNumber - PaginationMidpoint
                : currentPageNumber - (MaxPaginationPages - remainingPages);
        return [...Array(MaxPaginationPages).keys()].map((n) => n + diff);
    } else {
        return [...Array(Math.min(MaxPaginationPages, numPages)).keys()];
    }
}

interface PaginatedListProps<T> {
    // The number of items to display paer page
    pageSize: number;
    // The items to be paginated
    items: T[];
    // See the note below on render props.
    children: (items: T[], changePageLinks: React.ReactNode) => React.ReactNode;
    // This is provided via the `withRouter` HOC and need not be provided.
    location: Location;
    // This id is used as a prefix for the query string variable that's appended
    // to the url. It's only necessary when there are > 1 paginated lists on
    // a given page.
    id?: string;
    // The default offset (defaults to 0)
    defaultOffset?: number;
}

/**
 * Provides for a a PaginatedList of items. This only handles the case where
 * the entire collection is stored clientside, but could be adjusted in the
 * future to handle pagination that requires querying an updstream API.
 *
 * The component uses the "render props" pattern, which can be used as shown
 * below. The motivation for this pattern is to allow the actual layout to
 * be flexible (the paingation controls can be positioned as desired).
 */
class PaginatedList<T> extends React.PureComponent<
    EmptyRouteAwareComponentProps<PaginatedListProps<T>>
> {
    render() {
        const defaultOffset = this.props.defaultOffset !== undefined ? this.props.defaultOffset : 0;
        // If the id value is set, it's used as a prefix for the offset parameter
        // that's used to paginate the list (via the browser's url). This is only
        // necessary if there's multiple paginated lists on a single page, otherwise
        // it can be omitted.
        const offsetQueryVar = `${
            this.props.id ? encodeURIComponent(this.props.id) + '_' : ''
        }offset`;
        const offset = getIntFromQueryStringOrDefault(
            this.props.location.search,
            offsetQueryVar,
            defaultOffset
        );
        // Calculate where the next page of results would start, in a fashion that
        // handles manually entered offsets without breaking (i.e. if the user
        // inputs offset=7 and the pageSize is 5).
        const currentPageNum = Math.floor(offset / this.props.pageSize);
        const nextPageOffset = currentPageNum * this.props.pageSize + this.props.pageSize;
        const prevPageOffset = Math.max(offset - this.props.pageSize * 1, 0);
        const numPages = Math.ceil(this.props.items.length / this.props.pageSize);
        const changePageButtons =
            numPages > 1 ? (
                <PaginationContainer>
                    <Pagination>
                        {offset > 0 ? (
                            <PaginationLink to={`?${offsetQueryVar}=${prevPageOffset}`}>
                                <ChevronLeft size={ChevronSize} />
                            </PaginationLink>
                        ) : (
                            <SelectedPage>
                                <ChevronLeft size={ChevronSize} />
                            </SelectedPage>
                        )}
                        {getPageNumbersToDisplay(numPages, currentPageNum).map((pageNum) => {
                            const pageOffset = pageNum * this.props.pageSize;
                            if (pageOffset === offset) {
                                return <SelectedPage key={pageNum}>{pageNum + 1}</SelectedPage>;
                            } else {
                                return (
                                    <PaginationLink
                                        key={pageNum}
                                        to={`?${offsetQueryVar}=${pageOffset}`}>
                                        {pageNum + 1}
                                    </PaginationLink>
                                );
                            }
                        })}
                        {this.props.items.length - offset > this.props.pageSize ? (
                            <PaginationLink to={`?${offsetQueryVar}=${nextPageOffset}`}>
                                <ChevronRight size={ChevronSize} />
                            </PaginationLink>
                        ) : (
                            <SelectedPage>
                                <ChevronRight size={ChevronSize} />
                            </SelectedPage>
                        )}
                    </Pagination>
                </PaginationContainer>
            ) : null;
        return this.props.children(
            this.props.items.slice(offset, offset + this.props.pageSize),
            changePageButtons
        );
    }
}

const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const Pagination = styled.span`
    display: inline-flex;
    align-items: center;
    padding: 5px;
    border-radius: 25px;
    box-shadow: 1px 1px 2px ${(props) => props.theme.shadow};
    font-size: 1.125em; /* 18px */
`;

// Base styles for the all pagination items
const PaginationItem = `
  display: inline-flex;
  align-items: center;
  padding: 5px 8px;
  margin: 0 2.5px;
  font-weight: bold;
  line-height: 1;
  &:first-child {
    margin-right: 0;
  }
  &:last-child {
    margin-left: 0;
  }
`;

const PaginationLink = styled(Link)`
    ${PaginationItem}
    color: ${(props) => props.theme.color.B6.toString()};
    text-decoration: none;
    &:hover,
    &:focus {
        color: ${(props) => props.theme.color.A8.toString()};
    }
`;

const SelectedPage = styled.span`
    ${PaginationItem}
    color: ${(props) => props.theme.color.N6.toString()};
`;

export default withRouter(PaginatedList);
