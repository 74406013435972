import { AutoComplete, Dropdown, Input, Menu, Table, Tooltip, Alert } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { DeleteOutlined, FilterOutlined } from '@ant-design/icons';
import humps from 'humps';
import moment from 'moment';
import React from 'react';
import Measure from 'react-measure';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import debounce from 'debounce';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';

import { below, Breakpoint } from '../../Breakpoint';
import { PublishWindowDays } from '../../constants';
import { Leaderboard } from '../../leaderboards';
import {
    Config,
    Metric,
    MetricsTableColumn,
    Submission,
    SubmissionPatchSpec,
    SubmissionStatus,
} from '../../types';
import {
    Dictionary,
    EmptyRouteAwareComponentProps,
    formatDate,
    getScoreCompareValue,
    handleApiError,
    PublishQuotaExceededApiError,
    unpackApiError,
} from '../../util';
import { alert, baseLinkStyles, confirm, Rank } from '../general/Shared';
import TruncatedStringWithTitle from '../general/TruncatedStringWithTitle';
import { H2 } from '../typography';

import { LadderButtons } from './LadderButtons';
import { MetricsRendererError } from './metricstyles/error';
import { MetricsRendererRange } from './metricstyles/range';
import { MetricsRendererSimple } from './metricstyles/simple';
import SubmissionStatusWithIcon from './SubmissionStatusWithIcon';

interface Props {
    className?: string;
    submissions?: Submission[];
    focusedSubmission?: Submission;
    config: Config;
    selectedLeaderboard: Leaderboard;
    isPrivate: boolean;

    onFocusOnSubmission: (submission?: Submission) => void;
    onPublishSubmission?: (submission?: Submission) => Promise<any>;
    onUpdate?: (id: string, patchData: SubmissionPatchSpec) => Promise<any>;
}

interface State {
    dataSource: Submission[];
    columnData: ColumnData;
    metricMaxNumbers: Dictionary<number>;
    shouldFixColumns: boolean;
    totalColumnRems: number;
    columnFilter?: string;
    shouldHighlightFilter: boolean;
    uniqueFilterOptions: { value: string }[];
}

interface SubmissionColumnProps extends ColumnProps<Submission> {
    // how much space should generally be reserved for this column
    // we cannot store in width, as it will change
    preferredRemWidth: number;
    // should this column be allowed to be frozen to the left or right?
    // needed since we turn on and off 'fixing' per column based on screen width
    fixable?: boolean;
}

type ColumnData = {
    columns: SubmissionColumnProps[];
    isTruncated: boolean;
};

// we clip the number of columns to preserve performance
const MaxMetricsToDisplay = 100;

class LadderInternal extends React.Component<EmptyRouteAwareComponentProps<Props>, State> {
    pixelsPerRem = 16;

    constructor(props: EmptyRouteAwareComponentProps<Props>) {
        super(props);

        const dataSource = this.props.submissions || [];

        // calc statistics for filter groups
        const metricMaxNumbers: Dictionary<number> = {};
        dataSource.forEach((sub) => {
            Object.keys(sub.metricScores).forEach((key: string) => {
                if (!metricMaxNumbers[key]) {
                    metricMaxNumbers[key] = Number.NEGATIVE_INFINITY;
                }
                const blindScore = sub.metricScores[key].blindScore;
                if (blindScore !== undefined) {
                    metricMaxNumbers[key] = Math.max(blindScore, metricMaxNumbers[key]);
                }
            });
        });

        const columnData = this.getColumns(false);
        this.state = {
            dataSource,
            columnData,
            metricMaxNumbers,
            shouldFixColumns: false,
            totalColumnRems: columnData.columns.reduce((total, v) => {
                return total + v.preferredRemWidth;
            }, 0),
            columnFilter: this.props.selectedLeaderboard.metadata.defaultColumnFilter,
            // by default, highlight the filter if we set a default to help folks be able to recognize that a default is set
            shouldHighlightFilter: !!this.props.selectedLeaderboard.metadata.defaultColumnFilter,
            // if they're not unique, it will conflict with React's lifecycle checks due to key duplication
            uniqueFilterOptions: this.formatFilters(
                this.dedupeFilters(this.props.selectedLeaderboard.metadata.metricFilterSuggestions)
            ),
        };
    }

    dedupeFilters = (filters?: string[]) => {
        return filters ? [...new Set(filters)] : [];
    };

    formatFilters = (filters: string[]) => {
        return filters.map((t) => {
            return { value: t };
        });
    };

    // show cols as fixed (frozen) left/right if we have lots of columns and enough space that frozen cols make sense
    // we dont show frozen columns when either:
    //  1- the table is very narrow (not enough space for scrolling),
    //  2- the total columns space is not wide (no scroll bar)
    updateFixedColumns = (widthOfViewport: number) => {
        let shouldFixColumns = false;
        const gridWidth = widthOfViewport / this.pixelsPerRem;
        // calc size of cols we can fix
        const fixableColWidth = this.state.columnData.columns.reduce(
            (total: number, col: SubmissionColumnProps) => {
                return total + (undefined !== col.fixable ? col.preferredRemWidth : 0);
            },
            0
        );
        // calc the total size of col space
        const totalColumnRems = this.state.columnData.columns.reduce(
            (total: number, col: SubmissionColumnProps) => {
                return total + col.preferredRemWidth;
            },
            0
        );
        // if a good size of the space can be scrolling, then it makes sense to show cols as fixed
        if (gridWidth - fixableColWidth > 180 / this.pixelsPerRem) {
            // if the total col space is greater than the size of the table, then it still makes sense to show cols as fixed
            if (totalColumnRems > gridWidth) {
                shouldFixColumns = true;
            }
        }

        this.setState({
            columnData: this.getColumns(shouldFixColumns, this.state.columnFilter),
            shouldFixColumns,
            totalColumnRems,
        });
    };

    matchesFilter = (metricName: string, filterExpr?: string): boolean => {
        if (filterExpr === undefined) {
            return true;
        }
        if (filterExpr.trim() === '') {
            return true;
        }
        try {
            const r = new RegExp(filterExpr, 'i');
            return r.test(metricName);
        } catch (e) {
            return metricName.toLowerCase().indexOf(filterExpr) !== -1;
        }
    };

    getColumns = (fixCols: boolean, columnFilter?: string): ColumnData => {
        const ret: ColumnData = {
            columns: [],
            isTruncated: false,
        };

        // Rank
        if (!this.props.isPrivate) {
            ret.columns.push({
                title: 'Rank',
                width: 69,
                preferredRemWidth: 4.25,
                fixable: true,
                fixed: fixCols ? 'left' : undefined,
                dataIndex: 'rank',
                key: 'rank',
                // we set a default sort on the rank if we are public, and on created if we are private
                defaultSortOrder: 'ascend',
                sorter: (a: Submission, b: Submission) =>
                    (a.rank || Infinity) - (b.rank || Infinity),
                render: (value: number) => <Rank isBest={value === 1}>{value}</Rank>,
            });
        }

        // Name, Contributors, and Status
        ret.columns.push({
            title: 'Submission',
            preferredRemWidth: 12,
            fixable: true,
            fixed: fixCols ? 'left' : undefined,
            key: 'name/contributors/status',
            render: (value: Submission, record: Submission) => (
                <div>
                    <Title to={`/${this.props.selectedLeaderboard.id}/submission/${record.id}`}>
                        <TruncatedStringWithTitle text={record.name} maxCharacters={30} />
                    </Title>
                    {record.contributors ? (
                        <Contributors>
                            <TruncatedStringWithTitle
                                text={record.contributors}
                                maxCharacters={30}
                            />
                        </Contributors>
                    ) : null}
                    {this.props.isPrivate &&
                    record.blindStatus &&
                    record.blindStatus !== SubmissionStatus.Succeeded ? (
                        <Status status={record.blindStatus} />
                    ) : null}
                </div>
            ),
        });

        // Created
        ret.columns.push({
            title: 'Created',
            preferredRemWidth: 5.75,
            dataIndex: 'created',
            key: 'created',
            align: 'center',
            // we set a default sort on the rank if we are public, and on created if we are private, undefined means none
            defaultSortOrder: this.props.isPrivate ? 'descend' : undefined,
            sorter: (a: Submission, b: Submission) => (a.created.isBefore(b.created) ? -1 : 1),
            render: (value: moment.Moment) => <DontWrap>{formatDate(value)}</DontWrap>,
        });

        // Store metric definitions by the key name, for convenient lookup later.
        const metricByKey: { [key: string]: Metric } = {};
        for (const m of this.props.selectedLeaderboard.metadata.metrics) {
            metricByKey[m.key] = m;
        }

        // Metric Scores
        //
        // The "metricsTable" field defines each column to be displayed, possibly as a
        // composite of several metrics, by declaring a renderer.
        let returnedMetricCount = 0;
        for (const c of this.props.selectedLeaderboard.metadata.metricsTable.columns) {
            // Don't render this column if its name doesn't match the column filter regex.
            if (!this.matchesFilter(c.name, columnFilter)) {
                continue;
            }
            // Don't render too many columns
            if (returnedMetricCount++ >= MaxMetricsToDisplay) {
                ret.isTruncated = true;
                break;
            }
            const metricKey0 = humps.camelize(c.metricKeys[0]);
            ret.columns.push({
                title: () => <ColumnTitle>{columnTitleWithTooltip(c, metricByKey)}</ColumnTitle>,
                preferredRemWidth: 6.25,
                key: `metricScore-for-${c.metricKeys.join('-')}`,
                align: 'center',
                sorter: (a: Submission, b: Submission) => {
                    return (
                        -1 *
                        (getScoreCompareValue(
                            a,
                            a.metricScores[metricKey0],
                            this.props.selectedLeaderboard
                        ) -
                            getScoreCompareValue(
                                b,
                                b.metricScores[metricKey0],
                                this.props.selectedLeaderboard
                            ))
                    );
                },
                render: (value: Submission, record: Submission) => {
                    if (c.renderer === 'simple') {
                        return (
                            <MetricsRendererSimple
                                submission={record}
                                score={value.metricScores[metricKey0]}
                                selectedLeaderboard={this.props.selectedLeaderboard}
                                metricMaxNumbers={this.state.metricMaxNumbers}
                            />
                        );
                    } else if (c.renderer === 'error' && c.metricKeys.length === 3) {
                        const metricKey1 = humps.camelize(c.metricKeys[1]);
                        const metricKey2 = humps.camelize(c.metricKeys[2]);
                        return (
                            <MetricsRendererError
                                submission={record}
                                score={value.metricScores[metricKey0]}
                                upper={value.metricScores[metricKey1]}
                                lower={value.metricScores[metricKey2]}
                                selectedLeaderboard={this.props.selectedLeaderboard}
                                metricMaxNumbers={this.state.metricMaxNumbers}
                            />
                        );
                    } else if (c.renderer === 'range' && c.metricKeys.length === 3) {
                        const metricKey1 = humps.camelize(c.metricKeys[1]);
                        const metricKey2 = humps.camelize(c.metricKeys[2]);
                        return (
                            <MetricsRendererRange
                                submission={record}
                                score={value.metricScores[metricKey0]}
                                upper={value.metricScores[metricKey1]}
                                lower={value.metricScores[metricKey2]}
                                selectedLeaderboard={this.props.selectedLeaderboard}
                                metricMaxNumbers={this.state.metricMaxNumbers}
                            />
                        );
                    }
                    const title =
                        'This metric cannot be shown because the leaderboard is ' +
                        'misconfigured. Please ask the administrator of this leaderboard to ' +
                        'make a correction.';
                    return <span title={title}>?</span>;
                },
            });
        }

        // We only show these columns privately to user
        if (this.props.isPrivate) {
            // Test Status
            ret.columns.push({
                title: 'Test Status',
                preferredRemWidth: 13.25,
                dataIndex: 'blindStatus',
                key: 'blindStatus',
                render: (value: SubmissionStatus) => (
                    <span>{value ? <Status status={value} /> : null}</span>
                ),
            });

            // Actions
            ret.columns.push({
                title: 'Actions',
                key: 'actions',
                preferredRemWidth: 7,
                fixable: true,
                fixed: fixCols ? 'right' : undefined,
                render: (value: Submission, record: Submission) => {
                    const disallowPublishReason = this.disablePublishReason(record);
                    const menu = (
                        <Menu>
                            <Menu.Item key="delete" onClick={() => this.onRemoveClick(record)}>
                                <DeleteOutlined /> Remove
                            </Menu.Item>
                        </Menu>
                    );
                    return (
                        <Tooltip title={disallowPublishReason}>
                            <ActionButton
                                disable={!!disallowPublishReason}
                                onClick={() =>
                                    !disallowPublishReason && this.onPublishClick(record)
                                }
                                overlay={menu}>
                                Publish
                            </ActionButton>
                        </Tooltip>
                    );
                },
            });
        }

        return ret;
    };

    filterByDisplayOrder = <T extends { displayOrder: number }>(a: T, b: T) =>
        a.displayOrder - b.displayOrder;

    onPublishClick = (submission: Submission): void => {
        if (this.props.onPublishSubmission) {
            confirm(
                'Publish submission?',
                'Publish submission?',
                () => {
                    if (this.props.onPublishSubmission) {
                        this.props.onPublishSubmission(submission).catch((err) => {
                            const apiError = unpackApiError(err);
                            if (apiError instanceof PublishQuotaExceededApiError) {
                                const nextPublishDate = moment()
                                    .add(apiError.fields.secondsLeft, 'seconds')
                                    .format('h:mm a [on] MMMM Do, YYYY');
                                const errorMessage = (
                                    <div>
                                        <p>
                                            To protect against overfitting on the blind test dataset
                                            you can only publish results once every{' '}
                                            {PublishWindowDays} days.
                                        </p>
                                        <strong>
                                            You can publish to this leaderboard again anytime after{' '}
                                            {nextPublishDate}.
                                        </strong>
                                    </div>
                                );
                                alert('Publish Failed', undefined, undefined, errorMessage);
                            } else {
                                handleApiError(
                                    err,
                                    'Publish Failed',
                                    'You cannot publish submission at this time',
                                    'Error publishing'
                                );
                            }
                        });
                    }
                },
                <div>
                    {!this.props.selectedLeaderboard.disablePublishSpeedBump ? (
                        <p>
                            To protect against overfitting on the blind test dataset you can only
                            publish once every {PublishWindowDays} days.
                        </p>
                    ) : null}
                    <p>
                        Your submission will be immediately published and this action cannot be
                        undone.
                    </p>
                    <strong>Are you sure you're ready to publish this submission?</strong>
                </div>
            );
        }
    };

    disablePublishReason = (submission: Submission): string => {
        const hasUnsuccessfulTestSubmission =
            submission.blindStatus && submission.blindStatus !== SubmissionStatus.Succeeded;
        if (hasUnsuccessfulTestSubmission) {
            return 'Submission evaluation pending.';
        }
        for (const ms in submission.metricScores) {
            if (Object.prototype.hasOwnProperty.call(submission.metricScores, ms)) {
                const metricScore = submission.metricScores[ms];
                if (metricScore.awaitingSupplemental) {
                    return 'This submissions is awaiting a supplemental metric before it can be published.';
                }
            }
        }
        return '';
    };

    onRemoveClick = (submission: Submission): void => {
        confirm(
            'Remove submission?',
            `This will remove the submission from the leaderboard.`,
            () => {
                if (this.props.onUpdate) {
                    this.props.onUpdate(submission.id, { isArchived: true }).catch((err) => {
                        handleApiError(
                            err,
                            'Remove Failed',
                            'You cannot remove submission at this time',
                            'Error removing'
                        );
                    });
                }
            }
        );
    };

    applyMetricFilter(widthOfViewport: number) {
        // first hide columns based on filter
        this.getColumns(this.state.shouldFixColumns, this.state.columnFilter);
        // then resize table
        this.updateFixedColumns(widthOfViewport);
    }

    applyMetricFilterDebounced = debounce(this.applyMetricFilter, 500, false);

    onMetricFilter = (value: string, widthOfViewport: number): void => {
        // turn off the filter highlight (if it was set) now that the user has purposefully selected their own filter
        this.setState({ shouldHighlightFilter: false });
        this.setState({ columnFilter: value }, () => {
            this.applyMetricFilterDebounced(widthOfViewport);
        });
    };

    render() {
        return (
            <>
                <section className={this.props.className}>
                    <Measure
                        bounds
                        onResize={(contentRect) => {
                            this.updateFixedColumns(contentRect.bounds?.width || 0);
                        }}>
                        {({ measureRef, contentRect }) => (
                            <div ref={measureRef}>
                                <Table<Submission>
                                    showSorterTooltip={false}
                                    title={() => (
                                        <ButtonBar>
                                            {this.state.columnData.isTruncated ? (
                                                <SmallAlert
                                                    message="Too many metrics to display. Please use filter."
                                                    type="warning"
                                                />
                                            ) : null}
                                            {this.props.selectedLeaderboard.metadata
                                                .showColumnFilter ? (
                                                <ColumnFilter
                                                    shouldHighlightFilter={
                                                        this.state.shouldHighlightFilter
                                                    }
                                                    options={this.state.uniqueFilterOptions}
                                                    value={this.state.columnFilter}
                                                    onSelect={(v: string) =>
                                                        this.onMetricFilter(
                                                            v,
                                                            contentRect.bounds?.width || 0
                                                        )
                                                    }>
                                                    <Input
                                                        placeholder="show metrics that match"
                                                        title="Enter a regex to show matching metric columns"
                                                        prefix={<FilterOutlined />}
                                                        size="small"
                                                        allowClear
                                                        onChange={(e) =>
                                                            this.onMetricFilter(
                                                                e.target.value,
                                                                contentRect.bounds?.width || 0
                                                            )
                                                        }
                                                    />
                                                </ColumnFilter>
                                            ) : null}
                                            <Buttons
                                                submissions={this.props.submissions}
                                                selectedLeaderboard={this.props.selectedLeaderboard}
                                                published={!this.props.isPrivate}
                                            />
                                        </ButtonBar>
                                    )}
                                    onRow={(record: Submission) => {
                                        return {
                                            onMouseEnter: () => {
                                                this.props.onFocusOnSubmission(record);
                                            },
                                            onMouseLeave: () => {
                                                // calling focus with no submission is effectively a blur
                                                this.props.onFocusOnSubmission();
                                            },
                                        };
                                    }}
                                    size="small"
                                    dataSource={this.state.dataSource}
                                    columns={this.state.columnData.columns}
                                    scroll={{ x: `${this.state.totalColumnRems}rem` }}
                                    rowKey={(d: any) => d.id}
                                    pagination={false}
                                />
                            </div>
                        )}
                    </Measure>
                </section>
            </>
        );
    }
}

function columnTitleWithTooltip(
    c: MetricsTableColumn,
    metricByKey: { [key: string]: Metric }
): JSX.Element {
    const titleParts = [];
    if (c.description) {
        titleParts.push(c.description);
    }

    const metric = metricByKey[c.metricKeys[0]];
    if (metric.isSupplemental) {
        titleParts.push('This supplemental metric is provided by the admin of this Leaderboard.');
    }

    const title = titleParts.join(' ');
    const titleThatRespectsNewLines = title.replace(/\\n/g, '\n');
    return (
        <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={titleThatRespectsNewLines}>
            <span>{c.name}</span>
        </Tooltip>
    );
}

// antd applies `overflow-wrap: something`, we reverse this to the browser default, which is `something-else`.
const DontWrap = styled.span`
    overflow-wrap: initial;
`;

const ColumnTitle = styled.span`
    overflow-wrap: anywhere;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ColumnFilter = styled(({ shouldHighlightFilter, ...rest }) => <AutoComplete {...rest} />)<{
    shouldHighlightFilter?: boolean;
}>`
    grid-area: columnFilter;
    && {
        border: ${({ shouldHighlightFilter, theme }) =>
            shouldHighlightFilter ? `2px solid ${theme.palette.secondary.default}` : 'inherit'};
        border-radius: ${({ shouldHighlightFilter }) =>
            shouldHighlightFilter ? `5px` : 'inherit'};
        width: 250px;
    }
`;

const ButtonBar = styled.div`
    display: grid;
    grid-template: '. alert columnFilter buttons' / 1fr auto min-content min-content;
    justify-items: end;
    gap: ${(props) => props.theme.spacing.xxs};

    @media ${below(Breakpoint.MEDIUM)} {
        justify-items: unset;
        grid-template:
            'columnFilter buttons'
            'alert alert' / min-content min-content;
    }
`;

const SmallAlert = styled(Alert)`
    grid-area: alert;
    && {
        padding: 0 15px;

        @media ${below(Breakpoint.MEDIUM)} {
            padding: 4px 15px;
        }
    }
`;

const Buttons = styled(LadderButtons)`
    grid-area: buttons;
`;

const Title = styled(NavLink)`
    ${baseLinkStyles}
    font-weight: bold;
    text-decoration: none;

    :hover {
        text-decoration: none;
    }
`;

const Contributors = styled.div`
    font-style: italic;
`;

const Status = styled(SubmissionStatusWithIcon)`
    color: ${(props) => props.theme.color.N6.toString()};
`;

const ActionButton = styled(Dropdown.Button)<{ disable: boolean }>`
    && {
        button:first-of-type {
            padding: 0 0.5rem;
            opacity: ${({ disable }) => (disable ? '0.5' : 1)};
            pointer-events: ${({ disable }) => (disable ? 'none' : 'auto')};
        }
        button:first-of-type:hover {
            color: ${({ disable }) => (disable ? 'rgb(76, 82, 88)' : null)};
            border-color: ${({ disable }) => (disable ? 'rgb(217, 217, 217)' : null)};
        }
    }
`;

export const LadderHeader = styled(H2)`
    margin-top: 30px;
    border: none;
    padding-bottom: 0;
`;

export const Ladder = styled(withRouter(LadderInternal))`
    margin: 10px 0 40px 0;
`;
