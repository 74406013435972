import { Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { below, Breakpoint } from '../../Breakpoint';
import { Leaderboard } from '../../leaderboards';
import { formatDate, formatScore, pluralize } from '../../util';
import { NavLink } from '../general/Shared';
import { H3 } from '../typography';

interface Props {
    leaderboard: Leaderboard;
    className?: string;
}

const DatasetCard: React.FunctionComponent<Props> = ({ leaderboard, ...props }) => {
    return (
        <div className={props.className}>
            <Content>
                <Icon>
                    <NavLink to={`/${leaderboard.id}/submissions/public`}>
                        <img
                            src={leaderboard.metadata.logo}
                            width="180"
                            height="180"
                            alt={`${leaderboard.metadata.longName} Logo`}
                        />
                    </NavLink>
                </Icon>
                <Name>
                    <NameLink to={`/${leaderboard.id}/submissions/public`}>
                        {leaderboard.metadata.longName}
                    </NameLink>
                </Name>
                <Description>
                    <Typography.Paragraph ellipsis={{ rows: 5, expandable: true, symbol: 'more' }}>
                        {leaderboard.metadata.description}
                    </Typography.Paragraph>
                </Description>
                {leaderboard.submissionCount &&
                leaderboard.topScore &&
                leaderboard.lastPublished ? (
                    <Metadata>
                        {pluralize('Submission', leaderboard.submissionCount || 0)}
                        <div>
                            Top score:{' '}
                            <strong>
                                {formatScore(
                                    leaderboard.topScore,
                                    leaderboard.metadata.metricPrecision
                                )}
                            </strong>
                        </div>
                        <Updated>Updated: {formatDate(leaderboard.lastPublished)}</Updated>
                    </Metadata>
                ) : null}
            </Content>
        </div>
    );
};

const Content = styled.div`
    display: grid;
    gap: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.lg}`};
    grid-template-areas:
        'icon name blank'
        'icon description metadata';

    @media ${below(Breakpoint.LARGE)} {
        grid-template-areas:
            'icon name name'
            'icon description metadata';
    }

    @media ${below(Breakpoint.MEDIUM)} {
        gap: ${({ theme }) => `${theme.spacing.md}`};
        grid-template-areas:
            'name'
            'description'
            'metadata';
    }
`;

const Icon = styled.div`
    grid-area: icon;

    @media ${below(Breakpoint.MEDIUM)} {
        display: none;
    }
`;

const Name = styled(H3)`
    grid-area: name;
    font-size: 1.5em;
    margin: 0;
    line-height: 1;

    @media ${below(Breakpoint.MEDIUM)} {
        font-size: 1.25em;
    }
`;

const Description = styled.div`
    grid-area: description;
    color: ${(props) => props.theme.color.N9.toString()};
`;

const Metadata = styled.div`
    grid-area: metadata;
    display: flex;
    flex-direction: column;
    flex: 200px 0 0;
    text-align: right;

    @media ${below(Breakpoint.MEDIUM)} {
        flex-direction: row;
        width: auto;
        text-align: left;
        flex-basis: 0;
        gap: ${({ theme }) => theme.spacing.sm};
    }
`;

const NameLink = styled(NavLink)`
    text-decoration: none;
    margin-right: ${({ theme }) => theme.spacing.xs};

    :hover {
        text-decoration: none;
    }
`;

const Updated = styled.div`
    color: ${(props) => props.theme.color.N6.toString()};
`;

export default styled(DatasetCard)`
    margin-bottom: 30px;

    @media ${below(Breakpoint.MEDIUM)} {
        margin: 45px 0;
    }
`;
