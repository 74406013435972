import moment from 'moment';

import { Metric, MetricsTable } from './types';

export type LeaderboardList = { [id: string]: Leaderboard };

export interface LeaderboardTeamInfo {
    name: string;
    description: string;
}

export interface LeaderboardMetadata {
    // Test leaderboards are only available via direct url address
    hiddenFromMenu?: boolean;

    // your logo
    logo: string;

    // short name for display
    shortName: string;

    // long name if we have space
    longName: string;

    // small text description of your dataset
    description: string;

    // small example question from your dataset
    example: string;

    // optional scores for comparison
    scores?: Scores;

    // how do users get your dataset
    gettingTheData: string;

    // how do you score the predictions
    scoring: string;

    // what should a users submission look like
    predictionsFormat: string;

    // some example models (github repos prefered)
    exampleModels: string;

    // Informationa bout the team at AI2 responsible for the leaderboard
    team: LeaderboardTeamInfo;

    // how is this leaderboard important to the team
    purpose: string;

    // Optional leaderboard specific rules
    customRules?: string;

    // Optional leaderboard specific rule: used to temporarily disable submissions
    lockSubmissions?: boolean;

    // Optional template to show when initially editing the readme
    readmeTemplate?: string;

    // List of metrics of this leaderboard
    metrics: Metric[];

    // How many decimal places should we show on metrics
    metricPrecision?: number;

    // A definition of the columns in the table of metrics to show. This
    // is used by the ladder components to render columns.
    metricsTable: MetricsTable;

    // Should we display the column filter on the ladder?
    showColumnFilter?: boolean;

    // List of auto complete terms for Metric filtering
    metricFilterSuggestions?: string[];

    //  A filter that is set by default when the user views the table
    defaultColumnFilter?: string;

    // Chart to compare metrics
    metricChartConfigs?: MetricChartConfig[];

    // Let the user see a compact table of the public results
    showCompactTableButton?: boolean;
}

export interface MetricChartConfig {
    caption: string;
    categories: ChartCategory[];
    maxSeriesToShow?: number;
    seriesOrderMetric: string;
    chart: Chart;
}

export interface Chart {
    type: string;
    mode?: string;
    layout?: ChartLayout;
}

export interface ChartLayout {
    showlegend?: boolean;
    yaxis?: NumericAxis;
    xaxis?: Axis;
}

export interface Axis {
    title?: string;
}

export interface NumericAxis extends Axis {
    range?: [number, number];
}

export interface ChartCategory {
    metricKey: string;
    displayName?: string;
}

export interface Leaderboard {
    // your datasetID
    id: string;

    metadata: LeaderboardMetadata;
    disablePublishSpeedBump?: boolean;
    rankingChartMin?: number;
    rankingChartMax?: number;

    showUnpublishedScores?: boolean; // whether to display test / blind scores to users prior to results being published

    submissionCount?: number; // how many submissions do we currently have
    topScore?: number; // what is the current top score
    lastPublished?: moment.Moment; // when was the public board last updated
}

export interface Scores {
    // optional human score for comparison
    humanScore?: number;
}

function values<T>(dict: { [id: string]: T }): T[] {
    return Object.keys(dict).map((id) => dict[id]);
}

export function getSortedLeaderboards(leaderboards: LeaderboardList) {
    return values(leaderboards)
        .filter((lb) => !lb.metadata.hiddenFromMenu)
        .sort((a: Leaderboard, b: Leaderboard) => {
            if (a.metadata.shortName === b.metadata.shortName) {
                return a.id.localeCompare(b.id);
            }
            return a.metadata.shortName.localeCompare(b.metadata.shortName);
        });
}
