import React from 'react';
import styled from 'styled-components';
import Tabs from 'antd/es/tabs';

import { MarkdownReader } from './MarkdownReader';

interface Props {
    source: string;
    errorMessage?: string;
    onChange?: (event: any) => void;
}

export const MarkdownEditor: React.FunctionComponent<Props> = (props: Props) => {
    const helpText = `Headers:
  # H1
  ## H2

  Lines:
  ---
  ***
  ___

  Lists:
  1. Ordered1
  2. Ordered2
  * Unordered1
  * Unordered2

  Emphasis:
  **Bold**
  *Italic*
  ~~strikeout~~

  Links:
  [link](https://www.google.com)

  Images:
  ![alt text](https://github.com/adam-p/markdown-here/raw/master/src/common/images/icon48.png "Logo Title Text 1")

  Code blocks:
  \`\`\`javascript
  var s = "JavaScript syntax highlighting";
  alert(s);
  \`\`\`

  Tables:
  | Tables        | Are           | Cool  |
  | ------------- |:-------------:| -----:|
  | col 3 is      | right-aligned | $1600 |
  | col 2 is      | centered      |   $12 |
  | zebra stripes | are neat      |    $1 |

  Markdown | Less | Pretty
  --- | --- | ---
  *Still* | \`renders\` | **nicely**
  1 | 2 | 3
  ---

  Blockquotes:
  > blockquote

  `;

    return (
        <Tabs defaultActiveKey="editor">
            <Tabs.TabPane tab="Editor" key="editor">
                <FullWidthTextArea rows={20} value={props.source} onChange={props.onChange} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Preview" key="preview">
                <Reader>
                    <MarkdownReader source={props.source} />
                </Reader>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Examples" key="examples">
                <FullWidthTextArea readOnly={true} rows={20} value={helpText} />
            </Tabs.TabPane>
        </Tabs>
    );
};

const FullWidthTextArea = styled.textarea`
    outline: 0;
    width: 97%;
    border: none;
    margin-top: -10px;
    resize: none;
    font-size: 0.875em; /* 14px */
    font-family: monospace;
    padding: 10px;
`;

const Reader = styled.section`
    margin-top: -10px;
    padding: 10px;
    background-color: ${(props) => props.theme.color.N3.toString()};
    min-height: 340px;
`;
