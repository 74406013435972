import { isEmpty } from 'lodash';
import React from 'react';
import { CheckCircle, Edit, XCircle } from 'react-feather';
import styled from 'styled-components';

import { MarkdownEditor } from './MarkdownEditor';
import { MarkdownReader } from './MarkdownReader';
import { None } from './None';
import { Button } from './Shared';
import { SmallSpinner } from './SmallSpinner';
import {
    EditableProps,
    EditButton,
    ErrorMessage,
    Row,
    withEditableContainer,
} from './WithEditableContainer';

interface Props extends EditableProps {
    className?: string;
}

const EditableMarkdownComponent: React.FunctionComponent<Props> = (props) => {
    return props.isEditing ? (
        <SectionWithMargin className={props.className}>
            <div>
                <MarkdownEditor source={props.draftText || ''} onChange={props.onChange} />
                {props.isRequesting ? (
                    <SmallSpinner />
                ) : (
                    <ButtonArea>
                        <MdButton onClick={props.onSubmit}>
                            <CheckCircle size={20} />
                            <span>Save</span>
                        </MdButton>
                        <MdButton onClick={props.onCancel}>
                            <XCircle size={20} />
                            <span>Cancel</span>
                        </MdButton>
                    </ButtonArea>
                )}
            </div>
            <Row>
                {!isEmpty(props.errorMessage) && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
            </Row>
        </SectionWithMargin>
    ) : (
        <SectionWithMargin className={props.className}>
            <Row>
                {isEmpty(props.text) ? <None /> : <MarkdownReader source={props.text} />}
                <MdEditButton onClick={props.onEdit}>
                    <Edit size={20} />
                </MdEditButton>
            </Row>
        </SectionWithMargin>
    );
};

const ButtonArea = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const MdButton = styled(Button)`
    display: flex;
    align-items: center;
    margin: 10px 10px 0 0;

    &:last-child {
        margin-right: 0;
    }

    svg {
        margin-right: 5px;
    }
`;

const MdEditButton = styled(EditButton)`
    height: 40px;
`;

const SectionWithMargin = styled.section`
    margin-top: 15px;
`;

export const EditableMarkdown = styled(withEditableContainer(EditableMarkdownComponent))``;
