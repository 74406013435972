export type EmptyObject = {};

export interface Dictionary<S> {
    [id: string]: S;
}

export function sortByNumber<T>(arr: T[], func: (v: T) => number) {
    if (arr) {
        return arr.sort((s1, s2) => func(s1) - func(s2));
    }
    return arr;
}

export function sortByString<T>(arr: T[], func: (v: T) => string) {
    if (arr) {
        return arr.sort((s1, s2) => func(s1).localeCompare(func(s2)));
    }
    return arr;
}
