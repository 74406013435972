import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Leaderboard } from '../../leaderboards';
import { Config } from '../../types';
import { ReduxState } from '../../redux';

export interface WithConfigProps {
    config: Config;
    selectedLeaderboard: Leaderboard;
}

const WithConfigOuter = <P extends object>(Component: React.ComponentType<P>) =>
    class WithConfigInner extends React.PureComponent<P & WithConfigProps> {
        render() {
            return <Component {...this.props} />;
        }
    };

const mapStateToProps = (state: ReduxState) => ({
    config: state.config.config,
    selectedLeaderboard: state.config.selectedLeaderboard,
});

// TODO: i cannot figure out how to get the right type here
export const WithConfig = compose(connect(mapStateToProps, null), WithConfigOuter) as any;
