import React from 'react';
import styled from 'styled-components';

import { Leaderboard } from '../../leaderboards';
import { MetricScore } from '../../types';
import { Dictionary, formatScore } from '../../util';

interface Props {
    scores: Dictionary<MetricScore>;
    selectedLeaderboard: Leaderboard;
    className?: string;
}

class MetricScores extends React.PureComponent<Props> {
    render() {
        const { scores, selectedLeaderboard, className } = this.props;

        return (
            <Wrapper className={className}>
                <Header>Score{Object.keys(scores).length > 1 ? 's' : ''}</Header>
                <table>
                    <tbody>
                        {Object.keys(scores)
                            .map((k) => scores[k])
                            .sort((a, b) => a.displayOrder - b.displayOrder)
                            .map((s) => {
                                return (
                                    <Score key={s.metricKey}>
                                        <Label>
                                            <div>{s.displayName}:</div>
                                        </Label>
                                        <Value>
                                            {formatScore(
                                                s.blindScore,
                                                selectedLeaderboard.metadata.metricPrecision
                                            )}
                                        </Value>
                                    </Score>
                                );
                            })}
                    </tbody>
                </table>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    line-height: 0.9;
`;

const Header = styled.div`
    font-size: 1.125em; /* 18px */
    text-align: center;
    padding-bottom: 2px;
    margin-bottom: 2px;
    border-bottom: 1px solid ${(props) => props.theme.color.N6.toString()};
`;

const Score = styled.tr``;

const Label = styled.div`
    text-align: right;
    div {
        width: max-content;
    }
`;

const Value = styled.td`
    font-weight: bold;
    text-align: left;
`;

export default styled(MetricScores)`
    font-size: 0.9375em; /* 15px */
    color: ${(props) => props.theme.color.N9.toString()};
`;
