import * as React from 'react';

import { adminGetSubmissionDetails } from '../../../api/admin';
import { Submission } from '../../../types';
import { RouteAwareComponentProps } from '../../../util';
import Page from '../../layout/Page';

import AdminSubmissionDetails from './AdminSubmissionDetails';

enum LoadState {
    INITIAL,
    LOADING,
    LOADED,
    FAILED,
}

interface MatchParams {
    id: string;
}

interface State {
    loadState: LoadState;
    loadMessage: string;
    submission?: Submission;
}

type Props = RouteAwareComponentProps<{}, MatchParams>;

class AdminSubmissionDetailsContainer extends React.PureComponent<Props, State> {
    constructor(props: RouteAwareComponentProps<Props, MatchParams>) {
        super(props);

        this.state = {
            loadState: LoadState.INITIAL,
            loadMessage: 'Loading',
        };
    }

    componentDidMount() {
        this.startLoading();
    }

    startLoading() {
        const submissionID = this.props.match.params.id;
        if (!submissionID) {
            this.setState({
                loadState: LoadState.FAILED,
                loadMessage: 'Missing submission ID query arg.',
            });
            return;
        }

        this.setState({
            loadState: LoadState.LOADING,
            loadMessage: 'Loading',
        });

        adminGetSubmissionDetails(submissionID)
            .then((submission: Submission) => {
                this.setState({
                    loadState: LoadState.LOADED,
                    loadMessage: '',
                    submission: submission,
                });
            })
            .catch((err) => {
                if (err.response.status >= 400 && err.response.status <= 499) {
                    this.setState({
                        loadState: LoadState.FAILED,
                        loadMessage:
                            '4xx response from server. Either this is not found, or you must be authenticated and authorized.',
                    });
                    return;
                }

                this.setState({
                    loadState: LoadState.FAILED,
                    loadMessage: 'Unexpected error.',
                });
            });
    }

    render() {
        if (this.state.loadState !== LoadState.LOADED) {
            return (
                <Page>
                    <span>{this.state.loadMessage}</span>
                </Page>
            );
        }

        return this.state.submission ? (
            <Page>
                <AdminSubmissionDetails submission={this.state.submission} />
            </Page>
        ) : (
            <Page>Error: No submission</Page>
        );
    }
}

export default AdminSubmissionDetailsContainer;
