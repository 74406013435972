export function ellipsesify(str: string, maxLen: number, symbol: string = '…'): string {
    if (!str) {
        return '';
    }
    const len = str.length;
    if (len <= maxLen) {
        return str;
    }
    return str.substr(0, maxLen - symbol.length) + symbol;
}

export function getFullTitleIfTruncated(str: string, maxLen: number): string {
    if (str && str.length > maxLen) {
        return str;
    }
    return '';
}

// todo: we are assuming that ALL leaderboard metrics are 0-100% (this may need to be configured later)
export const formatScore = (score?: number, metricPrecision?: number) => {
    return score !== undefined ? score.toFixed(metricPrecision || 4) : 'n/a';
};

export function pluralize(str: string, count: number): string {
    if (count === 0 || count > 1) {
        return `${count} ${str}s`;
    }
    return `${count} ${str}`;
}
