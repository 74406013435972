import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { below, Breakpoint } from '../../Breakpoint';
import { getSortedLeaderboards, Leaderboard } from '../../leaderboards';
import { Config } from '../../types';
import { EmptyRouteAwareComponentProps } from '../../util';
import PaginatedList from '../general/PaginatedList';
import MaxWidth from '../layout/MaxWidth';
import Page from '../layout/Page';

import DatasetCard from './DatasetCard';
import DatasetCardFeatured from './DatasetCardFeatured';

interface Props {
    className?: string;
    config: Config;
    selectedLeaderboard: Leaderboard;
}

const Home: React.FunctionComponent<EmptyRouteAwareComponentProps<Props>> = (props) => {
    return (
        <div className={props.className}>
            {props.config.featuredLeaderboard ? (
                <DatasetCardFeatured leaderboard={props.config.featuredLeaderboard} />
            ) : null}
            <Page
                title="Home"
                description="The AI2 Leaderboard platform hosts public leaderboards for a variety of AI challenges across multiple research domains.">
                <MaxWidth>
                    <StyledLeaderboardList
                        leaderboards={getSortedLeaderboards(props.config.leaderboardsById)}
                    />
                </MaxWidth>
            </Page>
        </div>
    );
};

interface LeaderboardListProps {
    className?: string;
    leaderboards: Leaderboard[];
}

const LeaderboardList: React.FunctionComponent<LeaderboardListProps> = (
    props: LeaderboardListProps
) => {
    return (
        <div className={props.className}>
            {/* TODO: Fix the <PaginatedList /> types, which are being erased by withRouter() */}
            <PaginatedList pageSize={100} items={props.leaderboards}>
                {(items: unknown, changePageLinks: React.ReactNode) => {
                    const lbs = items as Leaderboard[];
                    return (
                        <>
                            <PageLinks>
                                {lbs.map((lb, i) => (
                                    <DatasetCard
                                        key={i}
                                        leaderboard={lb}
                                    />
                                ))}
                            </PageLinks>
                            {changePageLinks}
                        </>
                    );
                }}
            </PaginatedList>
        </div>
    );
};

const StyledLeaderboardList = styled(LeaderboardList)`
    margin-top: 10px;
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const PageLinks = styled.div`
    ${DatasetCard}:nth-child(even) {
        background: ${({ theme }) => theme.color.B1};
        margin: -15px -30px 15px -30px;
        padding: 15px 30px 15px 30px;

        @media ${below(Breakpoint.MEDIUM)} {
            margin: -22px -30px -22px -30px;
            padding: 22px 30px 22px 30px;
        }
    }
`;

export default withRouter(Home);
