import * as React from 'react';

import { H1 } from '../typography';

export const AdminHeader: React.FunctionComponent = () => (
    <>
        <H1>Leaderboard Admin</H1>
        <p>
            <a href="/admin">Leaderboards</a> | <a href="/admin/submissions">Submissions</a> |{' '}
            <a href="/admin/events">Submission Events</a>
        </p>
    </>
);
