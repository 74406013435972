import React from 'react';

import { PublishStatus, Submission } from '../../types';
import { WithUpdating } from '../general/WithUpdating';

import { Ladder, LadderHeader } from './Ladder';
import { SubmissionsProps } from './Submissions';

class MySubmissions extends React.PureComponent<SubmissionsProps> {
    render() {
        const publishedSubmissions: Submission[] = [];
        const otherSubmissions: Submission[] = [];
        if (this.props.privateSubmissions) {
            for (const s of this.props.privateSubmissions) {
                if (s.publishStatus === PublishStatus.Published) {
                    publishedSubmissions.push(s);
                } else if (!s.isArchived) {
                    otherSubmissions.push(s);
                }
            }
        }

        return (
            <>
                <LadderHeader>Published Submissions</LadderHeader>
                <Ladder
                    submissions={publishedSubmissions}
                    focusedSubmission={this.props.focusedSubmission}
                    onFocusOnSubmission={this.props.onFocusOnSubmission}
                    config={this.props.config}
                    selectedLeaderboard={this.props.selectedLeaderboard}
                    isPrivate={false}
                />
                <LadderHeader>Unpublished Submissions</LadderHeader>
                <Ladder
                    submissions={otherSubmissions}
                    focusedSubmission={this.props.focusedSubmission}
                    onFocusOnSubmission={this.props.onFocusOnSubmission}
                    onPublishSubmission={this.props.onPublishSubmission}
                    config={this.props.config}
                    selectedLeaderboard={this.props.selectedLeaderboard}
                    onUpdate={this.props.onUpdate}
                    isPrivate={true}
                />
            </>
        );
    }
}

export default WithUpdating(MySubmissions);
