import React from 'react';
import { connect } from 'react-redux';

import { selectLeaderboard } from '../../redux';
import { WithConfig, WithConfigProps } from '../general/WithConfig';

import Home from './Home';

interface Props extends WithConfigProps {
    selectLeaderboard(leaderboardId?: string): void;
}

class HomeContainer extends React.PureComponent<Props> {
    componentDidMount() {
        this.props.selectLeaderboard();
    }

    render() {
        return (
            <Home config={this.props.config} selectedLeaderboard={this.props.selectedLeaderboard} />
        );
    }
}

export default connect(
    () => {
        return {};
    },
    { selectLeaderboard }
)(WithConfig(HomeContainer));
