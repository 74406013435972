import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import React from 'react';
import MediaQuery from 'react-responsive';
import styled, { css } from 'styled-components';

import { above, Breakpoint } from '../../Breakpoint';
import { User } from '../../types';
import { logoutAndReload } from '../../util';
import { ExternalHeaderLink } from '../general/Shared';

interface Props {
    loggedInUser?: User;
    isWhoAmIRequesting: boolean;
}

const UserLogin = (props: Props) => {
    if (!props.loggedInUser) {
        return (
            <LoginContainer>
                <ExternalHeaderLink href="/login">
                    Log In <Login />
                </ExternalHeaderLink>
            </LoginContainer>
        );
    }

    const user = props.loggedInUser as User;
    return (
        <LoginContainer>
            <ExternalHeaderLink onClick={logoutAndReload}>
                <span>
                    <MediaQuery query={above(Breakpoint.XSMALL)}>
                        {(matches) => (matches ? <span>Hello {user.name}, </span> : null)}
                    </MediaQuery>
                    Log Out <Logout />
                </span>
            </ExternalHeaderLink>
        </LoginContainer>
    );
};

const iconBase = css`
    color: ${(props) => props.theme.color.B6.toString()};
    margin-left: 6px;
`;

const Login = styled(LoginOutlined)`
    ${iconBase}
`;

const Logout = styled(LogoutOutlined)`
    ${iconBase}
`;

const LoginContainer = styled.div`
    user-select: none;
    margin: 0 15px;
`;

export default UserLogin;
