import React from 'react';
import styled from 'styled-components';

import { below, Breakpoint } from '../../Breakpoint';
import MaxWidth from '../layout/MaxWidth';

interface Props {
    children: JSX.Element[] | React.ReactNode | React.ReactNode[];
    className?: string;
}

const TwoColumnLayout: React.FunctionComponent<Props> = (props) => {
    if (React.Children.count(props.children) !== 2) {
        throw new Error('TwoColumnLayout requires exactly 2 children');
    }
    return <MaxWidth className={props.className}>{props.children}</MaxWidth>;
};

export default styled(TwoColumnLayout)`
    display: grid;
    // The minmax here prevents a grid blowout:
    // https://css-tricks.com/preventing-a-grid-blowout/
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    gap: ${({ theme }) => theme.spacing.xl};

    @media ${below(Breakpoint.MEDIUM)} {
        grid-template-columns: minmax(0, auto);
    }
`;
