import React from 'react';
import { connect } from 'react-redux';

import { UserState } from '../../redux';
import { User } from '../../types';

import UserLogin from './UserLogin';

interface Props {
    loggedInUser?: User;
    isWhoAmIRequesting: boolean;
}

class UserLoginContainer extends React.PureComponent<Props> {
    render() {
        return <UserLogin {...this.props} />;
    }
}

export default connect((state: any) => {
    const userState: UserState = state.user;
    return {
        loggedInUser: userState.selectedUser,
        isWhoAmIRequesting: userState.isWhoAmIRequesting,
    };
})(UserLoginContainer);
