import { Table } from 'antd';
import * as React from 'react';

import { Leaderboard } from '../../../leaderboards';
import { Config } from '../../../types';
import { formatAgo, olderThanDays } from '../../../util';
import Page from '../../layout/Page';
import { AdminHeader } from '../AdminHeader';
import { AdminSubHeader } from '../AdminSubHeader';
import { AdminUsage } from '../AdminUsage';

export interface Props {
    config: Config;
}

export class AdminHome extends React.Component<Props> {
    render() {
        const leaderboardsSortedById = this.props.config.leaderboards.sort((a, b) =>
            a.id.localeCompare(b.id)
        );

        return (
            <Page>
                <AdminHeader />
                <AdminUsage />
                <AdminSubHeader title="Leaderboards">
                    <a href="/admin/leaderboard/new">Create a new leaderboard.</a>
                </AdminSubHeader>
                <p>Below are {leaderboardsSortedById.length} leaderboards.</p>
                <Table<Leaderboard>
                    dataSource={leaderboardsSortedById}
                    columns={[
                        {
                            title: 'Leaderboard ID',
                            key: 'id',
                            render: (lb) => (
                                <a href={`/${lb.id}`}>
                                    {lb.id}
                                    {lb.metadata.hiddenFromMenu ? ' (hidden)' : null}
                                </a>
                            ),
                        },
                        {
                            title: 'Team',
                            key: 'team',
                            render: (lb) => (
                                <>
                                    {lb.metadata.team.name}
                                    {lb.metadata.tagIds
                                        ? lb.metadata.tagIds.indexOf('ai2') > -1
                                            ? null
                                            : ' (' + lb.metadata.tagIds.join(',') + ')'
                                        : null}
                                </>
                            ),
                        },
                        {
                            title: 'Metrics',
                            key: 'metrics',
                            render: (lb) => lb.metadata.metrics.length,
                        },
                        {
                            title: 'Submissions',
                            key: 'submissions',
                            render: (lb) => (
                                <>
                                    {lb.submissionCount > 0 ? (
                                        <span
                                            title={
                                                'Last submission was published ' +
                                                formatAgo(lb.lastPublished)
                                            }>
                                            {lb.submissionCount}
                                            {!olderThanDays(lb.lastPublished, 30)
                                                ? ' (' + formatAgo(lb.lastPublished) + ')'
                                                : null}
                                        </span>
                                    ) : (
                                        0
                                    )}
                                </>
                            ),
                        },
                        {
                            title: 'Show Score before Publish?',
                            key: 'prePublishScore',
                            render: (lb) => (lb.showUnpublishedScores ? 'Show' : null),
                        },
                        {
                            title: 'Edit Configuration',
                            key: 'editConfig',
                            render: (lb) => <a href={`/admin/leaderboard/edit/${lb.id}`}>Edit</a>,
                        },
                    ]}
                    pagination={false}
                />
            </Page>
        );
    }
}

export default AdminHome;
