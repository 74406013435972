import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { getConfig, ReduxState, whoAmI } from '../../redux';
import { Config } from '../../types';
import { Connected, cookieExists, EmptyRouteAwareComponentProps } from '../../util';

import { CircleSpinnerSVG } from './CircleSpinnerSVG';
export interface Props {
    config?: Config;
    isConfigRequesting: boolean;
    isWhoAmIRequesting: boolean;
    children: JSX.Element;
    getConfig: Connected<typeof getConfig>;
    whoAmI: Connected<typeof whoAmI>;
}

class WithStateFromServer extends React.PureComponent<EmptyRouteAwareComponentProps<Props>> {
    componentDidMount() {
        this.props.getConfig();
        this.props.whoAmI(cookieExists('User-Token'));
    }

    render() {
        const isLoading = this.props.isConfigRequesting || this.props.isWhoAmIRequesting;
        if (isLoading) {
            return (
                <LoadingContainer>
                    <Loading>
                        <CircleSpinnerSVG size="48" />
                        <p>Loading</p>
                    </Loading>
                </LoadingContainer>
            );
        } else {
            return this.props.children;
        }
    }
}

const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Loading = styled.div`
    padding: 30px;
    text-align: center;
    text-transform: uppercase;

    p {
        margin: 15px 0 0;
    }
`;

function mapStateToProps(state: ReduxState) {
    return {
        config: state.config.config,
        isConfigRequesting: state.config.isConfigRequesting,
        isWhoAmIRequesting: state.user.isWhoAmIRequesting,
    };
}

export default withRouter(connect(mapStateToProps, { getConfig, whoAmI })(WithStateFromServer));
