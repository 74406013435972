import { confirmAlert } from 'react-confirm-alert';
import React from 'react';
import styled from 'styled-components';

// external consumers will call using this interface
interface WithEditableContainerProps {
    text?: string;
    errorMessage?: string;
    name?: boolean;
    inputType?: string; // todo: this should be part of the subcomponent... but we are sunsetting this
    requireConfirmation?: boolean;
    confirmationTitle?: string;
    confirmationMessage?: string;
    confirmationChildContent?: JSX.Element;
    onSubmitText: (text: string) => any;
    onBeforeSubmit?: (input: HTMLInputElement) => boolean | undefined;
    required?: boolean;
}

// local state
interface WithEditableContainerState {
    isRequesting: boolean;
    isEditing: boolean;
    draftText: string;
    errorMessage?: string;
}

// components that want to use withEditableContainer must follow this interface
export interface EditableProps {
    text?: string;
    draftText?: string;
    isRequesting?: boolean;
    isEditing?: boolean;
    name?: boolean;
    inputType?: string;
    errorMessage?: string;
    requireConfirmation?: boolean;
    confirmationTitle?: string;
    confirmationMessage?: string;
    confirmationChildContent?: JSX.Element;
    onSave?: (e: React.MouseEvent<HTMLButtonElement>) => any;
    onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => any;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
    onEdit?: (e: React.MouseEvent<HTMLButtonElement>) => any;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>, i: HTMLInputElement) => any;
    onSubmit?: (e: React.MouseEvent<HTMLButtonElement>, i?: HTMLInputElement) => any;
    required?: boolean;
}

// HOC, usage:
// const EditableText:React.ComponentClass<EditableProps> = ...
// const EditableText = withEditableContainer(EditableText);
const withEditableContainer = (Component: React.ComponentType<EditableProps>) =>
    class WithEditableContainer extends React.PureComponent<
        EditableProps & WithEditableContainerProps,
        WithEditableContainerState
    > {
        constructor(props: EditableProps & WithEditableContainerProps) {
            super(props);

            this.state = {
                isRequesting: false,
                isEditing: false,
                draftText: props.draftText || props.text || '',
                errorMessage: props.errorMessage,
            };

            this.onEdit = this.onEdit.bind(this);
            this.onCancel = this.onCancel.bind(this);
            this.onChange = this.onChange.bind(this);
            this.onKeyDown = this.onKeyDown.bind(this);
            this.onSubmit = this.onSubmit.bind(this);
            this.submitOrConfirm = this.submitOrConfirm.bind(this);
        }

        componentWillReceiveProps(nextProps: WithEditableContainerProps) {
            if (this.props.text !== nextProps.text) {
                this.setState({
                    isRequesting: false,
                    isEditing: false,
                    draftText: nextProps.text || '',
                    errorMessage: undefined,
                });
            } else if (nextProps.errorMessage) {
                this.setState({
                    isRequesting: false,
                    errorMessage: nextProps.errorMessage,
                });
            }
        }

        onKeyDown(event: any, input: HTMLInputElement) {
            switch (event.key) {
                case 'Enter':
                    event.preventDefault();
                    this.submitOrConfirm(input);
                    break;
                case 'Escape':
                    event.preventDefault();
                    this.onCancel();
                    break;
                default:
            }
        }

        onEdit() {
            this.setState({
                isEditing: true,
            });
        }

        submitOrConfirm(input?: HTMLInputElement) {
            // If the input is required, use the build-in HTML validation API to ensure the value
            // provided is ok.
            if (this.props.required) {
                if (!input) {
                    throw Error('unable to determine validity: no input');
                }
                // This displays an error message if `isValid` is `false`.
                const isValid = input.reportValidity();
                if (!isValid) {
                    return;
                }
            }

            if (this.props.requireConfirmation) {
                this.showConfirmation();
            } else {
                this.onSubmit();
            }
        }

        onSubmit() {
            const draftText = this.state.draftText.trim();

            if (this.props.text === draftText) {
                this.setState({
                    draftText,
                    isEditing: false,
                });
            } else {
                this.setState({
                    draftText,
                    isRequesting: true,
                });

                this.props.onSubmitText(draftText);
            }
        }

        showConfirmation() {
            const options = {
                title: this.props.confirmationTitle || 'Confirm Change',
                message: this.props.confirmationMessage || 'Are you sure?',
                childrenElement: () => this.props.confirmationChildContent,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: this.onSubmit,
                    },
                    {
                        label: 'No',
                        onClick: this.onCancel,
                    },
                ],
            };
            confirmAlert(options);
        }

        onCancel() {
            this.setState({
                isEditing: false,
                draftText: this.props.text || this.props.draftText || '',
                errorMessage: undefined,
            });
        }

        onChange(event: any) {
            this.setState({
                draftText: event.target.value,
                errorMessage: undefined,
            });
        }

        render() {
            return (
                <Component
                    {...this.state}
                    text={this.props.text}
                    name={this.props.name}
                    inputType={this.props.inputType}
                    onEdit={this.onEdit}
                    onCancel={this.onCancel}
                    onChange={this.onChange}
                    onKeyDown={this.onKeyDown}
                    onSubmit={(e, i) => {
                        this.submitOrConfirm(i);
                    }}
                    required={this.props.required}
                />
            );
        }
    };

export const EditButton = styled.button`
    background: none;
    border: none;
    color: ${(props) => props.theme.color.B6.toString()};
    display: inline-block;
    padding-left: 9px;
    cursor: pointer;
    font-size: 1em;
    &:hover {
        color: ${(props) => props.theme.color.A8.toString()};
    }
    &:active {
        outline: none;
    }
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
`;

export const ErrorMessage = styled.div`
    padding-top: 8px;
    color: ${(props) => props.theme.color.R8.toString()};
`;

export { withEditableContainer };
