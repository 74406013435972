import moment from 'moment';

import { Leaderboard, LeaderboardList } from './leaderboards';
import { Dictionary } from './util';

export interface Entity {
    id: string;
    name?: string;
}

export interface NamedEntity {
    id: string;
    name: string;
}

export interface User extends NamedEntity {
    displayName?: string;
    role?: string;
    email?: string;
}

export interface Dataset extends NamedEntity {
    containerPath: string;
}

export interface EnvironmentVariable extends NamedEntity {
    value: string;
}

export interface SubmissionPost {
    name: string;
    description: string;
    contributors: string;
    /* The beaker ID of the dataset containing the user's predictions. */
    predictionsId?: string;
    /* The name of the file that the user uploaded. This is displayed in the "Confirm" dialogue
     but isn't actually used by the server. */
    predictionsFileName?: string;
    readme?: string;
    email: string;
}

export interface SubmissionPatchSpec {
    name?: string;
    description?: string;
    contributors?: string;
    isArchived?: boolean;
    readme?: string;
    email?: string;
}

export interface AdminSubmission {
    id: string;
    name: string;
    leaderboardId: string;
    beakerUserId: string;
    email?: string;
    evaluatorRef: string;
    created: moment.Moment;
    status: SubmissionStatus;
    publishStatus: PublishStatus;
    archived: boolean;
}

export interface Submission extends NamedEntity {
    user: User;
    blindScore?: number;
    blindStatus?: SubmissionStatus;
    created: moment.Moment;
    published: moment.Moment;
    leaderboardId: string;
    rank?: number;
    description: string; // deprecate and use value in spec?
    contributors: string; // deprecate and use value in spec?
    publishStatus: PublishStatus;
    isArchived?: boolean;
    metricScores: Dictionary<MetricScore>;
    readme?: string;
    email?: string;
    evaluations: Evaluation[]; // only populated for admin views
}

// mirrors the "evaluation" table
export interface Evaluation {
    evalId: string;
    leaderboardId: string;
    evaluator: string;
    evaluatorRef: string;
    status: string;
    metrics: {
        map?: Dictionary<number>;
    };
    createTime: moment.Moment;
}

export interface MetricScore {
    metricKey: string;
    blindScore?: number;
    displayName: string;
    displayOrder: number;
    description?: string;
    isSupplemental?: boolean;
    awaitingSupplemental?: boolean;
}

export interface TaskSpec {
    image: string;
    args: string[];
    env: Dictionary<string>;
    datasetMounts: DatasetMount[];
}

export interface DatasetMount {
    datasetId: string;
    containerPath: string;
}

export enum PublishStatus {
    Unpublished = 'unpublished',
    Published = 'published',
}

export enum SubmissionStatus {
    Pending = 'pending',
    Failed = 'failed',
    Succeeded = 'succeeded',
}

export interface Config {
    leaderboards: Leaderboard[];
    leaderboardsById: LeaderboardList;
    featuredLeaderboard: Leaderboard;
}

export interface Metric {
    key: string;
    displayName: string;
    description?: string;
    isSupplemental?: boolean;
    displayOrder: number;
    humanScore?: number;
}

export interface SubmissionWithInvalidBlindScore {
    id: string;
    leaderboardId: string;
    currentBlindScore: number;
    expectedBlindScore: number;
    isPublished: boolean;
}

export interface MetricsTable {
    columns: MetricsTableColumn[];
}

export interface MetricsTableColumn {
    metricKeys: string[];
    renderer: string;
    name: string;
    description: string;
}
