import { notify } from 'react-notify-toast';

import { ErrorAction, ErrorData } from './error.action';

export interface ErrorState {}

const initialState: ErrorState = {};

export default function error(state: ErrorState = initialState, action: ErrorData) {
    switch (action.type) {
        case ErrorAction.API_ERROR: {
            notify.show(action.message + ' -- ' + action.error.toString(), 'error', 10000);
            return state;
        }

        default: {
            return state;
        }
    }
}
