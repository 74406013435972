import { Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { formatAgo, formatDateTime } from '../../util';

interface Props {
    className?: string;
    /** Start of the relative time. */
    time: moment.Moment;
}

const RelativeTime: React.FunctionComponent<Props> = (props) => {
    return (
        <Tooltip title={formatDateTime(props.time)}>
            <span>{formatAgo(props.time)}</span>
        </Tooltip>
    );
};

export default styled(RelativeTime)`
    cursor: help;
    font-weight: bold;
`;
