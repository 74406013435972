import { Button, Table } from 'antd';
import { BasicFilterDropdown, FilterIcon } from '@allenai/varnish/components';
import moment from 'moment';
import * as React from 'react';
import styled from 'styled-components';

import { LeaderboardEvent } from '../../../api/events';
import { User } from '../../../types';
import Page from '../../layout/Page';
import { AdminHeader } from '../AdminHeader';
import { AdminSubHeader } from '../AdminSubHeader';

export interface Props {
    events: LeaderboardEvent[];
    allRequested: boolean;
    requestAllEvents: () => void;
}

export interface State {}

export class AdminEvents extends React.Component<Props, State> {
    render() {
        return (
            <Page>
                <AdminHeader />
                <AdminSubHeader title="Submission Events" />
                {this.props.allRequested ? (
                    <p>Showing all {this.props.events.length} events</p>
                ) : (
                    <div>
                        <p>Showing {this.props.events.length} most recent events.</p>
                        <p>
                            <Button onClick={this.props.requestAllEvents}>Show all events</Button>
                        </p>
                    </div>
                )}
                <div>
                    <Table<LeaderboardEvent>
                        size="small"
                        dataSource={this.props.events}
                        columns={[
                            {
                                title: 'Date',
                                key: 'date',
                                dataIndex: 'date',
                                render: (date: moment.Moment) => <span>{date.format('lll')}</span>,
                            },
                            {
                                title: 'Submission',
                                key: 'submission',
                                render: (event: LeaderboardEvent) => (
                                    <DisplaySubmission event={event} />
                                ),
                                filterDropdown: BasicFilterDropdown,
                                filterIcon: FilterIcon,
                                onFilter: (
                                    filter: string | number | boolean,
                                    event: LeaderboardEvent
                                ) => {
                                    const lowerFilter = filter.toString().toLocaleLowerCase();
                                    const doc1 = event.extSubmissionId.toLocaleLowerCase();
                                    const doc2 = event.leaderboardId.toLocaleLowerCase();
                                    const doc3 =
                                        event.publishStatus === 'unpublished' ? '(private)' : '';
                                    return (
                                        doc1.indexOf(lowerFilter) > -1 ||
                                        doc2.indexOf(lowerFilter) > -1 ||
                                        doc3.indexOf(lowerFilter) > -1
                                    );
                                },
                            },
                            {
                                title: 'Event',
                                key: 'description',
                                dataIndex: 'description',
                                filters: eventDescFilters(this.props.events),
                                onFilter: (
                                    filter: string | number | boolean,
                                    event: LeaderboardEvent
                                ) => {
                                    const lowerFilter = filter.toString().toLocaleLowerCase();
                                    const doc1 = event.description;
                                    return doc1.toLocaleLowerCase().indexOf(lowerFilter) > -1;
                                },
                            },
                            {
                                title: 'User',
                                key: 'user',
                                dataIndex: 'user',
                                render: (user: User) => <DisplayUser user={user} />,
                                filters: eventUserFilters(this.props.events),
                                onFilter: (
                                    filter: string | number | boolean,
                                    event: LeaderboardEvent
                                ) => {
                                    const lowerFilter = filter.toString().toLocaleLowerCase();
                                    const doc1 = event.user.displayName;
                                    const doc2 = event.user.id === '' ? 'System' : '';
                                    return (
                                        (doc1 &&
                                            doc1.toLocaleLowerCase().indexOf(lowerFilter) > -1) ||
                                        doc2.toLocaleLowerCase().indexOf(lowerFilter) > -1
                                    );
                                },
                            },
                        ]}
                        expandedRowRender={(event: LeaderboardEvent) => {
                            const containers: JSX.Element[] = [];

                            if (event.before || event.after) {
                                containers.push(
                                    <DiffContainer>
                                        <DiffContents>
                                            <strong>Before</strong>
                                            <br />
                                            {event.before ? (
                                                <DiffPre>{event.before}</DiffPre>
                                            ) : (
                                                <None>none</None>
                                            )}
                                        </DiffContents>
                                        <DiffContents>
                                            <strong>After</strong>
                                            <br />
                                            {event.after ? (
                                                <DiffPre>{event.after}</DiffPre>
                                            ) : (
                                                <None>none</None>
                                            )}
                                        </DiffContents>
                                    </DiffContainer>
                                );
                            }

                            return containers.length > 0 ? (
                                containers
                            ) : (
                                <div>No details available.</div>
                            );
                        }}
                        rowKey={(d: any) => d.id}
                        pagination={false}
                    />
                </div>
            </Page>
        );
    }
}

function eventUserFilters(events: LeaderboardEvent[]) {
    // extract distinct values of .user.displayName of every event
    const names = [
        'System', // hard-coded
        ...new Set(
            events
                .map((e: LeaderboardEvent) => e.user.displayName)
                .filter((displayName): displayName is string => typeof displayName !== 'undefined')
        ),
    ];

    // sort them
    names.sort();

    // put them into a list of objects with fields 'text' and 'value', as required by varnish's table.
    return names.map((desc: string) => {
        return { text: desc, value: desc };
    });
}

function eventDescFilters(events: LeaderboardEvent[]) {
    // extract distinct values of .description of every event
    const descriptions = [...new Set(events.map((e: LeaderboardEvent) => e.description))];

    // sort them
    descriptions.sort();

    // put them into a list of objects with fields 'text' and 'value', as required by varnish's table.
    return descriptions.map((desc: string) => {
        return { text: desc, value: desc };
    });
}

const DisplayUser: React.FunctionComponent<{ user: User }> = (props) => {
    if (props.user.id === '') {
        return (
            <span>
                <em>System</em>
            </span>
        );
    }

    return (
        <a href={'https://beaker.org/api/v3/users/' + props.user.id}>
            {props.user.displayName || ''}
        </a>
    );
};

const DisplaySubmission: React.FunctionComponent<{ event: LeaderboardEvent }> = (props) => {
    return (
        <span>
            <a href={'/' + props.event.leaderboardId}>
                <BoardLogo
                    src={'/assets/images/leaderboard/' + props.event.leaderboardId + '/logo.svg'}
                />
            </a>
            <a href={'/admin/submission/' + props.event.extSubmissionId}>
                {props.event.extSubmissionId}
            </a>
            {props.event.publishStatus === 'published' ? (
                <>
                    {' '}
                    <a
                        href={
                            '/' +
                            props.event.leaderboardId +
                            '/submission/' +
                            props.event.extSubmissionId
                        }>
                        (published)
                    </a>
                </>
            ) : null}
        </span>
    );
};

const BoardLogo = styled.img`
    height: 2em;
    margin-right: 1em;
`;

const DiffContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

const DiffContents = styled.div`
    padding: 0.5em;
    margin: 0.5em;
    border: solid 1px ${(props) => props.theme.palette.border.info};
    background-color: ${(props) => props.theme.palette.background.info};
    overflow: auto;
    max-height: 20em;
`;

const DiffPre = styled.pre`
    overflow: unset;
`;

const None = styled.span`
    color: ${(props) => props.theme.palette.text.error};
`;

export default AdminEvents;
