import { escape } from 'lodash';
import moment from 'moment';
import React from 'react';
import { withRouter } from 'react-router';
import { Theme } from '@allenai/varnish/theme';

import { Leaderboard } from '../../leaderboards';
import { MetricScore, PublishStatus, Submission } from '../../types';
import {
    ellipsesify,
    EmptyRouteAwareComponentProps,
    formatScore,
    getMerticScoreByKey,
    getScoreCompareValue,
} from '../../util';

import {
    height,
    hoverlabel,
    legend,
    margin,
    markerSize,
    mutedAlpha,
    NoSubs,
    TopSectionHeader,
    xaxis,
    yaxis,
} from './Charts';
import Plot from './Plot';

/* We source Plotly from it's CDN, since building it into our application
   bundle causes our build to run out of heap space when ran in Google
   Cloud Build. */
declare let Plotly: any;

interface Props {
    className?: string;
    submissions: Submission[];
    focusedSubmission?: Submission;
    selectedLeaderboard: Leaderboard;

    minRange?: number;
    maxRange?: number;

    onFocusOnSubmission: (submission?: Submission) => void;
}

interface Point {
    x: moment.Moment;
    y: number;
    isFocused?: boolean;
    submission?: Submission;
}

const RankingChart: React.FunctionComponent<EmptyRouteAwareComponentProps<Props>> = (props) => {
    let runningBest: number | undefined;
    let first: Submission | undefined;
    let last: Submission | undefined;
    const top = [] as Point[];
    const all = [] as Point[];
    const orderedMetrics = props.submissions ? getOrderedMetrics(props.submissions[0]) : [];
    const primaryMetric = orderedMetrics.length ? orderedMetrics[0] : null;
    const primaryMetricName = primaryMetric ? primaryMetric.displayName : 'Submissions';
    const minRange = props.minRange || 0.0;
    const maxRange = props.maxRange || 1.0;

    // we only chart working submissions
    const workingSubmissions = props.submissions
        ? props.submissions.filter((s) => s.blindScore !== undefined)
        : undefined;

    function getOrderedMetrics(submission: Submission) {
        return submission
            ? Object.keys(submission.metricScores)
                  .map((k) => submission.metricScores[k])
                  .sort((as, bs) => as.displayOrder - bs.displayOrder)
            : [];
    }

    if (workingSubmissions) {
        // sort in time
        const sorted = workingSubmissions
            .concat()
            .sort((s1, s2) => (s1.created.isBefore(s2.created) ? -1 : 1));
        sorted.forEach((s) => {
            const score = getScoreCompareValue(
                s,
                getMerticScoreByKey(
                    props.selectedLeaderboard.metadata.metrics[0].key,
                    s.metricScores
                ),
                props.selectedLeaderboard
            );

            if (score !== undefined) {
                if (!runningBest || runningBest < score) {
                    // add a new best so far
                    top.push({
                        x: s.created,
                        y: score,
                        submission: s,
                    });
                    runningBest = score;
                }
                // collect all
                all.push({
                    x: s.created,
                    y: score,
                    submission: s,
                    isFocused: props.focusedSubmission && props.focusedSubmission.id === s.id,
                });
                if (!first) {
                    // hold on to working first and last for human performance
                    first = s;
                }
                last = s;
            }
        });
        if (last !== undefined && runningBest !== undefined) {
            top.push({
                x: last.created.clone().add(1, 'seconds'),
                y: runningBest,
                submission: undefined,
            }); // add a final point for running best
        }
    }
    const pointFunc = (a: Point[]) => {
        return {
            x: a.map((p: Point) => p.x.toISOString()),
            y: a.map((p: Point) => p.y),
            customdata: a.map((p: Point) => {
                if (!p.submission) {
                    return {};
                }
                return {
                    title: escape(ellipsesify(p.submission.name, 26)),
                    scores: getOrderedMetrics(p.submission).map((mScore: MetricScore) => {
                        return {
                            displayName: mScore.displayName,
                            value: formatScore(
                                getScoreCompareValue(
                                    // This cast is safe because of the guar at line 132
                                    p.submission as Submission,
                                    mScore,
                                    props.selectedLeaderboard
                                ),
                                props.selectedLeaderboard.metadata.metricPrecision
                            ),
                        };
                    }),
                };
            }),
        };
    };

    const markerColors = all.map((p: Point) =>
        p.isFocused ? Theme.color.A8.toString() : Theme.color.B6.toString()
    );
    const markerOpacity = all.map((p: Point) =>
        !props.focusedSubmission || p.isFocused ? 1 : mutedAlpha
    );
    const markerSymbols = all.map((p: Point) =>
        p.submission && p.submission.publishStatus === PublishStatus.Published ? 2 : 27
    );
    const getSubmission: (event: any) => Submission | undefined = (event: any) => {
        if (event.points && event.points.length && event.points[0].pointIndex < all.length) {
            return all[event.points[0].pointIndex].submission;
        }
        return undefined;
    };

    const dataSeries = [
        {
            ...pointFunc(top),
            type: 'scatter',
            mode: 'lines',
            marker: {
                color: Theme.color.O3.toString(),
            },
            name: 'Running Best',
            hoverinfo: 'none',
        },
        {
            ...pointFunc(all),
            type: 'scatter',
            mode: 'markers',
            marker: {
                color: markerColors,
                opacity: markerOpacity,
                size: markerSize,
                symbol: markerSymbols,
                line: {
                    color: Theme.color.N9.toString(),
                    width: 1,
                },
            },
            name: 'Submissions',
            hovertemplate:
                '<b>%{customdata.title}</b><br><br>' +
                orderedMetrics
                    .map(
                        (k, i) =>
                            '%{customdata.scores[' +
                            i +
                            '].displayName}: <b>%{customdata.scores[' +
                            i +
                            '].value}</b><br>'
                    )
                    .join('') +
                '<extra></extra>',
        },
    ];

    // Add a line for human performance, if one exists
    if (primaryMetric) {
        const metric = props.selectedLeaderboard.metadata.metrics.find(
            (m) => m.key === primaryMetric.metricKey
        );
        if (metric) {
            if (metric.humanScore !== undefined && first !== undefined && last !== undefined) {
                const startPoint: Point = {
                    x: first.created.clone().add(-1, 'seconds'),
                    y: metric.humanScore,
                    submission: undefined,
                };
                const endPoint: Point = {
                    x: last.created.clone().add(1, 'seconds'),
                    y: metric.humanScore,
                    submission: undefined,
                };
                dataSeries.unshift({
                    ...pointFunc([startPoint, endPoint]),
                    type: 'scatter',
                    mode: 'lines',
                    marker: {
                        color: Theme.color.O8.toString(),
                    },
                    name: `Human ${metric.displayName}`,
                    hoverinfo: formatScore(
                        metric.humanScore,
                        props.selectedLeaderboard.metadata.metricPrecision
                    ),
                });
            }
        }
    }

    const layout = {
        legend,
        hoverlabel,
        yaxis: {
            ...yaxis,
            title: primaryMetricName,
            range: [minRange, maxRange],
        },
        xaxis: {
            ...xaxis,
            title: 'Submission Date',
        },
        height,
        margin,
    };

    const config = { displayModeBar: false, responsive: true };

    return (
        <div className={props.className}>
            <TopSectionHeader>{primaryMetricName} Over Time</TopSectionHeader>
            <section>
                {!workingSubmissions || !workingSubmissions.length ? (
                    <NoSubs>No Submissions yet</NoSubs>
                ) : (
                    <Plot
                        data={dataSeries}
                        layout={layout}
                        config={config}
                        onMouseOverPoint={(event) => {
                            /* Adapted hack for changing the cursor in Plotly:
                   @see https://codepen.io/etpinard/pen/ZpyRxr */
                            Plotly.d3
                                .select('g.draglayer')
                                .selectAll('rect')
                                .style('cursor', 'pointer');
                            props.onFocusOnSubmission(getSubmission(event));
                        }}
                        onMouseOutPoint={() => {
                            Plotly.d3
                                .select('g.draglayer')
                                .selectAll('rect')
                                .style('cursor', 'default');
                            props.onFocusOnSubmission();
                        }}
                        onClick={(event) => {
                            const submission = getSubmission(event);
                            if (submission !== undefined) {
                                const submissionId = submission.id;
                                const detailsUrl = `/${props.selectedLeaderboard.id}/submission/${submissionId}`;
                                props.history.push(detailsUrl);
                            }
                        }}
                    />
                )}
            </section>
        </div>
    );
};

export default withRouter(RankingChart);
