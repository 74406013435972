import { Theme } from '@allenai/varnish/theme';
import styled from 'styled-components';

import { SectionHeader } from '../general/Shared';

const titlefont = {
    size: 13,
    color: Theme.color.N7.toString(),
};

const tickfont = {
    size: 9,
    color: Theme.color.N7.toString(),
};

export const NoSubs = styled.div`
    margin: 7px;
    color: ${(props) => props.theme.color.N9.toString()};
`;

export const TopSectionHeader = styled(SectionHeader)`
    margin-top: 0;
`;

export const markerSize = 10;

export const hoverlabel = { bgcolor: '#FFF' };

export const yaxis = {
    tickformat: '.2f',
    tickfont,
    titlefont,
    fixedrange: true,
};

export const xaxis = {
    showgrid: false,
    tickformat: '%x',
    tickfont,
    titlefont,
    tickangle: 30,
};

export const height = 330;

export const legend = {
    x: 1.1,
};

export const margin = {
    l: 30 + 16, // 16 is space for the axis label
    r: 15,
    b: 45 + 16,
    t: 10,
    pad: 1,
};

export const mutedAlpha = 0.33;
