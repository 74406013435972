import * as React from 'react';
import styled from 'styled-components';

import moment from 'moment';

import { Table, TableProps } from 'antd';

import { ColumnType } from 'antd/lib/table';

import { Evaluation, Submission } from '../../../types';
import Page from '../../layout/Page';
import { AdminHeader } from '../AdminHeader';

import RelativeTime from '../../general/RelativeTime';
import { AdminSubHeader } from '../AdminSubHeader';

import { HiddenEmail } from "../../general/HiddenEmail";

export interface Props {
    submission: Submission;
}

export interface State {
    emailHidden: boolean;
}

export class AdminSubmissionDetails extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            emailHidden: true,
        };
    }

    render() {
        return (
            <Page>
                <AdminHeader />
                <AdminSubHeader title="Submission Details" />

                <Details>
                    <DetailsName>LeaderboardID</DetailsName>
                    <div>{this.props.submission.leaderboardId}</div>

                    <DetailsName>SubmissionID</DetailsName>
                    <div>{this.props.submission.id}</div>

                    <DetailsName>Name</DetailsName>
                    <div>{this.props.submission.name}</div>

                    <DetailsName>Email</DetailsName>
                    {this.props.submission.email
                        ? (
                                <div>
                                    <HiddenEmail email={this.props.submission.email} hidden={this.state.emailHidden}/>
                                    {this.state.emailHidden
                                        ? (
                                            <button onClick={() => { this.revealEmail() }}>Reveal email...</button>
                                        )
                                        : null
                                    }
                                </div>
                        )
                        : (
                            <div>No email provided</div>
                        )
                    }

                    <DetailsName>Link</DetailsName>
                    <div>
                        <Link submission={this.props.submission} />
                    </div>

                    <DetailsName>Evaluations</DetailsName>
                    <EvaluationsTable evaluations={this.props.submission.evaluations} />

                    <DetailsName>JSON</DetailsName>
                    <WrappedForJSON>
                        {JSON.stringify(redactEmail(this.props.submission), null, 2)}
                    </WrappedForJSON>
                </Details>
            </Page>
        );
    }

    revealEmail() {
        if (confirm("You are about to reveal the email address on this page.\n\nDo not share this outside AI2.")) {
            this.setState({emailHidden: false});
        }
    }
}


// Redact the email field, so it's not shown in the JSON view.
function redactEmail(s: Submission): Submission {
    let s2 =  Object.assign({}, s);
    s2.email = "redacted";
    return s2;
}

function EvaluationsTable(props: { evaluations: Evaluation[] }): JSX.Element {
    const columns: ColumnType<Evaluation>[] = [
        {
            title: 'Evaluation ID',
            dataIndex: 'evaluationId',
            key: 'evaluationID',
        },
        {
            title: 'Create time',
            dataIndex: 'createTime',
            key: 'createTime',
            render: function (createTime: moment.Moment) {
                return <RelativeTime time={createTime} />;
            },
        },
        {
            title: 'Evaluator',
            dataIndex: 'evaluator',
            key: 'evaluator',
        },
        {
            title: 'Reference',
            dataIndex: 'evaluatorRef',
            key: 'evaluatorRef',
            render: function (evaluatorRef) {
                if (!evaluatorRef) {
                    // if there are is no reference, say so
                    return <i>No evaluator ref</i>;
                }
                return <>{evaluatorRef}</>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Metrics',
            dataIndex: 'metrics',
            key: 'metrics',
            render: function (_, record: Evaluation) {
                if (!record.metrics.map) {
                    // if there are no metrics, then we render nothing
                    return <i>No metrics</i>;
                }
                if (Object.keys(record.metrics.map).length === 1) {
                    // common case of only one metric is shown in a compact way
                    return (
                        <small>
                            <pre>{JSON.stringify(record.metrics.map)}</pre>
                        </small>
                    );
                }
                // otherwise, it's nicely indented
                return (
                    <small>
                        <pre>{JSON.stringify(record.metrics.map, null, '  ')}</pre>
                    </small>
                );
            },
        },
    ];

    return (
        <TableWithCellContentOnTop
            size="middle"
            rowKey={(record) => record.evalId}
            dataSource={props.evaluations}
            columns={columns}
        />
    );
}

const WrappedForJSON = styled.pre`
    font-size: ${({ theme }) => theme.typography.textStyles.micro.fontSize};
`;

const EvaluationTable = (props: TableProps<Evaluation>) => <Table {...props} />;

const TableWithCellContentOnTop = styled(EvaluationTable)`
    td {
        vertical-align: top;
    }
`;

const Details = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
    gap: ${({ theme }) => theme.spacing.md};
`;

const DetailsName = styled.div`
    font-weight: bold;
`;

interface LinkProps {
    submission: Submission;
}

function Link(props: LinkProps): JSX.Element {
    const path = `/${props.submission.leaderboardId}/submission/${props.submission.id}`;
    return <a href={path}>{path}</a>;
}

export default AdminSubmissionDetails;
