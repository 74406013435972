import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Leaderboard } from '../../leaderboards';
import { Config, User } from '../../types';
import { EmptyRouteAwareComponentProps } from '../../util';
import { MetaTags } from '../general/MetaTags';
import { LinkButton, SectionHeader, SpacedBr, StyledMarkdown } from '../general/Shared';
import MaxWidth from '../layout/MaxWidth';

import DefaultRules from './DefaultRules';
import SubmissionDescription from './SubmissionDescription';

interface Props {
    className?: string;
    config: Config;
    selectedLeaderboard: Leaderboard;
    selectedUser: User;
}

class GettingStarted extends React.PureComponent<EmptyRouteAwareComponentProps<Props>> {
    render() {
        return (
            <div className={this.props.className}>
                <MetaTags
                    title={`Getting Started — ${this.props.selectedLeaderboard.metadata.longName} Leaderboard`}
                    description={`Help documentation for getting started with the ${this.props.selectedLeaderboard.metadata.longName} Leaderboard`}
                />
                <Content>
                    <MaxWidth>
                        <SectionHeader>Getting the Data</SectionHeader>
                        <StyledMarkdown
                            source={this.props.selectedLeaderboard.metadata.gettingTheData}
                        />
                        <SectionHeader>Scoring</SectionHeader>
                        <StyledMarkdown source={this.props.selectedLeaderboard.metadata.scoring} />
                        <SectionHeader>Predictions Format</SectionHeader>
                        <StyledMarkdown
                            source={this.props.selectedLeaderboard.metadata.predictionsFormat}
                        />
                        <SectionHeader>Rules</SectionHeader>
                        <section>
                            {this.props.selectedLeaderboard.metadata.customRules ? (
                                <StyledMarkdown
                                    source={this.props.selectedLeaderboard.metadata.customRules}
                                />
                            ) : (
                                <DefaultRules
                                    name={this.props.selectedLeaderboard.metadata.shortName}
                                />
                            )}
                        </section>
                        <SectionHeader>Example Models</SectionHeader>
                        <StyledMarkdown
                            source={this.props.selectedLeaderboard.metadata.exampleModels}
                        />
                        <SectionHeader>Submitting to the Leaderboard</SectionHeader>
                        <section>
                            If you have a model for solving{' '}
                            {this.props.selectedLeaderboard.metadata.shortName} and would like to
                            make a submission, click "Log In" and sign up to create an account.
                        </section>
                        <section>
                            Next,{' '}
                            <LinkButton
                                onClick={() =>
                                    this.props.history.push(
                                        `/${this.props.selectedLeaderboard.id}/submission/create`
                                    )
                                }>
                                create a submission
                            </LinkButton>{' '}
                            and evaluate your model for the
                            {` ${this.props.selectedLeaderboard.metadata.shortName}`} dataset.
                            <SpacedBr />
                            <SubmissionDescription
                                selectedLeaderboard={this.props.selectedLeaderboard}
                            />
                        </section>
                        <SectionHeader>Getting Help</SectionHeader>
                        <section>
                            If you need any help, please email us at{' '}
                            <LinkButton href="mailto:leaderboard@allenai.org">
                                leaderboard@allenai.org
                            </LinkButton>{' '}
                            and say you're asking about
                            leaderboard <b>
                            {this.props.selectedLeaderboard.metadata.shortName}
                            </b>. Please include a submission URL if you are asking about a specific submission.
                        </section>
                    </MaxWidth>
                </Content>
            </div>
        );
    }
}

const Content = styled.div`
    color: ${(props) => props.theme.color.N9.toString()};
`;

export default styled(withRouter(GettingStarted))`
    ${SectionHeader} {
        align-self: auto;
        margin-top: 25px;
    }
`;
