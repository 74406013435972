import * as React from 'react';
import { Button, Form, Input } from 'antd';

import styled from 'styled-components';

import { AdminHeader } from '../AdminHeader';
import Page from '../../layout/Page';
import { AdminSubHeader } from '../AdminSubHeader';
import { ErrorMessage } from '../../general/Shared';

const { TextArea } = Input;

export function AdminNewLeaderboard() {
    // state for presenting the form
    const [loaded, setLoaded] = React.useState(false);
    const [allowed, setAllowed] = React.useState(false);

    // state of the form
    const [leaderboardID, setLeaderboardID] = React.useState('');
    const [evaluator, setEvaluator] = React.useState('');
    const [primaryMetricKey, setPrimaryMetricKey] = React.useState('');
    const [yaml, setYaml] = React.useState('');

    // state of submitting the form
    const [submitting, setSubmitting] = React.useState(false);
    const [submitMessage, setSubmitMessage] = React.useState<string | null>(null);

    // check if this form should even be rendered
    React.useEffect(() => {
        fetch('/api/v1/admin/allowed').then((response) => {
            setLoaded(true);
            if (response.status === 200) {
                setAllowed(true);
            }
        });
    }, []);

    if (!loaded) {
        return <p>Loading...</p>;
    }

    if (!allowed) {
        return <p>Not allowed.</p>;
    }

    function onFinish() {
        setSubmitting(true);
        setSubmitMessage(null);
        const creation = {
            leaderboard_id: leaderboardID,
            evaluator: evaluator,
            primary_metric_key: primaryMetricKey,
            yaml: yaml,
        };
        fetch('/api/v1/admin/leaderboard', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(creation),
        })
            .then(function (response) {
                if (response.status === 200) {
                    window.location.assign('/admin/leaderboard/edit/' + leaderboardID);
                    return;
                }
                interface HasMessage {
                    message: string;
                }
                return response.json() as Promise<HasMessage>;
            })
            .then(function (parsed) {
                setSubmitMessage(parsed?.message || null);
            })
            .catch(function (error) {
                setSubmitMessage(error);
            })
            .finally(function () {
                setSubmitting(false);
            });
    }

    return (
        <Page>
            <AdminHeader />
            <AdminSubHeader title="Create a new Leaderboard">
                Please look at the{' '}
                <a href="https://github.com/allenai/leaderboard">
                    documentation for creating a leaderboard
                </a>{' '}
                before filling out this form.
            </AdminSubHeader>
            <Form
                onFinish={onFinish}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                layout="horizontal"
                size="small">
                <Form.Item label="Leaderboard ID">
                    <Input
                        value={leaderboardID}
                        onChange={(e) => setLeaderboardID(e.target.value)}
                    />
                    <InputExplanation>
                        The Leaderboard ID is the permanent identifier that will be part of the URL.
                        You can see <a href="/admin">a list of existing leaderboard identifiers</a>{' '}
                        for reference.
                    </InputExplanation>
                </Form.Item>
                <Form.Item label="Jetty Evaluator">
                    <Input value={evaluator} onChange={(e) => setEvaluator(e.target.value)} />
                    <InputExplanation>
                        The Jetty Evaluator "app" name, chosen from{' '}
                        <a href="https://jetty.apps.allenai.org/admin">the list of Jetty apps</a>.
                        You will need to make a new Jetty app for a new Leaderboard.
                    </InputExplanation>
                </Form.Item>
                <Form.Item label="Primary metric name">
                    <Input
                        value={primaryMetricKey}
                        onChange={(e) => setPrimaryMetricKey(e.target.value)}
                    />
                    <InputExplanation>
                        The name of the evaluator metric that will be used for ranking submissions.
                        If your evaluator produces a metrics.json file with{' '}
                        <code>
                            {'{'}"accuracy": 0.78, "f1": 0.45{'}'}
                        </code>
                        , then a primary metric name could be <code>accuracy</code> or{' '}
                        <code>f1</code>.
                    </InputExplanation>
                </Form.Item>
                <Form.Item label="AI2 Internal Contact">
                    <Form.Item name="contact" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <InputExplanation>
                        The name and email address of the person at AI2 who is best able to answer
                        questions from submitters to this Leaderboard.
                    </InputExplanation>
                </Form.Item>
                <Form.Item label="YAML configuration">
                    <TextAreaMonoFont
                        value={yaml}
                        onChange={(e) => setYaml(e.target.value)}
                        rows={20}
                    />
                    <InputExplanation>
                        This is the YAML configuration for this Leaderboard. For an example of what
                        is required look at{' '}
                        <a href="https://github.com/allenai/leaderboard/blob/main/example_configuration.yaml">
                            this example
                        </a>
                        .
                    </InputExplanation>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                    <Button disabled={submitting} type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <InputExplanation>
                        The leaderboard you're creating will be hidden initially. So you can save
                        now, edit the content on the next page, and unhide the leaderboard when
                        you're ready.
                    </InputExplanation>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                    {submitting ? <p>Submitting ...</p> : null}
                    {submitMessage != null && submitMessage.length > 0 ? (
                        <>
                            <p>Couldn't submit:</p>
                            <ErrorMessage>{submitMessage}</ErrorMessage>
                        </>
                    ) : null}
                </Form.Item>
            </Form>
        </Page>
    );
}

const InputExplanation = styled.div`
    padding-top: ${({ theme }) => theme.spacing.md};
    padding-bottom: ${({ theme }) => theme.spacing.md};
`;

const TextAreaMonoFont = styled(TextArea)`
    font-family: monospace;
    font-size: ${({ theme }) => theme.typography.textStyles.small.fontSize};
`;
