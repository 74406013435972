import React from 'react';
import { connect } from 'react-redux';

import {
    focusOnSubmission,
    getSubmissions,
    publishSubmission,
    SubmissionsState,
    update,
    UserState,
} from '../../redux';
import { Submission, SubmissionPatchSpec, User } from '../../types';
import { RouteAwareComponentProps } from '../../util';
import { WithConfig, WithConfigProps } from '../general/WithConfig';
import Page from '../layout/Page';

import Submissions from './Submissions';

interface MatchParams {
    dataset: string;
}

interface DispatchProps {
    getSubmissions: (userId?: string) => Promise<any>;
    focusOnSubmission: (submission?: Submission) => void;
    publishSubmission: (submission?: Submission) => Promise<any>;
    update: (id: string, patchData: SubmissionPatchSpec) => Promise<any>;
}

interface StateProps {
    publicSubmissions: Submission[];
    privateSubmissions: Submission[];
    focusedSubmission: Submission;
    selectedUser: User;
    isPublicListRequesting: boolean;
    isPrivateListRequesting: boolean;
}

interface Props extends WithConfigProps, StateProps, DispatchProps {}

class SubmissionsContainer extends React.PureComponent<
    RouteAwareComponentProps<Props, MatchParams>
> {
    handleGetSubmissions = (userId?: string) => {
        this.props.getSubmissions(userId);
    };

    handleFocusOnSubmission = (submission?: Submission) => {
        this.props.focusOnSubmission(submission);
    };

    handlePublishSubmission = (submission?: Submission) => {
        return this.props.publishSubmission(submission);
    };

    render() {
        return (
            <Page
                title={`Submissions —  ${this.props.selectedLeaderboard.metadata.longName} Leaderboard.`}
                description={`Ranked list of submissions for the ${this.props.selectedLeaderboard.metadata.longName} Leaderboard.`}>
                <Submissions
                    onGetSubmissions={this.handleGetSubmissions}
                    onFocusOnSubmission={this.handleFocusOnSubmission}
                    onPublishSubmission={this.handlePublishSubmission}
                    publicSubmissions={this.props.publicSubmissions}
                    privateSubmissions={this.props.privateSubmissions}
                    focusedSubmission={this.props.focusedSubmission}
                    selectedUser={this.props.selectedUser}
                    isPublicListRequesting={this.props.isPublicListRequesting}
                    isPrivateListRequesting={this.props.isPrivateListRequesting}
                    config={this.props.config}
                    selectedLeaderboard={this.props.selectedLeaderboard}
                    onUpdate={this.props.update}
                    {...this.state}
                />
            </Page>
        );
    }
}

const mapStateToProps = (state: any) => {
    const userState: UserState = state.user;
    const submissionsState: SubmissionsState = state.submissions;
    return {
        publicSubmissions: submissionsState.publicSubmissions,
        privateSubmissions: submissionsState.privateSubmissions,
        focusedSubmission: submissionsState.focusedSubmission,
        selectedUser: userState.selectedUser,
        isPublicListRequesting: submissionsState.isPublicListRequesting,
        isPrivateListRequesting: submissionsState.isPrivateListRequesting,
    };
};

type DispatchInput =
    | ReturnType<typeof getSubmissions>
    | ReturnType<typeof focusOnSubmission>
    | ReturnType<typeof publishSubmission>
    | ReturnType<typeof update>;
const mapDispatchToProps = (dispatch: (d: DispatchInput) => void) => {
    return {
        getSubmissions: (userId?: string) => dispatch(getSubmissions(userId)),
        focusOnSubmission: (submission?: Submission) => dispatch(focusOnSubmission(submission)),
        publishSubmission: (submission?: Submission) => {
            if (!submission) {
                return;
            }
            return dispatch(publishSubmission(submission));
        },
        update: (id: string, patchData: SubmissionPatchSpec) => dispatch(update(id, patchData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithConfig(SubmissionsContainer));
