// TODO: replace these with basic headers from varnish

import styled, { css } from 'styled-components';

const headerBaseStyles = css`
    ${({ theme }) => css`
        color: ${theme.color.N9.toString()};
        font-family: ${theme.typography.font.family.headline};
        font-weight: 700;
        margin: 0 0 30px;
    `}
`;

export const H1 = styled.h1`
    ${headerBaseStyles};
    font-size: 2em; /* 32px */
`;

export const H2 = styled.h2`
    ${headerBaseStyles};
    font-size: 1.5em;
    padding: 0 0 15px;
    border-bottom: 1px solid ${(props) => props.theme.color.A1.toString()};
`;

export const H3 = styled.h3`
    ${headerBaseStyles};
    font-size: 1.25em;
`;

export const H4 = styled.h4`
    ${headerBaseStyles};
    font-size: 1em;
`;
