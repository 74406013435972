import React from 'react';
import { connect } from 'react-redux';

import { update, uploadPredictionFile, UserState } from '../../redux';
import { SubmissionPatchSpec, SubmissionPost, User } from '../../types';
import { WithConfig, WithConfigProps } from '../general/WithConfig';
import Page from '../layout/Page';

import CreateSubmission from './CreateSubmission';

interface Props extends WithConfigProps {
    user?: User;

    createSubmission: (newSubmission: SubmissionPost) => Promise<any>;
    uploadPredictionFile: (data: FormData) => Promise<any>;
    update: (id: string, patchData: SubmissionPatchSpec, decamelize?: boolean) => Promise<any>;
}

class CreateSubmissionContainer extends React.PureComponent<Props> {
    render() {
        return (
            <Page
                title={`Create Submission — ${this.props.selectedLeaderboard.metadata.longName}`}
                description={`Create a submission for the ${this.props.selectedLeaderboard.metadata.longName} Leaderboard.`}>
                <CreateSubmission
                    config={this.props.config}
                    selectedLeaderboard={this.props.selectedLeaderboard}
                    defaultEmail={this.props.user ? this.props.user.email : undefined}
                />
            </Page>
        );
    }
}

export default connect(
    (state: any) => {
        const userState: UserState = state.user;
        return {
            user: userState.selectedUser,
        };
    },
    (dispatch: any) => {
        return {
            uploadPredictionFile: (data: FormData) => dispatch(uploadPredictionFile(data)),
            update: (id: string, patchData: SubmissionPatchSpec, decamelize: boolean = true) =>
                dispatch(update(id, patchData, decamelize)),
        };
    }
)(WithConfig(CreateSubmissionContainer));
