import * as Api from '../api/submissions';
import * as MockApi from '../test/api';
import { getIndexFromMockId } from '../test/model';
import { Submission, SubmissionPatchSpec, SubmissionPost } from '../types';
import { haveQueryParam, sortByNumber } from '../util';

import { ErrorAction, ErrorData } from './error.action';

export enum SubmissionsAction {
    CLEAR_PUBLIC_LIST = 'CLEAR_SUBMISSIONS_PUBLIC_LIST',
    CLEAR_PRIVATE_LIST = 'CLEAR_SUBMISSIONS_PRIVATE_LIST',
    REQUEST_PUBLIC_LIST = 'REQUEST_SUBMISSION_PUBLIC_LIST',
    REQUEST_PRIVATE_LIST = 'REQUEST_SUBMISSION_PRIVATE_LIST',
    GET_PUBLIC_LIST = 'GET_PUBLIC_SUBMISSIONS_LIST',
    GET_PRIVATE_LIST = 'GET_PRIVATE_SUBMISSIONS_LIST',
    CLEAR_DETAIL = 'CLEAR_SUBMISSION_DETAIL',
    REQUEST_DETAIL = 'REQUEST_SUBMISSION_DETAIL',
    SELECT_DETAIL = 'SELECT_SUBMISSION_DETAIL',
    FOCUS_DETAIL = 'FOCUS_SUBMISSION_DETAIL',
    CREATE_NEW = 'CREATE_NEW_SUBMISSION',
    UPDATE = 'UPDATE_SUBMISSION',
    README_UPDATE = 'UPDATE_SUBMISSION_README',
    UPLOADING_PREDICTION_FILE = 'UPLOADING_PREDICTION_FILE_FOR_SUBMISSION',
    UPLOAD_PREDICTION_FILE = 'UPLOAD_PREDICTION_FILE_FOR_SUBMISSION',
}

export interface SubmissionsData {
    type: SubmissionsAction;
    submissions?: Submission[];
    focusedSubmission?: Submission;
    submissionDetails?: Submission;
    readme?: string;
    submissionId?: string;
    submissionPatchData?: SubmissionPatchSpec;
    uploadPredictionsDatasetId?: string;
}

// When developing locally (with node), then add ?mockapi to a URL to use the mocked data instead of the real API
function useMockApi() {
    return (
        process.env.NODE_ENV === 'development' && // This is set in UIDockerfile
        haveQueryParam(window.location.search, 'mockapi')
    );
}

export function getSubmissions(userId?: string, clearList: boolean = true) {
    return (dispatch: (sb: SubmissionsData) => void, getState: (...args: any[]) => any) => {
        if (clearList) {
            dispatch({
                type: userId
                    ? SubmissionsAction.REQUEST_PRIVATE_LIST
                    : SubmissionsAction.REQUEST_PUBLIC_LIST,
            });
            // Clear the existing list to hide stale results.
            dispatch({
                type: userId
                    ? SubmissionsAction.CLEAR_PRIVATE_LIST
                    : SubmissionsAction.CLEAR_PUBLIC_LIST,
            });
        }

        const leaderboard = getState().config.selectedLeaderboard;

        if (userId) {
            const userRequestFunc = useMockApi()
                ? MockApi.getAllMockSubmissions(leaderboard)
                : Api.getUserSubmissions(leaderboard.id);
            return userRequestFunc.then(({ data: submissions }) => {
                return dispatch({
                    type: SubmissionsAction.GET_PRIVATE_LIST,
                    submissions: sortByNumber(submissions, (s: Submission) => s.rank || Infinity),
                });
            });
        } else {
            const allRequestFunc = useMockApi()
                ? MockApi.getPublishedMockSubmissions(leaderboard)
                : Api.getSubmissions(leaderboard.id);
            return allRequestFunc.then(({ data: submissions }) => {
                return dispatch({
                    type: SubmissionsAction.GET_PUBLIC_LIST,
                    submissions: sortByNumber(submissions, (s: Submission) => s.rank || Infinity),
                });
            });
        }
    };
}

export function getSubmissionDetails(id: string) {
    return (
        dispatch: (d: SubmissionsData | ErrorData) => void,
        getState: (...args: any) => any
    ) => {
        dispatch({
            type: SubmissionsAction.REQUEST_DETAIL,
        });

        // if we are on a dev machine and set query string = debug, then mock the data functions
        const lb = getState().config.selectedLeaderboard;
        let requestFunc = Api.getSubmissionDetails(lb.id, id);
        if (useMockApi()) {
            requestFunc = MockApi.getMockSubmissionDetails(lb, getIndexFromMockId(id));
        }

        return requestFunc
            .then(({ data: submissionDetails }) => {
                return dispatch({
                    type: SubmissionsAction.SELECT_DETAIL,
                    submissionDetails,
                });
            })
            .catch((err) => {
                dispatch({
                    type: ErrorAction.API_ERROR,
                    error: err,
                    message: `Unable to get submission or readme of id: ${id}`,
                });
                dispatch({
                    type: SubmissionsAction.CLEAR_DETAIL,
                });
                throw err;
            });
    };
}

export function update(id: string, patchData: SubmissionPatchSpec, decamelize: boolean = true) {
    return (_: any, getState: (...args: any[]) => any) => {
        const lb = getState().config.selectedLeaderboard;
        return Api.patchSubmission(lb.id, id, patchData, decamelize).then(() => {
            // We used to dispatch an action here that told the UI to update, as the submissions list
            // should've changed. That said a bug in the UI prevents things from updating properly,
            // so instead we just reload the page.
            // See: https://github.com/allenai/leaderboard/issues/1024
            window.location.reload();
        });
    };
}

export function uploadPredictionFile(data: FormData) {
    return (dispatch: (sd: SubmissionsData) => void, getState: (...args: any[]) => any) => {
        dispatch({
            type: SubmissionsAction.UPLOADING_PREDICTION_FILE,
        });
        const leaderboardId = getState().config.selectedLeaderboard.id;
        return (
            Api.uploadPredictionsFile(leaderboardId, data)
                .then((response) => {
                    return dispatch({
                        type: SubmissionsAction.UPLOAD_PREDICTION_FILE,
                        uploadPredictionsDatasetId: response.headers.dataset,
                    });
                })
                // on error, stop progress and set datasetId to undefined
                .catch((err) => {
                    dispatch({
                        type: SubmissionsAction.UPLOAD_PREDICTION_FILE,
                        uploadPredictionsDatasetId: undefined,
                    });
                    throw err;
                })
        );
    };
}

export function createSubmission(submissionData: SubmissionPost) {
    return (dispatch: (sd: SubmissionsData) => void, getState: (...args: any[]) => any) => {
        const leaderboard = getState().config.selectedLeaderboard;
        return Api.createSubmission(leaderboard, submissionData).then(() => {
            return dispatch({
                type: SubmissionsAction.CREATE_NEW,
            });
        });
    };
}

export function publishSubmission(submissionDetails: Submission) {
    return () => {
        return Api.publishSubmission(submissionDetails.id).then(() => {
            // We used to fetch the submissions again here, as to refresh the state of the
            // submissions list since it should've changed. That said a bug in the UI prevents
            // things from updating properly, so instead we just reload the page.
            // See: https://github.com/allenai/leaderboard/issues/1024
            window.location.reload();
        });
    };
}

export function focusOnSubmission(focusedSubmission?: Submission) {
    return (dispatch: (sd: SubmissionsData) => void) => {
        return dispatch({
            type: SubmissionsAction.FOCUS_DETAIL,
            focusedSubmission,
        });
    };
}
