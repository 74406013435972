import { Footer } from '@allenai/varnish/components';
import React from 'react';
import styled from 'styled-components';

const PageFooter = () => (
    <Footer variant="dark">
        <Wrapper>
            <FooterParagraph>
                To get in touch, email us at{' '}
                <a href="mailto:leaderboard@allenai.org">leaderboard@allenai.org</a>
            </FooterParagraph>
            <FooterParagraph>
                Follow us <a href="https://twitter.com/allen_ai">@allen_ai</a>
                <Divider />
                Subscribe to the <a href="http://eepurl.com/gkTcXT">AI2 Newsletter</a>
            </FooterParagraph>
            <SmallTextParagraph>
                © The Allen Institute for Artificial Intelligence - All Rights Reserved
                <Divider />
                <a href="https://allenai.org/privacy-policy.html">Privacy Policy</a>
                <Divider />
                <a href="https://allenai.org/terms.html">Terms and Conditions</a>
            </SmallTextParagraph>
            <img
                src="/assets/images/logo-avatar-one-color.svg"
                alt="The Allen Institute for Artificial Intelligence"
                width="94.78"
                height="72.06"
            />
        </Wrapper>
    </Footer>
);

export default PageFooter;

const Divider = () => <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 30px;
`;

const FooterParagraph = styled.p`
    margin-top: 0;

    && {
        text-align: center;
    }
`;

const SmallTextParagraph = styled(FooterParagraph)`
    font-size: 0.9375em;
`;
