import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { Leaderboard } from '../../leaderboards';
import { Submission } from '../../types';
import { EmptyRouteAwareComponentProps } from '../../util';

import { CompactLadder } from './CompactLadder';

interface Props {
    submissions?: Submission[];
    selectedLeaderboard: Leaderboard;
    published: boolean; // only ladders with published submissions get a download button
    className?: string;
}

class LadderButtonsInternal extends React.Component<EmptyRouteAwareComponentProps<Props>> {
    render() {
        if (
            !this.props.published &&
            !this.props.selectedLeaderboard.metadata.showCompactTableButton
        ) {
            return null;
        }

        return (
            <Container className={this.props.className}>
                {this.props.published ? (
                    <LinkWithButtonStyle
                        size="small"
                        download
                        href={`/api/leaderboards/${this.props.selectedLeaderboard.id}/submissions/published?format=csv`}>
                        <DownloadOutlined />
                        Download
                    </LinkWithButtonStyle>
                ) : null}
                {this.props.selectedLeaderboard.metadata.showCompactTableButton ? (
                    <CompactLadder
                        submissions={this.props.submissions}
                        selectedLeaderboard={this.props.selectedLeaderboard}
                    />
                ) : null}
            </Container>
        );
    }
}

const LinkWithButtonStyle = styled(Button)`
    && {
        color: ${({ theme }) => theme.color.N9};
    }
`;

const Container = styled.span`
    display: inline-block;
`;

export const LadderButtons = styled(withRouter(LadderButtonsInternal))``;
