import * as React from 'react';

import { getEvents } from '../../../api/events';
import { Config } from '../../../types';
import Page from '../../layout/Page';

import AdminHome from './AdminHome';

enum LoadState {
    INITIAL,
    LOADING,
    LOADED,
    FAILED,
}

interface State {
    loadState: LoadState;
    loadMessage: string;
}

interface Props {
    config: Config;
}

class AdminHomeContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loadState: LoadState.INITIAL,
            loadMessage: 'Loading',
        };
    }

    componentDidMount() {
        this.startLoading();
    }

    startLoading() {
        this.setState({
            loadState: LoadState.LOADING,
            loadMessage: 'Loading',
        });

        // We don't want any events for this page, but we do want to attempt the
        // API call (requesting at most 1 event), so that it fails if the user
        // isn't an admin.
        const limit = 1;

        getEvents(limit)
            .then(() => {
                this.setState({
                    loadState: LoadState.LOADED,
                    loadMessage: '',
                });
            })
            .catch((err) => {
                if (err.response.status >= 400 && err.response.status <= 499) {
                    this.setState({
                        loadState: LoadState.FAILED,
                        loadMessage:
                            '4xx response from server. Either this is not found, or you must be authenticated and authorized.',
                    });
                    return;
                }

                this.setState({
                    loadState: LoadState.FAILED,
                    loadMessage: 'Unexpected error.',
                });
            });
    }

    render() {
        if (this.state.loadState !== LoadState.LOADED) {
            return (
                <Page>
                    <span>{this.state.loadMessage}</span>
                </Page>
            );
        }

        return (
            <Page>
                <AdminHome config={this.props.config} />
            </Page>
        );
    }
}

export default AdminHomeContainer;
