import React from 'react';
import styled from 'styled-components';

interface Props {
    className?: string;
    size?: string;
}

export const CircleSpinnerSVG: React.FunctionComponent<Props> = ({ className, size }) => {
    return (
        <SpinnerSVG
            className={className}
            version="1.1"
            x="0px"
            y="0px"
            width={size}
            height={size}
            viewBox="0 0 50 50"
            xmlSpace="preserve">
            <path
                fill="#000"
                d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 25 25"
                    to="360 25 25"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            </path>
        </SpinnerSVG>
    );
};

const SpinnerSVG = styled.svg`
    path,
    rect {
        fill: ${(props) => props.theme.color.O6.toString()};
    }
`;

CircleSpinnerSVG.defaultProps = {
    size: '40px',
};
