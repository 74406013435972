import { combineReducers } from 'redux';

import config from './config.reducer';
import error from './error.reducer';
import submissions from './submissions.reducer';
import user from './user.reducer';

export const reducers = combineReducers({
    config,
    user,
    submissions,
    error,
});

export * from './reducer-types';
export * from './config.action';
export * from './config.reducer';
export * from './error.action';
export * from './error.reducer';
export * from './submissions.action';
export * from './submissions.reducer';
export * from './user.action';
export * from './user.reducer';
