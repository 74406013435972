import { Theme } from '@allenai/varnish/theme';
import { escape } from 'lodash';
import React from 'react';
import { withRouter } from 'react-router';

import { ChartCategory, Leaderboard, MetricChartConfig } from '../../leaderboards';
import { Submission } from '../../types';
import {
    ellipsesify,
    EmptyRouteAwareComponentProps,
    formatScore,
    getMerticByKey,
    getMerticScoreByKey,
    getScoreCompareValue,
} from '../../util';

import {
    height,
    hoverlabel,
    legend,
    margin,
    markerSize,
    mutedAlpha,
    NoSubs,
    TopSectionHeader,
    xaxis,
    yaxis,
} from './Charts';
import Plot from './Plot';

/* We source Plotly from it's CDN, since building it into our application
   bundle causes our build to run out of heap space when ran in Google
   Cloud Build. */
declare let Plotly: any;

interface Props {
    className?: string;
    submissions: Submission[];
    focusedSubmission?: Submission;
    selectedLeaderboard: Leaderboard;
    metricChart: MetricChartConfig;

    onFocusOnSubmission: (submission?: Submission) => void;
}

const MetricChart: React.FunctionComponent<EmptyRouteAwareComponentProps<Props>> = (props) => {
    const data: {}[] = [];

    // we only chart working submissions
    const workingSubmissions = props.submissions
        ? props.submissions.filter((s) => s.blindScore !== undefined)
        : undefined;

    if (workingSubmissions) {
        const sortedAndFiltered = workingSubmissions
            .sort((s1, s2) => {
                const a = getScoreCompareValue(
                    s1,
                    getMerticScoreByKey(props.metricChart.seriesOrderMetric, s1.metricScores),
                    props.selectedLeaderboard
                );
                const b = getScoreCompareValue(
                    s2,
                    getMerticScoreByKey(props.metricChart.seriesOrderMetric, s2.metricScores),
                    props.selectedLeaderboard
                );
                return b - a;
            })
            .slice(0, props.metricChart.maxSeriesToShow || Infinity);

        const categories = props.metricChart.categories.map((cat: ChartCategory) => {
            const metric = getMerticByKey(
                cat.metricKey,
                props.selectedLeaderboard.metadata.metrics
            );
            return cat.displayName || metric?.displayName || '';
        });

        type ColorKey = keyof typeof Theme.color;
        const colorKeys: ColorKey[] = [
            'B10',
            'A5',
            'A9',
            'B4',
            'B8',
            'A3',
            'A7',
            'B2',
            'B6',
            'A1',
            'A10',
            'B5',
            'B9',
            'A4',
            'A8',
            'B3',
            'B7',
            'A2',
            'A6',
            'B1',
        ];
        const colors = colorKeys.map((k) => Theme.color[k].toString());
        sortedAndFiltered.forEach((sub, idx) => {
            const valuesPerCategory = props.metricChart.categories.map((cat: ChartCategory) => {
                return getScoreCompareValue(
                    sub,
                    getMerticScoreByKey(cat.metricKey, sub.metricScores),
                    props.selectedLeaderboard
                );
            });
            const series = {
                submission: sub,
                type: props.metricChart.chart.type,
                mode: props.metricChart.chart.mode,
                x: categories,
                y: valuesPerCategory,
                line: {
                    color:
                        !props.focusedSubmission || sub.id === props.focusedSubmission.id
                            ? colors[idx % colors.length]
                            : colors[idx % colors.length] + '55', // 55 is 33% in hex
                    width: props.focusedSubmission && sub.id === props.focusedSubmission.id ? 4 : 2,
                },
                marker: {
                    size: markerSize,
                    symbol: idx % 25,
                    color: colors[idx % colors.length],
                    opacity:
                        !props.focusedSubmission || sub.id === props.focusedSubmission.id
                            ? 1
                            : mutedAlpha,
                    line: {
                        color: Theme.color.N9.toString(),
                        width:
                            props.focusedSubmission && sub.id === props.focusedSubmission.id
                                ? 3
                                : 1,
                    },
                },
                name: ellipsesify(escape(sub.name), 13),
                hovertemplate:
                    `<b>${ellipsesify(escape(sub.name), 26)}</b><br><br>` +
                    props.metricChart.categories
                        .map((_: ChartCategory, i: number) => {
                            return `${categories[i]}: <b>${formatScore(
                                valuesPerCategory[i],
                                props.selectedLeaderboard.metadata.metricPrecision
                            )}</b><br>`;
                        })
                        .join('') +
                    '<extra></extra>',
            };

            data.push(series);
        });
    }

    const getSubmission = (event: any) => {
        if (event.points && event.points.length) {
            return event.points[0].data.submission;
        }
        return undefined;
    };

    const layout = {
        legend,
        yaxis: {
            ...yaxis,
            title: props.metricChart.chart.layout?.yaxis?.title || 'Score',
            range: props.metricChart.chart.layout?.yaxis?.range,
        },
        xaxis: {
            ...xaxis,
            title: props.metricChart.chart.layout?.xaxis?.title || 'Metric',
        },
        showlegend: props.metricChart.chart.layout?.showlegend,
        hovermode: 'closest',
        hoverlabel,
        height,
        margin,
    };

    const config = { displayModeBar: false, responsive: true };

    return (
        <div className={props.className}>
            <TopSectionHeader>{props.metricChart.caption}</TopSectionHeader>
            <section>
                {!workingSubmissions || !workingSubmissions.length ? (
                    <NoSubs>No Submissions yet</NoSubs>
                ) : (
                    <Plot
                        data={data}
                        layout={layout}
                        config={config}
                        onMouseOverPoint={(event) => {
                            /* Adapted hack for changing the cursor in Plotly:
                   @see https://codepen.io/etpinard/pen/ZpyRxr */
                            Plotly.d3
                                .select('g.draglayer')
                                .selectAll('rect')
                                .style('cursor', 'pointer');
                            props.onFocusOnSubmission(getSubmission(event));
                        }}
                        onMouseOutPoint={() => {
                            Plotly.d3
                                .select('g.draglayer')
                                .selectAll('rect')
                                .style('cursor', 'default');
                            props.onFocusOnSubmission();
                        }}
                        onClick={(event) => {
                            const submissionId = getSubmission(event).id;
                            const detailsUrl = `/${props.selectedLeaderboard.id}/submission/${submissionId}`;
                            props.history.push(detailsUrl);
                        }}
                    />
                )}
            </section>
        </div>
    );
};

export default withRouter(MetricChart);
