import styled from 'styled-components';
import { Tooltip } from 'antd';
import React from 'react';

import { AlertCircle } from 'react-feather';

import { EyeInvisibleOutlined } from '@ant-design/icons';

import { PublishStatus, Submission, SubmissionStatus } from '../../types';
import { Leaderboard } from '../../leaderboards';
import { CircleSpinnerSVG } from '../general/CircleSpinnerSVG';

// This function may return an element that shows an icon to represent a hidden score. For example, a
// pending submission will have a spinner instead of the score, etc. If the score isn't hidden, then
// this returns null so that the caller can decide how to best render the score.
export const renderScoreIcon = (record: Submission, leaderboard: Leaderboard) => {
    if (record.blindStatus === SubmissionStatus.Failed) {
        return (
            <Tooltip title="This evaluation of this submission failed, so there are no scores. For help determining why things failed, send a note to leaderboard@allenai.org.">
                <CenteredIcon>
                    <Red>
                        <AlertCircle size={16} />
                    </Red>
                </CenteredIcon>
            </Tooltip>
        );
    }

    if (record.blindStatus === SubmissionStatus.Pending) {
        return (
            <Tooltip title="Evaluation in progress. Please reload this page to update the status.">
                <CenteredIcon>
                    <CircleSpinnerSVG size="16" />
                </CenteredIcon>
            </Tooltip>
        );
    }

    // If we got here, then record.blindStatus === SubmissionStatus.Succeeded

    // In this case, the successful submission's score can be rendered.
    if (record.publishStatus === PublishStatus.Published || leaderboard.showUnpublishedScores) {
        return null;
    }

    // Otherwise, the score is hidden from view.
    return (
        <Tooltip title="Your predictions have been scored successfully, but the score is hidden. Publish the submission to reveal it.">
            <CenteredIcon>
                <EyeInvisibleOutlined />
            </CenteredIcon>
        </Tooltip>
    );
};

const Red = styled.span`
    color: ${(props) => props.theme.color.R8};
`;

const CenteredIcon = styled.div`
    display: grid;
    justify-content: center;
    height: 1.4rem;
`;
