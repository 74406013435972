import { isEmpty } from 'lodash';
import React from 'react';
import { CheckCircle, Edit, XCircle } from 'react-feather';
import styled from 'styled-components';

import { None } from './None';
import { SmallSpinner } from './SmallSpinner';
import {
    EditableProps,
    EditButton,
    ErrorMessage,
    Row,
    withEditableContainer,
} from './WithEditableContainer';

class EditableTextComponent extends React.Component<EditableProps> {
    public ref: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
    render() {
        const props = this.props;

        // Move cursor to the end of the input.
        const moveCursorToEnd = (event: any) => {
            const value = event.target.value;
            event.target.value = '';
            event.target.value = value;
        };

        if (props.isEditing) {
            return (
                <EditContent>
                    <Row>
                        <Input
                            ref={this.ref}
                            type={props.inputType || 'text'}
                            value={props.draftText}
                            disabled={props.isRequesting}
                            onChange={props.onChange}
                            autoFocus
                            onFocus={moveCursorToEnd}
                            onKeyDown={(e) => {
                                if (props.onKeyDown && this.ref.current) {
                                    props.onKeyDown(e, this.ref.current);
                                }
                            }}
                            required={props.required}
                        />

                        {props.isRequesting ? (
                            <SmallSpinner />
                        ) : (
                            <>
                                <SaveButton
                                    onClick={(e) => {
                                        if (props.onSubmit) {
                                            props.onSubmit(e, this.ref.current || undefined);
                                        }
                                    }}>
                                    <CheckCircle size={15} />
                                </SaveButton>
                                <DelButton onClick={props.onCancel}>
                                    <XCircle size={15} />
                                </DelButton>
                            </>
                        )}
                    </Row>

                    <Row>
                        {!isEmpty(props.errorMessage) && (
                            <ErrorMessage>{props.errorMessage}</ErrorMessage>
                        )}
                    </Row>
                </EditContent>
            );
        }

        return (
            <Row>
                {props.text ? (
                    isEmpty(props.text) ? (
                        <None />
                    ) : props.inputType === 'number' ? (
                        parseInt(props.text, 10).toLocaleString('en-US')
                    ) : (
                        props.text
                    )
                ) : null}
                <EditButton onClick={props.onEdit}>
                    <Edit size={20} />
                </EditButton>
            </Row>
        );
    }
}

const Input = styled.input`
    font-size: 0.9375rem; /* 15px; Use rem to account for variable container. */
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
    margin: 0 10px 0 0;
    width: 100%;
    display: block;
    color: ${(props) => props.theme.color.N9.toString()};
    outline: 0;
    border: 1px solid ${(props) => props.theme.color.A1.toString()};
`;

const SaveButton = styled.button`
    height: 19px;
    font-size: 0.9375rem;
    background: none;
    border: none;
    cursor: pointer;
    color: ${(props) => props.theme.color.B9.toString()};
    svg {
        display: block;
        margin-right: 5px;

        &:only-child {
            margin: 0;
        }
    }
    :hover {
        color: ${(props) => props.theme.color.A8.toString()};
        text-decoration: underline;
    }
`;

const DelButton = styled(SaveButton)`
    :hover {
        color: ${(props) => props.theme.color.R8.toString()};
    }
`;

export const EditContent = styled.div`
    white-space: nowrap;
`;

export const EditableText = styled(withEditableContainer(EditableTextComponent))``;
