import styled from 'styled-components';

/**
 * A component that constraints the max-width of it's content. This should be used for text and
 * other content that shouldn't use all of the page's horizontal real estate.
 */
const MaxWidth = styled.div`
    max-width: ${({ theme }) => theme.breakpoints.xl};
`;

export default MaxWidth;
