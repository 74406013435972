import React from 'react';

import humps from 'humps';
import styled from 'styled-components';

import { Leaderboard } from '../../../leaderboards';
import { MetricScore, Submission } from '../../../types';
import { Dictionary, formatScore } from '../../../util';
import { renderScoreIcon } from '../HiddenScore';

export interface MetricsRendererSubAndSuperProps {
    submission: Submission;
    selectedLeaderboard: Leaderboard;
    metricMaxNumbers: Dictionary<number>;

    score: MetricScore;
    upper: MetricScore;
    lower: MetricScore;
    showSigns: boolean;
}

// A renderer that shows a score with additional superscript and subscript values. In
// ASCII form:
//
//         +2.4      <-- upper (+ sign is optional)
//  84.7             <-- score
//         -1.3      <-- lower (- sign is optional)
//
export function MetricsRendererSubAndSuper(props: MetricsRendererSubAndSuperProps) {
    const scoreIcon = renderScoreIcon(props.submission, props.selectedLeaderboard);
    if (scoreIcon !== null) {
        return scoreIcon;
    }

    if (props.score === undefined || props.upper === undefined || props.lower === undefined) {
        return null;
    }

    const scoreMetricKey = humps.camelize(props.score.metricKey);
    const upperMetricKey = humps.camelize(props.upper.metricKey);
    const lowerMetricKey = humps.camelize(props.lower.metricKey);

    const scoreMetricScore = props.submission.metricScores[scoreMetricKey];
    const upperMetricScore = props.submission.metricScores[upperMetricKey];
    const lowerMetricScore = props.submission.metricScores[lowerMetricKey];

    if (
        scoreMetricScore === undefined ||
        upperMetricScore === undefined ||
        lowerMetricScore === undefined
    ) {
        return null;
    }

    const score = scoreMetricScore.blindScore;
    const upper = upperMetricScore.blindScore;
    const lower = lowerMetricScore.blindScore;

    if (score === undefined || upper === undefined || lower === undefined) {
        return null;
    }

    const isTopScore = score >= props.metricMaxNumbers[scoreMetricKey];

    return (
        <Score isTopScore={isTopScore}>
            <ScoreStack
                score={formatScore(score, props.selectedLeaderboard.metadata.metricPrecision)}
                upper={formatScore(upper, props.selectedLeaderboard.metadata.metricPrecision)}
                lower={formatScore(lower, props.selectedLeaderboard.metadata.metricPrecision)}
                showSigns={props.showSigns}
            />
        </Score>
    );
}

const Score = styled.span<{ isTopScore: boolean }>`
    font-weight: ${({ isTopScore }) => (isTopScore ? 'bold' : 'normal')};
`;

const ScoreStack = (props: {
    score: string;
    upper: string;
    lower: string;
    showSigns: boolean;
}): JSX.Element => {
    return (
        <ScoreStackGrid>
            <ScoreStackScore>{props.score}</ScoreStackScore>
            <ScoreStackUpper>{`${props.showSigns ? '+' : ''}${props.upper}`}</ScoreStackUpper>
            <ScoreStackLower>{`${props.showSigns ? '-' : ''}${props.lower}`}</ScoreStackLower>
        </ScoreStackGrid>
    );
};

const ScoreStackGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        'SCORE UPPER'
        'SCORE LOWER';
`;

const ScoreStackScore = styled.span`
    grid-area: SCORE;
    text-align: right;
    align-self: center;
`;

const ScoreStackUpper = styled.span`
    grid-area: UPPER;
    text-align: left;
    font-size: ${(props) => props.theme.typography.textStyles.micro.fontSize};
`;

const ScoreStackLower = styled.span`
    grid-area: LOWER;
    text-align: left;
    font-size: ${(props) => props.theme.typography.textStyles.micro.fontSize};
`;
