import * as Api from '../api/user';
import { User } from '../types';
import { logoutAndReload } from '../util';

import { handleAPIError } from './error.action';

export enum UserAction {
    SELECTED = 'USER_SELECTED',
    WHOAMI_REQUESTED = 'WHOAMI_REQUESTED',
    NOT_LOGGED_IN = 'NOT_LOGGED_IN',
}

export interface UserData {
    type: UserAction;
    selectedUser?: User;
}

export function whoAmI(userTokenExists: boolean) {
    return (dispatch: (a: UserData | ReturnType<typeof handleAPIError>) => any) => {
        if (!userTokenExists) {
            return dispatch({
                type: UserAction.NOT_LOGGED_IN,
            });
        }

        dispatch({
            type: UserAction.WHOAMI_REQUESTED,
        });
        Api.getWhoAmI()
            .then((response) => {
                const selectedUser = response.data;

                dispatch({
                    type: UserAction.SELECTED,
                    selectedUser,
                });
            })
            .catch((err) => {
                if (err.response && err.response.status === 403) {
                    // api.whoAmI returns a 403 if the current user token is stale.
                    logoutAndReload();
                    return;
                }
                if (err.response && (err.response.status === 401 || err.response.status === 500)) {
                    // api.whoAmI not found, dont allow login, but still show site
                    return dispatch({
                        type: UserAction.NOT_LOGGED_IN,
                    });
                }
                dispatch(handleAPIError(err, 'Error trying to get user credentials'));
            });
    };
}
