import React from 'react';

import { SmallSpinner } from './SmallSpinner';

import { LoadingContainer, LoadingMsg } from './Shared';

interface WithUpdatingProps {
    isUpdating: boolean;
}

export const WithUpdating = <P extends object>(Component: React.ComponentType<P>) =>
    class WithUpdatingInner extends React.PureComponent<P & WithUpdatingProps> {
        render() {
            const { isUpdating } = this.props;
            return isUpdating ? (
                <LoadingContainer>
                    <LoadingMsg>
                        <SmallSpinner />
                        <div>Loading, please wait...</div>
                    </LoadingMsg>
                </LoadingContainer>
            ) : (
                <Component {...this.props} />
            );
        }
    };
