import { Typography } from 'antd';
import React from 'react';
import { Plus } from 'react-feather';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { below, Breakpoint } from '../../Breakpoint';
import { Leaderboard } from '../../leaderboards';
import { Config, Submission, SubmissionPatchSpec, User } from '../../types';
import { EmptyRouteAwareComponentProps } from '../../util';
import PageHeader from '../general/PageHeader';
import { Button, NavButton } from '../general/Shared';
import { H1 } from '../typography';

import About from './About';
import GettingStarted from './GettingStarted';
import MySubmissions from './MySubmissions';
import PublicSubmissions from './PublicSubmissions';

export interface SubmissionsProps {
    publicSubmissions?: Submission[];
    privateSubmissions?: Submission[];
    focusedSubmission?: Submission;
    selectedUser: User;
    className?: string;
    config: Config;
    selectedLeaderboard: Leaderboard;
    isPublicListRequesting: boolean;
    isPrivateListRequesting: boolean;

    onGetSubmissions: (userId?: string) => void;
    onFocusOnSubmission: (submission?: Submission) => void;
    onPublishSubmission: (submission?: Submission) => Promise<any>;
    onUpdate?: (id: string, patchData: SubmissionPatchSpec) => Promise<any>;
}

class Submissions extends React.PureComponent<EmptyRouteAwareComponentProps<SubmissionsProps>> {
    componentDidMount() {
        this.props.onGetSubmissions();
        if (this.props.selectedUser) {
            this.props.onGetSubmissions(this.props.selectedUser.id);
        }
    }

    componentWillUpdate(prevProps: SubmissionsProps) {
        if (prevProps.selectedLeaderboard.id !== this.props.selectedLeaderboard.id) {
            this.props.onGetSubmissions();
            if (this.props.selectedUser) {
                this.props.onGetSubmissions(this.props.selectedUser.id);
            }
        }
    }

    disallowCreateNewReason(user: User, lb: Leaderboard): string | undefined {
        if (lb.metadata.lockSubmissions) {
            return 'Submissions are temporarily disabled.';
        }
        if (!user) {
            return 'Please log in to create submissions';
        }
    }

    render() {
        const baseURL = `/${this.props.selectedLeaderboard.id}/submission`;
        const disallowCreateNewReason = this.disallowCreateNewReason(
            this.props.selectedUser,
            this.props.selectedLeaderboard
        );
        return (
            <div className={this.props.className}>
                <PageHeader.Grid>
                    <PageHeader.Content>
                        <img
                            src={this.props.selectedLeaderboard.metadata.logo}
                            width="90"
                            height="90"
                            alt={`${this.props.selectedLeaderboard.metadata.longName} Logo`}
                        />
                        <div>
                            <H1 title={this.props.selectedLeaderboard.metadata.longName}>
                                {this.props.selectedLeaderboard.metadata.shortName}
                            </H1>
                            <Typography.Paragraph
                                ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
                                {this.props.selectedLeaderboard.metadata.description}
                            </Typography.Paragraph>
                        </div>
                    </PageHeader.Content>
                    <div>
                        <NewButton>
                            {!disallowCreateNewReason ? (
                                <NavButton to={`${baseURL}/create`}>
                                    <Plus size={16} />
                                    Create Submission
                                </NavButton>
                            ) : (
                                <div data-tip={disallowCreateNewReason}>
                                    <Button disabled={true}>
                                        <Plus size={16} />
                                        Create Submission
                                    </Button>
                                </div>
                            )}
                        </NewButton>
                    </div>
                </PageHeader.Grid>
                <div>
                    <div>
                        <TabArea>
                            <ToggleButton to={`${baseURL}s/public`} activeClassName="selected">
                                Public Submissions
                            </ToggleButton>
                            {this.props.selectedUser ? (
                                <ToggleButton to={`${baseURL}s/private`} activeClassName="selected">
                                    My Submissions
                                </ToggleButton>
                            ) : null}
                            <ToggleButton to={`${baseURL}s/get-started`} activeClassName="selected">
                                Getting Started
                            </ToggleButton>
                            <ToggleButton to={`${baseURL}s/about`} activeClassName="selected">
                                About
                            </ToggleButton>
                        </TabArea>
                        <Switch>
                            <Route
                                exact
                                path={`${baseURL}s/public`}
                                render={() => (
                                    <PublicSubmissions
                                        {...this.props}
                                        isUpdating={this.props.isPublicListRequesting}
                                    />
                                )}
                            />
                            {this.props.selectedUser ? (
                                <Route
                                    exact
                                    path={`${baseURL}s/private`}
                                    render={() => (
                                        <MySubmissions
                                            {...this.props}
                                            isUpdating={this.props.isPrivateListRequesting}
                                        />
                                    )}
                                />
                            ) : (
                                <Redirect from={`${baseURL}s/private`} to={`${baseURL}s/public`} />
                            )}
                            <Route
                                exact
                                path={`${baseURL}s/get-started`}
                                render={() => (
                                    <GettingStarted
                                        config={this.props.config}
                                        selectedUser={this.props.selectedUser}
                                        selectedLeaderboard={this.props.selectedLeaderboard}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={`${baseURL}s/about`}
                                render={() => (
                                    <About
                                        config={this.props.config}
                                        selectedLeaderboard={this.props.selectedLeaderboard}
                                    />
                                )}
                            />
                            <Redirect from={`${baseURL}s`} to={`${baseURL}s/public`} />
                        </Switch>
                    </div>
                </div>
                <ReactTooltip multiline={true} />
            </div>
        );
    }
}

export const ChartWrapper = styled.div`
    width: 100%;
`;

export const ChartArea = styled.div<{ columnCount: number }>`
    display: grid;
    gap: ${({ theme }) => theme.spacing.lg};
    margin-top: ${({ theme }) => theme.spacing.md};
    width: 100%;
    grid-template-columns: repeat(${({ columnCount }) => Math.min(3, columnCount)}, 1fr);
    ${({ theme, columnCount }) => (columnCount < 2 ? `max-width: ${theme.breakpoints.xl};` : '')}

    @media ${below(Breakpoint.LARGE)} {
        grid-template-columns: repeat(${({ columnCount }) => Math.min(2, columnCount)}, 1fr);
    }
    @media ${below(Breakpoint.MEDIUM)} {
        grid-template-columns: repeat(${({ columnCount }) => Math.min(1, columnCount)}, 1fr);
    }
`;

const NewButton = styled.div`
    grid-area: button;
    white-space: nowrap;

    @media ${below(Breakpoint.SMALL)} {
        font-size: 0.875em; /* 14px */
    }
`;

const TabArea = styled.div`
    display: flex;
    overflow: auto;
    overflow-y: hidden;
    border-bottom: solid 1px ${(props) => props.theme.color.A1.toString()};
`;

const ToggleButton = styled(NavLink)`
    font-size: 0.9375em; /* 15px */
    color: ${(props) => props.theme.color.A9.toString()};
    font-weight: bold;
    background: transparent;
    padding: 0 0 15px;
    margin: 0 30px -1px 0;
    border: 0;
    border-bottom: 5px solid transparent;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
        color: ${(props) => props.theme.color.A8.toString()};
    }

    &.selected {
        color: ${(props) => props.theme.color.B10.hex};
        border-bottom-color: ${(props) => props.theme.color.A9.toString()};
    }

    @media ${below(Breakpoint.LARGE)} {
        margin-right: 15px;
    }
`;

export default styled(withRouter(Submissions))``;
