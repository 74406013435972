import React from 'react';

import { OlWithSpace } from '../general/Shared';

interface Props {
    name: string;
}

const DefaultRules: React.FunctionComponent<Props> = ({ name }) => (
    <>
        <OlWithSpace>
            <li>
                <strong>Try to solve the {name} dataset</strong>: all submissions must be attempts
                to solve the {name} dataset.
            </li>
            <li>
                <strong>Share how you solved it</strong>: you must share information about how the
                task was solved. For example, you can link to a code repository, an academic paper
                or a blog post.
            </li>
            <li>
                <strong>Share who you are</strong>: you must identify yourself, so others can ask
                you about your approach. Anonymous submissions are not allowed.
            </li>
        </OlWithSpace>
    </>
);

export default DefaultRules;
