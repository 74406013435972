import * as React from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import styled from 'styled-components';

import Page from '../../layout/Page';
import { AdminHeader } from '../AdminHeader';
import { AdminSubHeader } from '../AdminSubHeader';
import { ErrorMessage } from '../../general/Shared';

enum PageState {
    LOADING,
    ERROR,
    ALLOWED,
    NOT_ALLOWED,
    NOT_FOUND,
}

interface ConfigShape {
    evaluator: string;
    primary_metric_key: string;
    yaml: string;
    hidden_from_menu: boolean;
    contact: string;
}

const { TextArea } = Input;

export function AdminEditLeaderboard() {
    // Read the leaderboard ID from the URL, expecting path like "/admin/leaderboard/edit/:id"
    // TODO: try useParams (and/or useRouteMatch) to get this from the routed URL
    const leaderboardID = window.location.pathname.split('/')[4];

    // state for presenting the page
    const [pageState, setPageState] = React.useState(PageState.LOADING);

    // state of the form
    const [form] = Form.useForm();

    // state of submitting the form
    const [submitting, setSubmitting] = React.useState(false);
    const [submitMessage, setSubmitMessage] = React.useState<string | null>(null);

    const initialLoad = React.useCallback(async () => {
        const response = await fetch('/api/v1/admin/leaderboard/' + leaderboardID + '/config');
        if (response.status === 401) {
            setPageState(PageState.NOT_ALLOWED);
            return;
        }
        if (response.status === 404) {
            setPageState(PageState.NOT_FOUND);
            return;
        }
        if (response.status !== 200) {
            setPageState(PageState.ERROR);
            return;
        }
        const config = await (response.json() as Promise<ConfigShape>);
        form.setFieldsValue({
            leaderboard_id: leaderboardID,
            evaluator: config.evaluator,
            primary_metric_key: config.primary_metric_key,
            hidden_from_menu: config.hidden_from_menu,
            yaml: config.yaml,
            contact: config.contact,
        });
        setPageState(PageState.ALLOWED);
    }, [pageState, form]);

    React.useEffect(() => {
        initialLoad();
    }, [initialLoad]);

    function onFinish() {
        setSubmitting(true);
        setSubmitMessage(null);

        const values = form.getFieldsValue();
        console.log('form values are:', values);

        const patch = {} as ConfigShape;
        if (form.isFieldTouched('yaml')) {
            patch.yaml = form.getFieldValue('yaml');
        }
        if (form.isFieldTouched('hidden_from_menu')) {
            console.log('the value of hidden_from_menu is', form.getFieldValue('hidden_from_menu'));
            patch.hidden_from_menu = form.getFieldValue('hidden_from_menu');
        }
        if (form.isFieldTouched('contact')) {
            patch.contact = form.getFieldValue('contact');
        }

        fetch('/api/v1/admin/leaderboard/' + leaderboardID + '/config', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(patch),
        })
            .then(function (response) {
                if (response.status === 200) {
                    window.location.reload();
                    return;
                }

                interface HasMessage {
                    message: string;
                }

                return response.json() as Promise<HasMessage>;
            })
            .then(function (parsed) {
                setSubmitMessage(parsed?.message || null);
            })
            .catch(function (error) {
                setSubmitMessage(error);
            })
            .finally(function () {
                setSubmitting(false);
            });
    }

    if (pageState === PageState.LOADING) {
        return <>Loading...</>;
    }

    if (pageState === PageState.NOT_ALLOWED) {
        return <>Not allowed</>;
    }

    if (pageState === PageState.NOT_FOUND) {
        return <>Not found; bad ID?</>;
    }

    if (pageState === PageState.ERROR) {
        return <>Some error happened.</>;
    }

    return (
        <Page>
            <AdminHeader />
            <AdminSubHeader title="Edit leaderboard">
                Please look at the{' '}
                <a href="https://github.com/allenai/leaderboard">
                    documentation for creating a leaderboard
                </a>{' '}
                before filling out this form.
            </AdminSubHeader>
            <Form
                form={form}
                onFinish={onFinish}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                layout="horizontal"
                size="small">
                <Form.Item label="Leaderboard ID">
                    <Form.Item name="leaderboard_id" rules={[{ required: true }]}>
                        <Input disabled={true} />
                    </Form.Item>
                    <InputExplanation>
                        The Leaderboard ID is the permanent identifier that is part of the URL.
                    </InputExplanation>
                    <InputExplanation>
                        This Leaderboard is currently viewable available at{' '}
                        <a href={'/' + leaderboardID}>{'/' + leaderboardID}</a>.
                    </InputExplanation>
                    <InputExplanation>
                        To change this ID, please contact the ReViz team.
                    </InputExplanation>
                </Form.Item>
                <Form.Item label="Evaluator">
                    <Form.Item name="evaluator" rules={[{ required: true }]}>
                        <Input disabled={true} />
                    </Form.Item>
                    <InputExplanation>
                        The Jetty Evaluator "app" name, chosen from{' '}
                        <a href="https://jetty.apps.allenai.org/admin">
                            https://jetty.apps.allenai.org/admin
                        </a>
                    </InputExplanation>
                    <InputExplanation>
                        This Leaderboard is currently using the Jetty app named{' '}
                        <a
                            href={
                                'https://jetty.apps.allenai.org/admin/apps/' +
                                form.getFieldValue('evaluator')
                            }>
                            {form.getFieldValue('evaluator')}
                        </a>
                        . You can go there to test it out in isolation and to see diagnostic details
                        of previously evaluated submissions.
                    </InputExplanation>
                    <InputExplanation>
                        To change the evaluator for this leaderboard, please contact the ReViz team.
                    </InputExplanation>
                </Form.Item>
                <Form.Item label="Primary Metric">
                    <Form.Item name="primary_metric_key" rules={[{ required: true }]}>
                        <Input disabled={true} />
                    </Form.Item>
                    <InputExplanation>
                        The name of the evaluator metric that will be used for ranking submissions.
                    </InputExplanation>
                    <InputExplanation>
                        To change this, please contact the ReViz team.
                    </InputExplanation>
                </Form.Item>
                <Form.Item label="Hidden from menu">
                    <Form.Item
                        name="hidden_from_menu"
                        rules={[{ required: true }]}
                        valuePropName="checked">
                        <Checkbox>Hidden from menu</Checkbox>
                    </Form.Item>
                    <InputExplanation>
                        If set, then this leaderboard is hidden from the main menu on the site and
                        is accessible by URL only. Keep your leaderboard hidden until you're ready
                        for public submissions. Unhiding a leaderboard effectively makes it publicly
                        visible, and Google will index it soon.
                    </InputExplanation>
                </Form.Item>
                <Form.Item label="AI2 Internal Contact">
                    <Form.Item name="contact" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <InputExplanation>
                        The name and email address of the person at AI2 who is best able to answer
                        questions from submitters to this Leaderboard.
                    </InputExplanation>
                </Form.Item>
                <Form.Item label="YAML config">
                    <Form.Item name="yaml" rules={[{ required: true }]}>
                        <TextAreaMonoFont rows={20} />
                    </Form.Item>
                    <InputExplanation>
                        This is the YAML configuration for this Leaderboard. For an example of what
                        is required look at{' '}
                        <a href="https://github.com/allenai/leaderboard/blob/main/example_configuration.yaml">
                            this example
                        </a>
                        .
                    </InputExplanation>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                    <Button disabled={submitting} type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 12 }}>
                    {submitting ? <p>Submitting ...</p> : null}
                    {submitMessage != null && submitMessage.length > 0 ? (
                        <>
                            <p>Couldn't submit:</p>
                            <ErrorMessage>{submitMessage}</ErrorMessage>
                        </>
                    ) : null}
                </Form.Item>
            </Form>
        </Page>
    );
}

const InputExplanation = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.md};
`;

const TextAreaMonoFont = styled(TextArea)`
    font-family: monospace;
    font-size: ${({ theme }) => theme.typography.textStyles.small.fontSize};
`;
