import { Entity } from '../types';

// immutably update part or all of an item in an array (if it is in the array)
export function replaceItemWithId<T extends Entity>(
    array: T[],
    itemId: string,
    updates: Partial<T>
): T[] {
    return array.map((item: T) => {
        if (item.id !== itemId) {
            return item;
        }
        return { ...item, ...updates };
    });
}

// Type for the resulting props after a redux action is bound with connect().
export type Connected<F extends (...p: any) => (...ip: any) => any> = {
    (...p: Parameters<F>): ReturnType<ReturnType<F>>;
};
