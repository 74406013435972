import { Header } from '@allenai/varnish/components';
import React from 'react';
import styled from 'styled-components';

import { WithConfig, WithConfigProps } from '../general/WithConfig';
import UserLoginContainer from '../user/UserLoginContainer';

interface Props extends WithConfigProps {
    className?: string;
}

interface State {
    isMobileNavVisible: boolean;
    isCollapsed: boolean;
}

class TopBar extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { isMobileNavVisible: false, isCollapsed: false };
    }

    render() {
        return (
            <Header>
                <Header.Columns columns="auto 1fr auto">
                    <Header.Logo href={'/'} label={<Header.AppName>Leaderboards</Header.AppName>}>
                        <img
                            src="/assets/images/logo.svg"
                            width="48"
                            height="48"
                            alt="Leaderboard Logo"
                        />
                    </Header.Logo>
                    <span>
                        {window.document.location.hostname !== 'leaderboard.allenai.org' ? (
                            <Banner>
                                This is a development environment of the AI2 Leaderboards for
                                testing. Scores are not real. Please go to the{' '}
                                <a href="https://leaderboard.allenai.org">
                                    production AI2 leaderboards website
                                </a>{' '}
                                .
                            </Banner>
                        ) : null}
                    </span>
                    <UserLoginContainer />
                </Header.Columns>
            </Header>
        );
    }
}

const Banner = styled.div`
    padding: ${({ theme }) => theme.spacing.xs2};
    height: 3rem;
    overflow: auto;
    margin-left: ${({ theme }) => theme.spacing.md};

    border-color: ${({ theme }) => theme.color.R8};
    border-width: 2px;
    border-style: solid;

    color: ${({ theme }) => theme.color.R10};
    background-color: ${({ theme }) => theme.color.O6};

    font-weight: bold;
    font-size: ${({ theme }) => theme.typography.textStyles.micro.fontSize};
`;

export default styled(WithConfig(TopBar))``;
