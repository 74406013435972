import * as React from 'react';

import styled from 'styled-components';

import { H2 } from '../typography';

interface Props {
    title: string;
    children?: JSX.Element[] | React.ReactNode;
}

export const AdminSubHeader: React.FunctionComponent<Props> = (props) => (
    <>
        <H2LessPadding>{props.title}</H2LessPadding>
        {props.children ? <AdminPageNote>{props.children}</AdminPageNote> : null}
    </>
);

const H2LessPadding = styled(H2)`
    margin-bottom: 0;
    border-bottom: none;
`;

const AdminPageNote = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.lg};
`;
