import React from "react";

export interface HiddenEmailProps {
    email: string
    hidden: boolean
}

export class HiddenEmail extends React.Component<HiddenEmailProps> {
    render() {
        if (this.props.hidden) {
            return <span>Email address hidden</span>;
        }
        return <span>{this.props.email}</span>;
    }
}
