import { Layout } from '@allenai/varnish/components';
import React from 'react';
import styled from 'styled-components';

import { MetaTags } from '../general/MetaTags';

const { Content } = Layout;

interface Props {
    title?: string;
    description?: string;
    children: JSX.Element[] | React.ReactNode;
    className?: string;
}

// outer base page for all pages
const Page: React.FunctionComponent<Props> = (props) => {
    return (
        <Content className={props.className}>
            {props.title || props.description ? (
                <MetaTags title={props.title} description={props.description} />
            ) : null}
            {props.children}
        </Content>
    );
};

export default styled(Page)`
    padding: 30px 30px 60px;
`;
