import React from 'react';
import { connect } from 'react-redux';

import { getSubmissionDetails, SubmissionsState, update, UserState } from '../../redux';
import { Submission, SubmissionPatchSpec, User } from '../../types';
import { RouteAwareComponentProps, Connected } from '../../util';
import { WithConfig, WithConfigProps } from '../general/WithConfig';
import Page from '../layout/Page';

import SubmissionDetails from './SubmissionDetails';

interface MatchParams {
    id: string;
}

interface Props extends WithConfigProps {
    submissionDetails: Submission;
    isDetailsRequesting: boolean;
    user: User;

    getSubmissionDetails: Connected<typeof getSubmissionDetails>;
    update: Connected<typeof update>;
}

class SubmissionDetailsContainer extends React.PureComponent<
    RouteAwareComponentProps<Props, MatchParams>
> {
    constructor(props: RouteAwareComponentProps<Props, MatchParams>) {
        super(props);

        this.handleUpdate = this.handleUpdate.bind(this);
    }

    componentDidMount() {
        this.props.getSubmissionDetails(this.props.match.params.id).catch((error: Error) => {
            console.error(error);
            this.setState({ error });
        });
    }

    handleUpdate(patchData: SubmissionPatchSpec, decamelize: boolean = true) {
        this.props
            .update(this.props.submissionDetails.id, patchData, decamelize)
            .catch((error: Error) => {
                console.error(error);
                this.setState({ error });
            });
    }

    render() {
        return (
            <Page
                title="Submission Details"
                description={
                    this.props.submissionDetails
                        ? `Submission details for ${this.props.submissionDetails.name}`
                        : ''
                }>
                <SubmissionDetails
                    submissionDetails={this.props.submissionDetails}
                    onUpdate={this.handleUpdate}
                    isUpdating={this.props.isDetailsRequesting}
                    config={this.props.config}
                    selectedLeaderboard={this.props.selectedLeaderboard}
                    canEdit={
                        this.props.user &&
                        this.props.submissionDetails &&
                        this.props.submissionDetails.user &&
                        this.props.user.id === this.props.submissionDetails.user.id
                    }
                />
            </Page>
        );
    }
}

export default connect(
    (state: any) => {
        const submissionsState: SubmissionsState = state.submissions;
        const userState: UserState = state.user;
        return {
            submissionDetails: submissionsState.submissionDetails,
            isDetailsRequesting: submissionsState.isDetailsRequesting,
            user: userState.selectedUser,
        };
    },
    (dispatch: any) => {
        return {
            getSubmissionDetails: (id: string) => dispatch(getSubmissionDetails(id)),
            update: (id: string, patchData: SubmissionPatchSpec, decamelize: boolean = true) =>
                dispatch(update(id, patchData, decamelize)),
        };
    }
)(WithConfig(SubmissionDetailsContainer));
