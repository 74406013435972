import * as React from 'react';

import { getEvents, LeaderboardEvent } from '../../../api/events';
import { haveQueryParam } from '../../../util';
import Page from '../../layout/Page';

import AdminEvents from './AdminEvents';

enum LoadState {
    INITIAL,
    LOADING,
    LOADED,
    FAILED,
}

interface State {
    loadState: LoadState;
    loadMessage: string;
    events: LeaderboardEvent[];
    allRequested: boolean;
}

interface Props {}

const numEventsInitially = 100;

class AdminEventsContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loadState: LoadState.INITIAL,
            loadMessage: 'Loading',
            events: [],
            allRequested: false,
        };
    }

    componentDidMount() {
        this.startLoading();
    }

    startLoading() {
        this.setState({
            loadState: LoadState.LOADING,
            loadMessage: 'Loading',
            events: [],
        });
        const allRequested = haveQueryParam(window.location.search, 'all');
        const limit = allRequested ? 0 : numEventsInitially;
        getEvents(limit)
            .then((events) => {
                this.setState({
                    loadState: LoadState.LOADED,
                    loadMessage: '',
                    events: events,
                    allRequested: allRequested || events.length < numEventsInitially,
                });
            })
            .catch((err) => {
                if (err.response.status >= 400 && err.response.status <= 499) {
                    this.setState({
                        loadState: LoadState.FAILED,
                        loadMessage:
                            '4xx response from server. Either this is not found, or you must be authenticated and authorized.',
                    });
                    return;
                }

                this.setState({
                    loadState: LoadState.FAILED,
                    loadMessage: 'Unexpected error.',
                    events: [],
                });
            });
    }

    render() {
        if (this.state.loadState !== LoadState.LOADED) {
            return (
                <Page>
                    <span>{this.state.loadMessage}</span>
                </Page>
            );
        }

        return (
            <Page>
                <AdminEvents
                    events={this.state.events}
                    allRequested={this.state.allRequested}
                    requestAllEvents={() => window.location.assign('/admin/events?all')}
                />
            </Page>
        );
    }
}

export default AdminEventsContainer;
