import { camelizeKeys, transformResponse } from '../util';

import axios from './axios';

/*
 * Uses the backend WhoAmI API to validate the current cookie (if it exists) and retrieve
 * details for the currently logged-in user.
 * The cookie value is automatically added to outgoing requests, so nothing needs to be passed
 * to this function.
 */
export function getWhoAmI() {
    return axios.get('/api/auth/whoami', { transformResponse: transformResponse(camelizeKeys) });
}
