import React from 'react';

import { NavLink } from '../general/Shared';
import { H1 } from '../typography';

import Page from './Page';

interface Props {
    className?: string;
}

const NotFoundPage: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <Page title="Page Not Found" description="Nothing to see here.">
            <section className={props.className}>
                <H1>Page Not Found</H1>
                <p>Sorry, that page doesn't appear to exist.</p>
                <p>
                    <NavLink to="/">Home</NavLink>
                </p>
            </section>
        </Page>
    );
};

export default NotFoundPage;
