import React from 'react';
import { Button, Modal } from 'antd';
import { FullscreenOutlined } from '@ant-design/icons';
import humps from 'humps';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';

import { Leaderboard } from '../../leaderboards';
import { Submission } from '../../types';
import { EmptyRouteAwareComponentProps } from '../../util';

interface Props {
    submissions?: Submission[];
    selectedLeaderboard: Leaderboard;
}

interface State {
    showModal: boolean;
}

class CompactLadderInternal extends React.Component<EmptyRouteAwareComponentProps<Props>, State> {
    constructor(props: EmptyRouteAwareComponentProps<Props>) {
        super(props);

        this.state = {
            showModal: false,
        };
    }

    handleCancel = () => {
        this.setState({ showModal: false });
    };

    render() {
        // there is some kind of race condition issue that is forcing loading tho think its done, before its done, this is a precautionary fix
        if (
            this.props.submissions &&
            this.props.submissions.length &&
            this.props.selectedLeaderboard.id !== this.props.submissions[0].leaderboardId
        ) {
            return null;
        }
        const metricKeys: string[] = [];
        const metricDisplayNames: { [key: string]: string } = {};
        this.props.selectedLeaderboard.metadata.metrics.forEach((m) => {
            metricKeys.push(m.key);
            metricDisplayNames[m.key] = m.displayName;
        });

        return (
            <>
                <Button size="small" onClick={() => this.setState({ showModal: true })}>
                    <FullscreenOutlined />
                    Expand
                </Button>
                <BigModal
                    width="100%"
                    title={`${this.props.selectedLeaderboard.metadata.shortName} Public Submissions`}
                    visible={this.state.showModal}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Close
                        </Button>,
                    ]}
                    onCancel={this.handleCancel}>
                    <SlantedTable>
                        <thead>
                            <tr key="header">
                                <HorizontalHeader key="rank">Rank</HorizontalHeader>
                                <HorizontalHeader key="submission">Submission</HorizontalHeader>
                                {metricKeys.map((m) => {
                                    return (
                                        <SlantedHeader key={m}>
                                            <SlantedOuter>
                                                <SlantedInner>
                                                    <SlantedInner2>
                                                        {metricDisplayNames[m]}
                                                    </SlantedInner2>
                                                </SlantedInner>
                                            </SlantedOuter>
                                        </SlantedHeader>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {(this.props.submissions || []).map((submission) => {
                                return (
                                    <tr key={submission.id}>
                                        <td key={submission.id + 'rank'}>{submission.rank}</td>
                                        <td key={submission.id + 'name'}>{submission.name}</td>
                                        {metricKeys.map((m) => {
                                            const metricValue =
                                                submission.metricScores[humps.camelize(m)];
                                            if (
                                                metricValue &&
                                                metricValue.blindScore !== undefined
                                            ) {
                                                return (
                                                    <td key={submission.id + m}>
                                                        {metricValue.blindScore.toFixed(
                                                            this.props.selectedLeaderboard.metadata
                                                                .metricPrecision || 2
                                                        )}
                                                    </td>
                                                );
                                            } else {
                                                return (
                                                    <td key={submission.id + m}>
                                                        {(0).toFixed(
                                                            this.props.selectedLeaderboard.metadata
                                                                .metricPrecision || 2
                                                        )}
                                                    </td>
                                                );
                                            }
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </SlantedTable>
                </BigModal>
            </>
        );
    }
}

const BigModal = styled(Modal)`
    .ant-modal-body {
        overflow-x: scroll;
    }
`;

const HorizontalHeader = styled.th`
    vertical-align: bottom;
    text-align: center;
    padding: ${(props) => props.theme.spacing.xs};
`;

const SlantedHeader = styled.th`
    height: 140px;
    white-space: nowrap;
`;

const SlantedOuter = styled.div`
    transform: translate(28px, 50px) rotate(315deg);
    width: 30px;
    /* Fix FF issue */
    @supports (-moz-appearance: none) {
        transform: translate(28px, 47px) rotate(315deg);
    }
`;

const SlantedInner = styled.span`
    border-bottom: 1px solid #ccc;
    padding: 7px 10px;
`;

const SlantedInner2 = styled.span`
    margin-left: -1em;
`;

const SlantedTable = styled.table`
    font-size: ${({ theme }) => theme.typography.textStyles.small.fontSize};
    td {
        border: 1px solid #ccc;
        padding: 0.5rem;
    }
`;

export const CompactLadder = styled(withRouter(CompactLadderInternal))``;
