import * as React from 'react';

import { adminGetAllSubmissions } from '../../../api/admin';
import { AdminSubmission, Config } from '../../../types';
import Page from '../../layout/Page';

import AdminAllSubmissions from './AdminAllSubmissions';
import { getStringFromQueryStringOrNull } from '../../../util';

enum LoadState {
    INITIAL,
    LOADING,
    LOADED,
    FAILED,
}

interface State {
    loadState: LoadState;
    loadMessage: string;
    submissions?: AdminSubmission[];
    leaderboardID?: string;
}

interface Props {
    config: Config;
}

class AdminAllSubmissionsContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loadState: LoadState.INITIAL,
            loadMessage: 'Loading',
            leaderboardID:
                getStringFromQueryStringOrNull(window.location.search, 'leaderboard_id') ||
                undefined,
        };
    }

    componentDidMount() {
        this.startLoading();
    }

    startLoading() {
        this.setState({
            loadState: LoadState.LOADING,
            loadMessage: 'Loading',
        });

        adminGetAllSubmissions(this.state.leaderboardID)
            .then((submissions: AdminSubmission[]) => {
                this.setState({
                    loadState: LoadState.LOADED,
                    loadMessage: '',
                    submissions: submissions,
                });
            })
            .catch((err) => {
                if (err.response.status >= 400 && err.response.status <= 499) {
                    this.setState({
                        loadState: LoadState.FAILED,
                        loadMessage:
                            '4xx response from server. Either this is not found, or you must be authenticated and authorized.',
                    });
                    return;
                }

                this.setState({
                    loadState: LoadState.FAILED,
                    loadMessage: 'Unexpected error.',
                });
            });
    }

    render() {
        if (this.state.loadState !== LoadState.LOADED) {
            return (
                <Page>
                    <span>{this.state.loadMessage}</span>
                </Page>
            );
        }

        return (
            <Page>
                <AdminAllSubmissions
                    config={this.props.config}
                    submissions={this.state.submissions || []}
                    leaderboardID={this.state.leaderboardID}
                />
            </Page>
        );
    }
}

export default AdminAllSubmissionsContainer;
