import React from 'react';

import humps from 'humps';
import styled from 'styled-components';

import { Leaderboard } from '../../../leaderboards';
import { MetricScore, Submission } from '../../../types';
import { Dictionary, formatScore } from '../../../util';
import { renderScoreIcon } from '../HiddenScore';

interface MetricsStyleSimpleProps {
    submission: Submission;
    selectedLeaderboard: Leaderboard;
    metricMaxNumbers: Dictionary<number>;

    score: MetricScore;
}

// A renderer that renders a score as a plain value.
export function MetricsRendererSimple(props: MetricsStyleSimpleProps) {
    const scoreIcon = renderScoreIcon(props.submission, props.selectedLeaderboard);
    if (scoreIcon !== null) {
        return scoreIcon;
    }

    if (props.score === undefined) {
        return null;
    }

    const scoreMetricKey = humps.camelize(props.score.metricKey);
    const scoreMetricScore = props.submission.metricScores[scoreMetricKey];
    if (scoreMetricScore === undefined) {
        return null;
    }

    const score = scoreMetricScore.blindScore;
    if (score === undefined) {
        return null;
    }

    const isTopScore = score >= props.metricMaxNumbers[scoreMetricKey];

    return (
        <Score isTopScore={isTopScore}>
            {formatScore(score, props.selectedLeaderboard.metadata.metricPrecision)}
        </Score>
    );
}

// antd applies `overflow-wrap: something`, we reverse this to the browser default, which is `something-else`.
const DontWrap = styled.span`
    overflow-wrap: initial;
`;

const Score = styled(DontWrap)<{ isTopScore: boolean }>`
    font-weight: ${({ isTopScore }) => (isTopScore ? 'bold' : 'normal')};
`;
