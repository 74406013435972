// TODO: move to varnish

import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

interface Props {
    title?: string;
    description?: string;
}

export const MetaTags = (props: Props) => {
    const loc = useLocation();
    const title = props.title || 'AI2 Leaderboard';
    const description =
        props.description ||
        'The AI2 Leaderboard platform hosts public leaderboards for a variety of AI challenge tasks across multiple research domains.';
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="twitter:title" content={title} />
            <meta property="og:title" content={title} />

            <meta name="description" content={description} />
            <meta name="twitter:description" content={description} />
            <meta property="og:description" content={description} />

            <meta
                name="og:url"
                content={`${document.location.origin}${loc.pathname}${loc.search}`}
            />
        </Helmet>
    );
};
