import React from 'react';
import styled from 'styled-components';

import { below, Breakpoint } from '../../Breakpoint';
import { formatScore } from '../../util';
import { WithUpdating } from '../general/WithUpdating';

import { Ladder } from './Ladder';
import MetricChart from './MetricChart';
import RankingChart from './RankingChart';
import { ChartArea, ChartWrapper, SubmissionsProps } from './Submissions';

class PublicSubmissions extends React.PureComponent<SubmissionsProps> {
    render() {
        const humanScores = this.props.selectedLeaderboard.metadata.metrics.filter(
            (m) => m.humanScore !== undefined
        );
        return (
            <>
                {humanScores.length > 0 ? (
                    <HumanPerf>
                        <HumanIcon />
                        <span>Human Performance</span>
                        <HumanPerfScores>
                            {humanScores
                                .sort((a, b) => a.displayOrder - b.displayOrder)
                                .map((metric) => (
                                    <React.Fragment key={metric.key}>
                                        {metric.displayName}:{' '}
                                        {formatScore(
                                            metric.humanScore,
                                            this.props.selectedLeaderboard.metadata.metricPrecision
                                        )}
                                        <br />
                                    </React.Fragment>
                                ))}
                        </HumanPerfScores>
                    </HumanPerf>
                ) : null}
                <Ladder
                    submissions={this.props.publicSubmissions}
                    focusedSubmission={this.props.focusedSubmission}
                    onFocusOnSubmission={this.props.onFocusOnSubmission}
                    config={this.props.config}
                    selectedLeaderboard={this.props.selectedLeaderboard}
                    isPrivate={false}
                />
                <ChartArea
                    columnCount={
                        1 +
                        (this.props.selectedLeaderboard.metadata.metricChartConfigs
                            ? this.props.selectedLeaderboard.metadata.metricChartConfigs.length
                            : 0)
                    }>
                    <ChartWrapper>
                        <RankingChart
                            selectedLeaderboard={this.props.selectedLeaderboard}
                            submissions={this.props.publicSubmissions || []}
                            focusedSubmission={this.props.focusedSubmission}
                            onFocusOnSubmission={this.props.onFocusOnSubmission}
                            minRange={this.props.selectedLeaderboard.rankingChartMin}
                            maxRange={this.props.selectedLeaderboard.rankingChartMax}
                        />
                    </ChartWrapper>
                    {this.props.selectedLeaderboard.metadata.metricChartConfigs
                        ? this.props.selectedLeaderboard.metadata.metricChartConfigs.map(
                              (chart, i) => {
                                  return (
                                      <ChartWrapper key={`${i}_${chart.caption}`}>
                                          <MetricChart
                                              metricChart={chart}
                                              selectedLeaderboard={this.props.selectedLeaderboard}
                                              submissions={this.props.publicSubmissions || []}
                                              focusedSubmission={this.props.focusedSubmission}
                                              onFocusOnSubmission={this.props.onFocusOnSubmission}
                                          />
                                      </ChartWrapper>
                                  );
                              }
                          )
                        : null}
                </ChartArea>
            </>
        );
    }
}

const HumanPerfScores = styled.span`
    white-space: nowrap;
`;

const leftBorderWidth = 8;
const HumanPerf = styled.div`
    background: ${({ theme }) => theme.color.A3};
    border-left: ${leftBorderWidth}px solid ${({ theme }) => theme.color.B6};
    padding: ${({ theme }) => {
        const padding = theme.spacing.md;
        // This reduces the padding by the left border width
        const left = parseFloat(theme.spacing.md) - leftBorderWidth + 'px';
        return [padding, padding, padding, left].join(' ');
    }};
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing.md};
    margin: ${({ theme }) => theme.spacing.lg} 0;
    align-items: center;
    font-weight: 900;

    svg {
        margin-right: ${({ theme }) => theme.spacing.md};
    }

    ${HumanPerfScores} {
        margin-left: auto;
    }

    @media ${below(Breakpoint.MEDIUM)} {
        ${HumanPerfScores} {
            margin-left: ${({ theme }) => parseFloat(theme.spacing.md) + 35}px;
        }
    }
`;

const HumanIcon = () => (
    <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="white" />
        <circle cx="12" cy="8" r="3" fill="#265ED4" />
        <path
            d="M6.5 18H17.5C17.0575 14.5947 14.7635 12 12 12C9.23655 12 6.94252 14.5947 6.5 18Z"
            fill="#265ED4"
        />
    </svg>
);

export default WithUpdating(PublicSubmissions);
