import { Table } from 'antd';
import * as React from 'react';

import styled from 'styled-components';

import { AdminSubmission, Config, PublishStatus } from '../../../types';
import Page from '../../layout/Page';
import { AdminHeader } from '../AdminHeader';
import { AdminSubHeader } from '../AdminSubHeader';
import { Leaderboard } from '../../../leaderboards';
import { HiddenEmail } from "../../general/HiddenEmail";

interface Props {
    config: Config;
    submissions: AdminSubmission[];
    leaderboardID?: string;
}

interface State {
    emailsHidden: boolean;
}

function BoardLinks({ leaderboards }: { leaderboards: Leaderboard[] }) {
    const links: JSX.Element[] = [];
    links.push(<span key="title">Show submissions from </span>);

    leaderboards.sort((a, b) => a.id.localeCompare(b.id));

    for (const lb of leaderboards) {
        links.push(
            <span key={lb.id}>
                <a href={'/admin/submissions?leaderboard_id=' + lb.id}>{lb.id}</a>,{' '}
            </span>
        );
    }
    links.push(
        <span key="or">
            or show submissions in <a href="/admin/submissions">all Leaderboards</a>.
        </span>
    );

    return <p key="links">{links}</p>;
}

function Description({ num, id }: { num: number; id?: string }) {
    if (id) {
        return (
            <>
                Here is a list of all {num} submissions in Leaderboard {id}.
            </>
        );
    }
    return <>Here is a list of all {num} submissions across all Leaderboards.</>;
}

const FilterControl = styled.div`
    padding-left: ${(props) => props.theme.spacing.lg};
`;

export default class AdminAllSubmissions extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            emailsHidden: true,
        };
    }

    render() {
        return (
            <Page>
                <AdminHeader />
                <AdminSubHeader title="Submissions">
                    <FilterControl>
                        <p>
                            <b>Filter by Leaderboard</b>
                        </p>
                        <BoardLinks leaderboards={this.props.config.leaderboards} />
                    </FilterControl>
                    {this.props.submissions ? (
                        <Description
                            num={this.props.submissions.length}
                            id={this.props.leaderboardID}
                        />
                    ) : (
                        <span>0 submissions in Leaderboard {this.props.leaderboardID}.</span>
                    )}
                </AdminSubHeader>
                {this.state.emailsHidden
                    ?
                    (
                        <React.Fragment>
                            <span></span>
                            <span><button onClick={() => { this.revealEmails() }}>Reveal emails...</button> </span>
                        </React.Fragment>
                    )
                    :
                    null
                }
                {this.props.submissions ? (
                    <TableContainer>
                        <Table<AdminSubmission>
                            size="small"
                            rowKey={(record) => record.id}
                            dataSource={this.props.submissions}
                            columns={[
                                {
                                    title: 'ID',
                                    key: 'submissionID',
                                    width: '12em',
                                    render: (sub: AdminSubmission) => {
                                        return (
                                            <small>
                                                <code>{sub.id}</code>
                                            </small>
                                        );
                                    },
                                },
                                {
                                    title: 'Created',
                                    key: 'created',
                                    width: '10em',
                                    render: (sub: AdminSubmission) => {
                                        if (sub.created) {
                                            return <small>{sub.created.fromNow()}</small>;
                                        }
                                        return null;
                                    },
                                },
                                {
                                    title: 'Leaderboard',
                                    key: 'leaderboardId',
                                    width: '15em',
                                    render: (sub: AdminSubmission) => (
                                        <small>{sub.leaderboardId}</small>
                                    ),
                                },
                                {
                                    title: '👁',
                                    key: 'Visibility',
                                    width: '32px',
                                    render: (sub: AdminSubmission) => {
                                        let icon = '🌎';
                                        let title = 'Published';
                                        if (sub.publishStatus === PublishStatus.Unpublished) {
                                            icon = '🤫';
                                            title = 'Unpublished';
                                        }
                                        if (sub.archived) {
                                            title = 'Archived';
                                            icon = '🗑';
                                        }
                                        return <small title={title}>{icon}</small>;
                                    },
                                },
                                {
                                    title: 'Submission',
                                    key: 'id',
                                    ellipsis: true,
                                    width: '250px',
                                    render: (sub: AdminSubmission) => {
                                        return (
                                            <a
                                                title={sub.name}
                                                href={`/admin/submission/${sub.id}`}>
                                                <small>{sub.name}</small>
                                            </a>
                                        );
                                    },
                                },
                                {
                                    title: '🧪',
                                    key: 'experiment',
                                    width: '10em',
                                    render: (sub: AdminSubmission) => (
                                        <small>{evaluatorLink(sub.evaluatorRef, sub.status)}</small>
                                    ),
                                },
                                {
                                    title: '🤓',
                                    key: 'user',
                                    width: '10em',
                                    render: (sub: AdminSubmission) => (
                                        <a
                                            href={
                                                'https://beaker.org/api/v3/users/' +
                                                sub.beakerUserId
                                            }>
                                            <small>{sub.beakerUserId}</small>
                                        </a>
                                    ),
                                },
                                {
                                    title: '✉️',
                                    key: 'email',
                                    render: (sub: AdminSubmission) => {
                                        if (sub.email) {
                                            return <small><HiddenEmail email={sub.email} hidden={this.state.emailsHidden}></HiddenEmail></small>;
                                        }
                                        return null;
                                    },
                                },
                            ]}
                            pagination={false}
                        />
                    </TableContainer>
                ) : null}
            </Page>
        );
    }

    revealEmails() {
        if (confirm("You are about to reveal the email addresses on this page.\n\nDo not share them outside AI2.")) {
            this.setState({emailsHidden: false});
        }
    }
}

// The width here is chosen empirically to look good.
const TableContainer = styled.div`
    min-width: 1500px;
`;

function evaluatorLink(evaluatorRef: string, status: string): JSX.Element {
    if (evaluatorRef.startsWith('https://')) {
        let url = evaluatorRef;
        if (url.startsWith('https://jetty.apps.allenai.org') && url.endsWith('.json')) {
            // remove .json from jetty URLs
            url = url.substr(0, url.length - 5);
        }
        return <a href={url}>{status}</a>;
    }
    return <span title={evaluatorRef}>{status}</span>;
}
