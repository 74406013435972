import { Theme } from '@allenai/varnish/theme';
import React from 'react';
import { AlertCircle, CheckCircle, ChevronsRight } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { SubmissionStatus } from '../../types';

interface Props {
    status: SubmissionStatus;
    href?: string;
    className?: string;
}

const SubmissionStatusWithIcon: React.FunctionComponent<Props> = (props) => {
    let icon = null;
    let color = { color: Theme.color.N9.toString() };
    let explanation = null;
    const iconSize = 16;
    switch (props.status) {
        case SubmissionStatus.Failed: {
            icon = <AlertCircle size={iconSize} />;
            color = { color: Theme.color.R8.toString() };
            explanation = `We don't supply additional information because we want to prevent<br />
         against leaking our blind test dataset, but you may contact us<br />
         if you need help troubleshooting why your submission failed.`;
            break;
        }
        case SubmissionStatus.Succeeded: {
            icon = <CheckCircle size={iconSize} />;
            color = { color: Theme.color.B7.toString() };
            explanation = `Your predictions have been scored successfully. You can now publish your submission.`;
            break;
        }
        case SubmissionStatus.Pending: {
            icon = <ChevronsRight size={iconSize} />;
            explanation = `Please reload this page to update the status.`;
            break;
        }
    }
    const TagName = props.href ? 'a' : 'span';
    return (
        <>
            <TagName
                className={props.className}
                target="_blank"
                href={props.href}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                style={color}
                data-tip={explanation}>
                {icon} <span>Submission {props.status}</span>
                {explanation ? <ReactTooltip multiline={true} /> : null}
            </TagName>
        </>
    );
};

export default styled(SubmissionStatusWithIcon)<Props>`
    cursor: ${(props) => (props.href ? 'pointer' : 'default')};
    width: fit-content;
    display: block;
    svg {
        vertical-align: middle;
        margin-right: 5px;
    }
    a {
        vertical-align: middle;
        display: flex;
    }
`;
