import React from 'react';
import styled from 'styled-components';

const LightText = styled.span`
    color: ${(props) => props.theme.color.N6.toString()};
`;

export function None() {
    return <LightText>None</LightText>;
}
