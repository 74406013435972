import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import { baseLinkStyles } from './Shared';

export const MarkdownReader: React.FunctionComponent<ReactMarkdown.ReactMarkdownProps> = (
    props: ReactMarkdown.ReactMarkdownProps
) => (
    <Markdown>
        <ReactMarkdown {...props} />
    </Markdown>
);

const Markdown = styled.section`
    overflow: auto;

    a {
        ${baseLinkStyles}
    }

    blockquote {
        font-size: 0.875em; /* 14px */
        background-color: ${(props) => props.theme.color.N3.toString()};
        margin: 16px 0 16px 0;
        padding: 16px;
        border-radius: 5px;
        white-space: pre-wrap;
        word-wrap: break-word;
        word-break: break-all;
        hyphens: auto;
    }

    table {
        tr {
            background-color: #fff;
            border-top: 1px solid #c6cbd1;
        }
        th {
            background-color: ${(props) => props.theme.color.N3.toString()};
        }
        th,
        td {
            padding: 6px 13px;
            border: 1px solid #dfe2e5;
        }
    }

    // Varnish's well-designed header sizes are too large for rendering a
    // snippet of markdown embedded in a page. So the header font sizes are
    // reduced to make the rendered markdown look good.
    h1 {
        font-size: ${(props) => props.theme.typography.h4.fontSize};
        margin: ${(props) => props.theme.typography.h4.margin};
        padding: 0;
    }
    h2,
    h3,
    h4,
    h5 {
        font-size: ${(props) => props.theme.typography.h5.fontSize};
        margin: ${(props) => props.theme.typography.h5.margin};
        padding: 0;
    }
`;
