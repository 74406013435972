import { User } from '../types';

import { UserAction, UserData } from './user.action';

export interface UserState {
    isLoggedIn?: boolean;
    isWhoAmIRequesting: boolean;
    selectedUser?: User;
}

const initialState: UserState = {
    isLoggedIn: undefined,
    isWhoAmIRequesting: true,
    selectedUser: undefined,
};

export default function user(state: UserState = initialState, action: UserData) {
    switch (action.type) {
        case UserAction.NOT_LOGGED_IN:
            return {
                ...state,
                isWhoAmIRequesting: false,
                isLoggedIn: false,
                selectedUser: undefined,
            };

        case UserAction.WHOAMI_REQUESTED:
            return {
                ...state,
                isWhoAmIRequesting: true,
            };

        case UserAction.SELECTED:
            return {
                ...state,
                isWhoAmIRequesting: false,
                selectedUser: action.selectedUser,
                isLoggedIn: true,
            };

        default: {
            return state;
        }
    }
}
