import React from 'react';

import { PublishWindowDays } from '../../constants';
import { Leaderboard } from '../../leaderboards';
import { SpacedBr } from '../general/Shared';

interface Props {
    className?: string;
    selectedLeaderboard: Leaderboard;
}

const SubmissionDescription: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <span className={props.className}>
            {props.selectedLeaderboard.disablePublishSpeedBump
                ? null
                : `To protect against overfitting on the blind test dataset you can only publish once every ${PublishWindowDays} days.`}
            <SpacedBr />
            Submissions will be reviewed by AI2's {
                props.selectedLeaderboard.metadata.team.name
            }{' '}
            team to verify that the provided links contain sufficient detail for others to
            understand the technique and, ideally, build upon or validate the results.
        </span>
    );
};

export default SubmissionDescription;
