import React from 'react';

/* We source Plotly from it's CDN, since building it into our application
   bundle causes our build to run out of heap space when ran in Google
   Cloud Build. */
declare let Plotly: any;

interface PlotlyElement extends HTMLDivElement {
    on: (eventName: string, callback: (event: any) => void) => void;
}

interface Props {
    data: Array<{}>;
    layout: {};
    config: {};
    onMouseOverPoint?: (event: any) => void;
    onMouseOutPoint?: (event: any) => void;
    onClick?: (event: any) => void;
}

/**
 * This is a shim allowing us to use Plotly, source via a CDN, as a
 * JavaScript global in a declarative fashion.
 *
 * It was written because we couldn't build Plotly in Google Cloud Build without
 * running out of Heap space. Rather than attempt to resolve this, we opted
 * to simply use a pre-built, CDN hosted version and write a bridge between
 * said global interface and our React application.
 *
 * Future maintenance and/or attempts to expand this logic should likely
 * re-evaluate the underlying charting library, as Plotly appears to be
 * very out of date and doesn't mesh well with React's declaractive
 * style.
 *
 * *NOTE:* This component cannot be a PureComponent, as the render() functoin
 * doesn't fully capture the resulting DOM (and accordingly the vdom diffing
 * algorithm can't do it's job)
 */
export default class Plot extends React.PureComponent<Props> {
    private readonly element: React.RefObject<PlotlyElement>;
    constructor(props: Props) {
        super(props);
        this.element = React.createRef();
    }

    componentWillUnmount() {
        this.cleanup();
    }

    componentDidUpdate() {
        // We redraw the plot with every update. There's likely a better
        // way to do this, but for now it works without any noticeable
        // performance issues, so we'll optimize if / when necessary (or
        // rip out Plotly and replace it with something else entirely).
        this.cleanup();
        this.plot();
    }

    componentDidMount() {
        this.plot();
    }

    cleanup() {
        Plotly.purge(this.element.current);
    }

    plot() {
        if (this.element.current) {
            Plotly.plot(
                this.element.current,
                this.props.data,
                this.props.layout,
                this.props.config
            );
            if (this.props.onMouseOverPoint) {
                this.element.current.on('plotly_hover', this.props.onMouseOverPoint);
            }
            if (this.props.onMouseOutPoint) {
                this.element.current.on('plotly_unhover', this.props.onMouseOutPoint);
            }
            if (this.props.onClick) {
                this.element.current.on('plotly_click', this.props.onClick);
            }
        }
    }

    render() {
        return <div ref={this.element} />;
    }
}
