import styled from 'styled-components';

import { below, Breakpoint } from '../../Breakpoint';
import MaxWidth from '../layout/MaxWidth';

const Grid = styled.div`
    display: grid;
    grid-template-columns: auto min-content;
    gap: ${({ theme }) => theme.spacing.md};
    margin-bottom: ${({ theme }) => theme.spacing.xl};

    @media ${below(Breakpoint.SMALL)} {
        grid-template-columns: 1fr;
        margin-bottom: ${({ theme }) => theme.spacing.lg};
    }
`;

interface NewPageHeaderContentProps {
    alignItems?: string;
}

const Content = styled(MaxWidth)<NewPageHeaderContentProps>`
    display: grid;
    grid-template-columns: min-content auto;
    gap: ${({ theme }) => theme.spacing.md};
    align-items: ${({ alignItems }) => alignItems || 'flex-start'};

    h1 {
        margin-bottom: ${({ theme, alignItems }) =>
            alignItems !== 'center' ? theme.spacing.sm : 0};
    }
`;

const PageHeader = { Grid, Content };
export default PageHeader;
